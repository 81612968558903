import React, { useState, useEffect } from 'react';
import Notification from './Notification'
import classNames from 'classnames';
import { head } from 'lodash';
import HeaderButton from './buttons/HeaderButton';
import Breadcrumbs from './Breadcrumbs';

const ModularHeader = ( {children,headerObj, internal, errors, successes}) => {
  const [trail, setTrail] = useState(null);
  const [body, setBody] = useState(null);
  const [tabs, setTabs]= useState(null);
  const [buttons, setButtons]= useState(null);
  const [sideButton ,setSideButton] =useState(null);
  const [name, setName]=useState(null);
  const [subheader, setSubheader]=useState(null);

  const [physician, setPhysician] =useState(null);
  const [tpa, setTpa] =useState(null);
  const [insurer, setInsurer] =useState(null);
  const [provider, setProvider] =useState(null);
  const [vetkit, setVetkit]=useState(null);

  useEffect(() => {
    if(headerObj!==null&headerObj!==undefined)
  {
    if(headerObj.trail!==undefined)
    {
      setTrail(headerObj.trail);
    }
    if(headerObj.body!==undefined)
    {
      setBody(headerObj.body);
      var body = headerObj.body;
      if(body.name!==undefined)
      {
      setName(body.name)
      }
      if(body.subheader!==undefined)
      {
      setSubheader(body.subheader)
      }
      if(body.buttons!==undefined)
      {
        setButtons(body.buttons)
      }
      if(body.vetkit!==undefined)
      {
        setVetkit(body.vetkit);
      }
      if(body.info!==undefined)
      {
        setPhysician(body.info.physician);
        setProvider(body.info.provider);
        setInsurer(body.info.insurer);
        setTpa(body.info.tpa);
      }
      if(body.sideButton!==undefined)
      {
        setSideButton(body.sideButton)
      }
      if(body.tabs!==undefined)
      {
        setTabs(body.tabs)
      }

    }
  
  }
     
   }, [headerObj])


   const headerButtons= buttons!==null && buttons.map((b, i) => (
<React.Fragment>

<button className='h-[80%] max-[1200px]:h-[70%]  max-[1200px]:bottom-[15%] max-[1200px]:px-1  px-3 py-2 bg-white hover:bg-gray-100 rounded-lg shadow	 border border-gray-300 justify-center items-center gap-1 inline-flex' onClick={b.click}>
    {b.img&&<img className="w-5 h-5 relative" src={b.img} />}
<div className="px-0.5 justify-center items-center flex ">
<div className="text-gray-700 text-sm max-[1200px]:text-xs  font-semibold font-['Inter'] leading-tight">{b.title.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
</div>
</button>

</React.Fragment>    
   ))

   const headerTabs= tabs!==null && tabs.map((tab, i) => (
    <React.Fragment>
    
    <button className={'h-[80%] max-[1200px]:h-[60%] w-1/6 bottom-0  max-[1200px]:px-1  px-3   bg-white hover:bg-gray-100  	 justify-center items-center  inline-flex '+(tab.current===true?"border-b-2	border-brand-600 text-brand-600":"border-0 text-gray-700 ")} onClick={tab.click}>
    <div className="px-0.5 justify-center items-center flex ">
    <div className=" text-sm max-[1200px]:text-xs  font-semibold font-['Inter'] leading-tight">{tab.text.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
    </div>
    </button>
    
    </React.Fragment>    
       ))

  return (

    <React.Fragment>
    {
        headerObj!==null&&headerObj!==undefined&&internal?
        (
          
          
    <div id="infoHeader" className={internal?'z-10 bg-white pointer-events-auto shadow	 py-0 my-0 fixed right-0 left-[calc(100%-calc(100%-196px))] top-0 ':'z-10 pointer-events-auto fixed right-0 left-0 top-0  '}>
      {trail? <span className={` bg-inherit	  shadow-none	pl-[1.3rem] relative w-full h-[3vh] max-[1200px]:h-[3vh] border-0  float-start  items-center py-0 my-0 inline-flex  text-sm max-[1200px]:text-xs font-normal font-['Inter']`}>
      <Breadcrumbs links={trail} />

        </span>:<span className='absolute shadow-none	'></span>
    }
    {
      body?<span className={`w-full h-[14vh] shadow-none 	 max-[1200px]:h-[11vh] relative  bg-inherit my-0 py-0 pl-4 float-up   inline-flex  inset-x-0 `} id="t">
     
     <div id="basicInfo"className='h-2/3  m-0 relative w-max shadow-none mr-[50px] max-[1200px]:mr-2	  max-[1200px]:gap-y-0	 self-stretch pt-1 max-[1200px]:pt-0   flex-col justify-start items-start inline-flex '>
              <span className='inline-flex gap-x-2 max-[1200px]:gap-x-0'>
              <div className="text-gray-900 text-4xl   shadow-none	 max-[1200px]:text-xl font-medium font-['Inter'] leading-[44px] max-[1200px]:leading-tight max-[1200px]:m-0">{name}</div>
              {sideButton!==null?<div className="w-9 h-9  max-[1200px]:w-8  max-[1200px]:h-8 rounded-lg justify-start items-start inline-flex max-[1200px]:ml-1">
              <div class='has-tooltip  	'>

            <div className="p-2 bg-white hover:bg-gray-100 rounded-lg shadow border border-gray-300 justify-center items-center gap-2 flex" onClick={()=>{sideButton.click()}}>
                <img className="w-5 h-5  max-[1200px]:w-4  max-[1200px]:h-4 relative" src={sideButton.img}/>
            </div>
            <span class='tooltip whitespace-nowrap  inline-flex rounded shadow-lg p-1 -mt-12 ml-4'>{sideButton.text}</span>

            </div>

              </div>:<span></span>}
              </span>
              <div className=" text-gray-600 text-sm   shadow-none	 max-[1200px]:text-xs font-normal font-['Inter'] leading-tight max-[1200px]:m-0">{subheader}</div>

     </div>
    {provider!==null&& <div id="patientInfo"className='h-2/3  relative shadow-none	justify-center items-start'>
     <div className=" text-sm max-[1200px]:text-xs pt-3  gap-[50px] max-[1200px]:gap-[5px] inline-flex">
    <div className="flex-col justify-start items-start gap-[5px] max-[1200px]:gap-[1px] inline-flex">
        <div className="justify-start items-start gap-[11px] max-[1200px]:gap-[15px] inline-flex">
            <div className="text-gray-900  font-semibold font-['Inter'] leading-tight">TPA</div>
            <div className="text-gray-600  font-normal font-['Inter'] leading-tight">{tpa}</div>
        </div>
        <div className="justify-start items-start gap-[11px] max-[1200px]:gap-[5px] inline-flex">
            <div className="text-gray-900  font-semibold font-['Inter'] leading-tight">Insurer</div>
            <div className="text-gray-600  font-normal font-['Inter'] leading-tight">{insurer}</div>
        </div>
    </div>
    <div className="flex-col justify-start items-start gap-[5px] max-[1200px]:gap-[1px] inline-flex">
        <div className="justify-start items-start gap-[11px] inline-flex">
            <div className="text-gray-900 font-semibold font-['Inter'] leading-tight">Provider</div>
            <div className="text-gray-600  font-normal font-['Inter'] leading-tight">{provider}</div>
        </div>
        <div className="justify-start items-start gap-[11px] inline-flex">
            <div className="text-gray-900  font-semibold font-['Inter'] leading-tight">Physician</div>
            <div className="text-gray-600  font-normal font-['Inter'] leading-tight">{physician}</div>
        </div>
    </div>
</div>
     </div>}
    { vetkit!==null&& <div id="vetkitInfo"className='h-2/3 right-0  absolute shadow-none 	justify-end items-center right-0 inline-flex  mr-16 max-[1200px]:mr-0 '>
        <div className="flex-col justify-start items-center inline-flex  pt-1 ">
        <div className="text-gray-900 text-sm max-[1200px]:text-xs font-semibold font-['Inter'] leading-tight">VET™ Version</div>
        <div className="text-gray-600 text-xl max-[1200px]:text-lg font-normal font-['Inter'] leading-[30px]">{vetkit!==null?vetkit:"Not Found"}</div>
        </div>
        

      
     </div>}
     <div id="buttons"className={'h-1/3  w-full absolute flex bottom-0 justify-start shadow-none	  inline-flex '+(headerTabs===false?"gap-3 items-start ":" ml-[-16px] items-end")}>
      {
        (headerButtons!==undefined&&headerButtons!==null&&headerButtons)
     }
     {
        (headerTabs!==undefined&&headerTabs!==null&&headerTabs)

     }
     </div>


     </span>:<span className='absolute  bg-inherit		'></span>
    }
    {children}

    </div>
    )

          
          
        
    :
    (
      <div id="infoHeaderHide" className={internal?'z-10 pointer-events-auto shadow-none	 py-0 my-0 fixed right-0 left-[calc(100%-calc(100%-196px))] top-0 ':'z-10 pointer-events-auto fixed right-0 left-0 top-0  '}>
     
    {children}
    </div>
    )
    
    }

    </React.Fragment>
  )
}

export default ModularHeader