import React, { useState, useEffect } from 'react';
import API from '../../services/API';
import {  Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import PersonCard from './../cards/PersonCard';
import ButtonCard from './../cards/ButtonCard';


const Clinicians = ({}) => { 

	const [rawData, setRawData] = useState([]);

	useEffect(() => {
		if (rawData.length == 0) {
			getData();
		}
		
	}, []);
	

	const getData = () => {
		API.get(`/physicians/`)
		.then(response => {
			console.log(response);
			setRawData(response.data.results);
		})
		.catch(error => console.error(error));
	}

	const clinicians = rawData && rawData.map((clinician, i) => 
	 	<PersonCard key={i} person={clinician} type="Physician"/>
	)

	return (
		<div className="patients">
			<ButtonCard destination="create-clinician">Add new</ButtonCard>	
			{clinicians}		
			
		</div>
	);
}

export default Clinicians;

