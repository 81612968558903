import React from 'react'
import PatientProfile from './PatientProfile'
import { withRouter, useParams } from 'react-router';

const PatientProfileRedirect = () => {
  return (
    <PatientProfile redirect={true}>
    <div>PatientProfileRedirect</div>

    </PatientProfile>
  )
}

export default withRouter( PatientProfileRedirect)