import React, { useState, useEffect } from 'react';
import API from '../../services/API';
import { withRouter, useParams } from 'react-router';
import { element } from 'prop-types';
import CoachingPlanList from './CoachingPlanList';
import { TailSpin	 } from  'react-loader-spinner'


const ListOfCoachingPlanList= ({  coachingPlan, protocol}) => { 

	const [protocols, setProtocols] = useState(protocol);

	useEffect(() => {
		//getProtocols();
		
	  }, [])

	const getProtocols= () => {
		API.get(`/protocols`).then(response => {
			var protocol ={}
			response.data.results.forEach(element => protocol[element.id]=element.display_name);
			setProtocols(protocol)

		}).catch(error => console.log(error));
	  }
       
	

	const ListList = coachingPlan &&coachingPlan.length>0 && coachingPlan.map((progress) => (
		<CoachingPlanList coachingPlan={progress} protocols={protocols}/> 
	))
		

	return (

			<React.Fragment>

			



			{coachingPlan.length>0?<h3>Bonus Activities : {coachingPlan[0].track_name}</h3>:""}
			{protocols!==null && ListList? ListList: <TailSpin	
			height="100"
			width="100"
			color='grey'
			ariaLabel='loading'
		  />&&
      	<br />&& <br />&&
      <h4>Loaiding...</h4>
	  }
 
			
			
			</React.Fragment>
		

		
	);
}


export default withRouter(ListOfCoachingPlanList);
