import React, { Children, useState } from 'react';
import { Portal } from 'react-portal';


const Confirmation = ({ onClose, onSubmit, children }) => {

  const handleCancel = (e) => {
    e.stopPropagation();
    onClose();
  }


  const handleSubmit = (e) => {

    console.log("Handling submit", onSubmit);
    e.stopPropagation();
    onSubmit && onSubmit();
    onClose();
  }

  const handleClick = (e) => {
    e.stopPropagation();
  }

  return(
    <Portal>
      <div className="confirmation-holder" onClick={handleClick}> 
        <div className="confirmation" onClick={handleClick}>
          {children}
          <br />
          <div className="button-row">
            {onSubmit && <button className="caution" onClick={handleCancel}>Cancel</button>}
            <button onClick={handleSubmit}>OK</button>
          </div>

        </div>

      </div>
     
    </Portal>
   
  )
}

export default Confirmation;