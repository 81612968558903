import React, { useEffect, useState } from 'react';
import DropdownInput from './DropdownInput';
import Input from './Input';
import { initial, values } from 'lodash';

const SingleInput = ({ start="" ,nameClass="", index,eraseFunction, title="" ,multiInput=false, secondClass="" ,start2="", startVal=null, startVal2=null}) => {

  
  useEffect(() => {





}, []);

  const Remove =(e)=>{
    e.preventDefault();
    eraseFunction(index)
}
 


  return(
    <React.Fragment>

<div className='w-full'>
  <span className='inline-flex gap-x-1 pb-2'>
<p className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight">{title} {index+1}</p>

<button type="button" onClick={Remove}><img className='h-4 w-4 ' src ={"/icons/trash.svg"} ></img></button>

  </span> 
  <br/>       
                {
                  
                multiInput?(startVal2===null?<React.Fragment><input type="text" className={'w-full h-[5.5vh]  resize-none bg-white rounded-lg shadow border border-gray-300 '+secondClass} id={secondClass} placeholder={start2} /><br/></React.Fragment>:<React.Fragment><textarea type="text" id={secondClass} className={'w-full h-[5.5vh] mb-1 resize-none bg-white rounded-lg shadow border border-gray-300 '+secondClass} defaultValue={startVal2} /><br/></React.Fragment>):<span></span>
                }       
{startVal===null? <React.Fragment> <textarea className={'w-full h-[10vh] resize-none bg-white rounded-lg shadow border border-gray-300 '+nameClass}  type="text" id={nameClass} placeholder={start}  /></React.Fragment>:<React.Fragment><textarea type="text" className={'w-full h-[10vh] resize-none bg-white rounded-lg shadow border border-gray-300 '+nameClass} id={nameClass} defaultValue={startVal}  /></React.Fragment>}

        </div>
    </React.Fragment>
  )
}

export default SingleInput;