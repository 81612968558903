import React, { useState, useEffect } from 'react';
import { withRouter,useParams } from 'react-router';
import { labelToURL } from '../../utility/Utility';
import moment from 'moment-timezone';
import { useHistory } from "react-router-dom";
import { getTimezone } from '../../utility/UserUtility';
import API from '../../services/API';
import { object } from 'prop-types';
import JSZip from "jszip";
import { saveAs } from 'file-saver';

const ExternalOutcomeRow = ({ patient, outcomeCollection=[], Click, currentPatient, index, SetDownload}) => {
    const patientId = patient.id;
	const timezone = getTimezone();
	let history = useHistory();
	const [show, setShow] = useState(false);
	const [clank, setClank] = useState(false);
    const [click, setClick] = useState(false);

    const GetPDF=async(id,week,date)=>{
		
		if(id!==undefined&&week!==undefined&&date!==undefined){

			SetDownload(true);

			await API.get(`/outcome_reports/${id}/?pdf_data=${true}/` ).then((response) => {

				console.log(response);
				if(response.data.report_pdf_data!==undefined)
				{
					var url =`/report/${id}`
					localStorage.setItem("pdf",JSON.stringify(response.data) );
					window.open(url, '_blank');
				}
				SetDownload(false);

		
			
			}).catch(error => {
			console.error(error)
			SetDownload(false);

			
			})

        }

	}

	const GetAllPDF=  ()=>{
        console.log(patientId)
        SetDownload(true);
        console.log(patientId!==null&&patientId!==undefined)
        if(patientId!==null&&patientId!==undefined){
            console.log(12);
            var url =`/reports/${patientId}`
            localStorage.clear();
            window.open(url, '_blank');
            SetDownload(false);

        }
        }

	const DateDisplay=(day)=>{
		var d = moment(day).tz(timezone);
		return d.format("MM-DD-YYYY");
		

	}

    useEffect(() => {
		if(clank &&click)
        {
            setClank(false);
            setClick(false);

            return;
        }
        if(click){
        var x= !show;
        setShow(x);
		if(x){
			Click(index);
		}
		else{
			Click(-1);
		}   
        setClick(false);
    }
	
	}, [click]);

    const HandleClick=()=>{
        console.log("click")

		setClick(true)   
    }

	const Clanked =()=>{
        setClank(true);
        console.log("clank")
        console.log(123)
		GetAllPDF();
    }

    const RenderTableElements=()=>
    {
        var t=[];
        t.push(
            <React.Fragment>
            <tr className=' whitespace-nowrap ' >
            <td className="whitespace-nowrap border-x-0	  bg-gray-50  px-6 py-3 text-start text-xs font-medium text-gray-900 font-['Inter'] leading-[18px] ">{}</td>
            <td className="whitespace-nowrap border-x-0		 bg-gray-50  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{"Report Week"}</td>
            <td className="whitespace-nowrap border-x-0		 bg-gray-50  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{"Date Created"}</td>
            <td className="whitespace-nowrap border-x-0		 bg-gray-50  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{}</td>
            <td className="whitespace-nowrap border-x-0		 bg-gray-50  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{}</td>
            <td className="whitespace-nowrap border-x-0		 bg-gray-50  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>
            <td className="whitespace-nowrap border-x-0		 bg-gray-50 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>
            <td className="whitespace-nowrap border-x-0		 bg-gray-50 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>
        
            <td className="whitespace-nowrap border-x-0		 bg-gray-50  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>
          </tr>

          </React.Fragment>
        );

        outcomeCollection.forEach((outcome, index) => {
            t.push(<React.Fragment>
                <tr className=' whitespace-nowrap even:bg-white odd:bg-white border-y-2		' >
        <td className="whitespace-nowrap border-x-0	  bg-gray-25 px-6 py-3 text-start text-xs font-medium text-gray-900 font-['Inter'] leading-[18px] ">{}</td>
        <td className="whitespace-nowrap border-x-0		 bg-gray-25  px-6 py-3 text-start text-xs font-medium text-gray-900 font-['Inter'] leading-[18px] ">{ !(outcome.is_post_program_checkin!==undefined && outcome.is_post_program_checkin===true)?"Week "+outcome.included_weeks: "6 Months Post"}</td>
        <td className="whitespace-nowrap border-x-0		 bg-gray-25  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{!outcome.in_progress?outcome.prepared_date:""}</td>
        <td className="whitespace-nowrap border-x-0		 bg-gray-25  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{}</td>
        <td className="whitespace-nowrap border-x-0		 bg-gray-25  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{}</td>
        <td className="whitespace-nowrap border-x-0		 bg-gray-25 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>
        <td className="whitespace-nowrap border-x-0		 bg-gray-25 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>
        <td className="whitespace-nowrap border-x-0		 bg-gray-25 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>
    
        <td className="whitespace-nowrap border-x-0		px-6 py-3 text-center	 text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">
        {!outcome.in_progress?<button className=" px-3.5 py-2 bg-brand-600 hover:bg-brand-700 rounded-lg shadow border border-brand-600 justify-center items-center gap-2 inline-flex" onClick={()=>{GetPDF(outcome.id,outcome.included_weeks, outcome.prepared_date)}}>
        <img className="relative" src="/icons/folder.svg" />
        <div className="text-white text-sm font-semibold font-['Inter'] leading-tight">Download</div>
    </button>:"In Progress"}
        </td>
      </tr>
            </React.Fragment>)
    
        }
        )

        return t;

    }

    useEffect(() => {
        if(currentPatient!==index && show)
		{
			setShow(false);
		}
	}, [currentPatient]);


   
  return (
    <React.Fragment><tr className='cursor-pointer whitespace-nowrap even:bg-white odd:bg-white	border-y-2	' onClick={HandleClick}>
    <td className="whitespace-nowrap border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-900 font-['Inter'] leading-[18px] ">{!show?    <img className="relative" src="/icons/chevron-right.svg" />:<img className="relative" src="/icons/chevron-down.svg" />}</td>
    <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-900 font-['Inter'] leading-[18px] ">{patient.profile.payer_type !==2?(patient.first_name+" "+patient.last_name):("#"+patientId)} {outcomeCollection.filter(function(value)
			  { 
				  return value.patient_id === patient.id && value.in_progress===undefined;
			  }).length>0?<div className="w-6 h-[22px] px-2 py-0.5 bg-slate-50 rounded-2xl justify-center items-center inline-flex">
<div className="text-center text-blue-800 text-xs font-medium font-['Inter'] leading-[18px]">{outcomeCollection.filter(function(value)
			  { 
				  return value.patient_id === patient.id && value.in_progress===undefined;
			  }).length}</div>
</div>:<span></span>}</td>
    <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{patient.physician_name}</td>
    <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{patient.profile.insurers}</td>
    <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{patient.profile.third_party_administrators}</td>
    <td className="whitespace-nowrap border-x-0		px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>
    <td className="whitespace-nowrap border-x-0		px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>
    <td className="whitespace-nowrap border-x-0		px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>

    <td className="whitespace-nowrap border-x-0		px-6 py-3 text-center	 text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">
    {outcomeCollection.filter(function(value)
			  { 
				  return value.patient_id === patient.id && value.in_progress===undefined;
			  }).length>0?<button className=" px-3.5 py-2 bg-brand-600 hover:bg-brand-700 rounded-lg shadow border border-brand-600 justify-center items-center gap-2 inline-flex" onClick={()=>{Clanked()}}>
    <img className="relative" src="/icons/folder.svg" />
    <div className="text-white text-sm font-semibold font-['Inter'] leading-tight">Download All</div>
</button>:"N/A"}
    </td>
  </tr>

  {show&&currentPatient===index?(outcomeCollection&&outcomeCollection.length>0?(
    RenderTableElements()
   
  ):(
	<tr className='whitespace-nowrap even:bg-white odd:bg-white	' >
    <td className="whitespace-nowrap border-x-0	 bg-gray-25  px-6 py-3 text-start text-xs font-medium text-gray-900 font-['Inter'] leading-[18px] "></td>
    <td className="whitespace-nowrap border-x-0		 bg-gray-25 px-6 py-3 text-start text-xs font-medium text-gray-900 font-['Inter'] leading-[18px] ">{"No reports found"}</td>
    <td className="whitespace-nowrap border-x-0	 bg-gray-25	 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{}</td>
    <td className="whitespace-nowrap border-x-0	 bg-gray-25 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{}</td>
    <td className="whitespace-nowrap border-x-0	 bg-gray-25	 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{}</td>
    <td className="whitespace-nowrap border-x-0		 bg-gray-25 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>
    <td className="whitespace-nowrap border-x-0		 bg-gray-25 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>
    <td className="whitespace-nowrap border-x-0		 bg-gray-25 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>
    <td className="whitespace-nowrap border-x-0		 bg-gray-25 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>
  </tr>

  )):""}
  
  
  </React.Fragment>
  )
  
}

export default ExternalOutcomeRow