import React, { useState, useEffect } from 'react';
import TimeSeriesGraph from './../graphs/TimeSeriesGraph';
import CategoryPieChart from './../graphs/PieChart';



const MetricsList = ({  }) => { 

	return (
		<div className="statistics">
			{/* <TimeSeriesGraph type="exercises" timeKey="created" limit="150" label="Calibration activities completed" filter={{ id: 'scene_type', value:"Paint By Number"}}/> */}
			<TimeSeriesGraph type="exercises" timeKey="start_time" limit="150" label="Recent activity"  />
			<CategoryPieChart type="exercises" categoryName="scene_type" label="Popular activities" limit="5000"/> 
			{/* <RomsGraph type="visits" timeKey="start_time" limit="800" label="Roms" valueName="calibrated_roms"/> */}

		</div>
		

		
	);
}


export default MetricsList;
