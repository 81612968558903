import React, { useState, useEffect, useRef } from 'react';
import API from '../../services/API';
import {  Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import PersonCard from './../cards/PersonCard';
import ButtonCard from './../cards/ButtonCard';
import Skeleton from 'react-loading-skeleton';
import cookie from 'react-cookies';
import { useHistory } from "react-router-dom";
import { getDuration } from '../../utility/Visit';
import moment from 'moment-timezone';
import Input from '../input/Input';

//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { TailSpin	 } from  'react-loader-spinner'
import { debug } from 'react-router-breadcrumbs';
import { object } from 'prop-types';


const Patients = ({}) => { 

	const [userIsClinician, setUserIsClinician] = useState(false);
	const [patients, setPatients] = useState(null);
	const [masterPercentage, setMasterPercentage] = useState(0);
  const [slavePercentage, setSlavePercentage] = useState(0);
  const [filter, setFilter]=useState(null);
  const [showInactive, setShowInactive] = useState(false);

  const [filteredpatients, setFilteredPatients] = useState(null);

	useEffect(() => {

		if(cookie.load('role') === 'Physician'){
			setUserIsClinician(true);
		}
		var retrievedPatients=[]
		API.get(`/patient_usage_stats/?ordering=last_name&limit=100&exclude_visits_without_coaching_plans=true`)
			.then(response => {
				console.log(response.data.results);
				retrievedPatients.push(...response.data.results)
				if(response.data.next===null)
				{
					setPatients(retrievedPatients);
				}
				else{
					batchPatients(response.data.next,retrievedPatients)
				}
			})
			.catch(
				error => {
					console.error(error)
					setPatients([]);
				}
				);
	}, []);

	useEffect(() => {
		if(patients){
		var filtered= patients.filter(p=>{
			var name = p.first_name+" "+p.last_name;
			name = name.toLowerCase();
			if(!showInactive)
			{
				if(p.coaching_plan_state==="INACTIVE")
				{
					return false;
				}
			}
			if(filter)
			{
				var f=filter.trim();

				return name.includes(f);
			}
			else
			{
				return true;
			}
		})
		setFilteredPatients(filtered)
		}
	}, [patients,filter,showInactive]);
	let history = useHistory();

	const batchPatients= async (next, retrieved)=>{
		var path = next.split(".com")[1];
		await API.get(path)
			.then(response => {
				console.log(response.data.results);
				retrieved.push(...response.data.results)
				if(response.data.next===null)
				{
					setPatients(retrieved);
				}
				else{
					batchPatients(response.data.next,retrieved)
				}
			})
			.catch(
				error => {
					console.error(error)
					setPatients([]);
				}
				);

	}

	const handleMasterScroll = (e) => {
		let slave = e.target.parentNode.querySelector(".slave");
		let master = e.target;
		let height = master.scrollLeft - master.clientLeft;
		let percentage = (master.scrollWidth / height) * 100;
		setMasterPercentage(percentage);
		slave.scroll({
		  left: height
		});
	  };

	  const handleSlaveScroll = (e) => {
		let slave = e.target;
		let master = e.target.parentNode.querySelector(".master");
		let height = slave.scrollLeft - slave.clientLeft;

		let percentage = (slave.scrollWidth / height) * 100;
		setSlavePercentage(percentage);
		master.scroll({
		  left: height
		});
	  };

	const loadPerson=(id)=>{
	history.push(`/patient/` +id);
	}



	const renderPatients = filteredpatients&&filteredpatients.map((patient, index) => 
	<div className={'PatientRow'+(patient.coaching_plan_state!=="INACTIVE"?"":" Inactive")}  onClick={()=>{loadPerson(patient.id)}}> 
<span key={index} className="row-table">
{patient.coaching_plan_state!=="INACTIVE"?<React.Fragment>


			<h4 className="chart-label-center"><span>{patient.first_name}</span>{" "+patient.last_name}</h4>
			{
				<h4 className="chart-label-center">{patient.coaching_plan_week!==undefined?patient.coaching_plan_week:"\u2014"}</h4>

			}
			<h4 className="chart-label-center">{patient.total_stats!==undefined && patient.total_stats.unique_days!==undefined?patient.total_stats.unique_days: "\u2014;"}</h4>
			<h4 className="chart-label-center">{patient.total_stats!==undefined && patient.total_stats.time_spent!==undefined? patient.total_stats.time_spent > 0 ? `${(patient.total_stats.time_spent)<60?moment.duration(patient.total_stats.time_spent*1000).seconds()+ " s":""} ${moment.duration(patient.total_stats.time_spent*1000).hours() > 0 ? (moment.duration(patient.total_stats.time_spent*1000).hours() + " hrs ") : ""}${moment.duration(patient.total_stats.time_spent*1000).minutes()?moment.duration(patient.total_stats.time_spent*1000).minutes() +" m ":""}` : "\u2014" : "\u2014"}</h4>
			{
				<h4 className="chart-label-center">{patient.plan_stats&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks[Object.keys(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks).pop()]?(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks[Object.keys(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks).pop()].unique_days):"\u2014"}</h4>

			}
			{
				<h4 className="chart-label-center">{patient.plan_stats&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks[Object.keys(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks).pop()]? `${((patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks[Object.keys(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks).pop()].time_spent))<60?moment.duration((patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks[Object.keys(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks).pop()].time_spent)*1000).seconds()+ " s":""} ${moment.duration((patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks[Object.keys(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks).pop()].time_spent)*1000).hours() > 0 ? (moment.duration((patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks[Object.keys(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks).pop()].time_spent)*1000).hours() + " hrs ") : ""}${moment.duration((patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks[Object.keys(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks).pop()].time_spent)*1000).minutes()?moment.duration((patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks[Object.keys(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks).pop()].time_spent)*1000).minutes() +" m ":""}` :"\u2014"}</h4>

			}
			{
				<h4 className="chart-label-center">{patient.plan_stats&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks[Object.keys(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks).pop()]&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks[Object.keys(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks).pop()].personal_routine?(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks[Object.keys(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks).pop()].personal_routine.completions):"\u2014"}</h4>

			}
			{
				<h4 className="chart-label-center">{patient.plan_stats&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks[Object.keys(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks).pop()]&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks[Object.keys(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks).pop()].bonus_activity?(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks&&patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks[Object.keys(patient.plan_stats[Object.keys(patient.plan_stats).pop()].plan_weeks).pop()].bonus_activity.completions):"\u2014"}</h4>

			}
			</React.Fragment>:
			<React.Fragment>
				<h4 className="chart-label-center"><span>{patient.first_name}</span>{" "+patient.last_name}</h4>
				<h4 className="chart-label-center">No Current Coaching Plan</h4>
				<h4 className="chart-label-center"> {"\u2014"}</h4>
				<h4 className="chart-label-center"> {"\u2014"}</h4>
				<h4 className="chart-label-center"> {"\u2014"}</h4>
				<h4 className="chart-label-center">{"\u2014"} </h4>
				<h4 className="chart-label-center">{"\u2014"} </h4>
				<h4 className="chart-label-center">{"\u2014"} </h4>
			



			</React.Fragment>
		}
		</span>	
		</div>
)




	return (
		
		<div className="patients">
			{userIsClinician &&<ButtonCard destination="create-patient">Add new</ButtonCard>	}
	
			{patients&&renderPatients?(
				patients.length>0?
			<React.Fragment>
						
			<div className='PatientView'>
			<Input
						type="text"
						name="Patient Name"
						value={filter}
						onChange={setFilter}
						overrideLabel="Patient Search"
						noLabel={true}
						overrideText="Search by patient name..."
					/>
					</div>

<label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={showInactive } onChange={()=>{setShowInactive(!showInactive)}} /> <b>Show Inactive Patients</b>
                    </label>
					<div className='PatientView'>
						{
							renderPatients.length>0?			<React.Fragment>


			<div  onScroll={handleSlaveScroll} className='scrollslave slave' >

			<div className="row-max" id="scrollDiv-1">
				

<p className="chart-label-center nohover"><b className='nohover'>Patient</b></p>
<p className="chart-label-center nohover"><b className='nohover'>Plan Week</b></p>
<p className="chart-label-center nohover"><b className='nohover'>Total Days Used</b></p>
<p className="chart-label-center nohover"><b className='nohover'>Total Activity Time</b></p>
<p className="chart-label-center nohover"><b className='nohover'>Current Week Days Used</b></p>
<p className="chart-label-center nohover"><b className='nohover'>Current Week Activity Time</b></p>
<p className="chart-label-center nohover"><b className='nohover'>Current Week Routine Completions</b></p>
<p className="chart-label-center nohover"><b className='nohover'>Current Week Bonus Activities</b></p>
</div>
</div>

<div  onScroll={handleMasterScroll} className='scrollView master' id="scrollDiv-2" >
				{renderPatients}</div>
				</React.Fragment>
:
<h3>No Patients Found</h3>			}
			</div>
			</React.Fragment>:<h4>No Patients Found</h4>):
			<React.Fragment>


			<TailSpin	
				  height="100"
				  width="100"
				  color='grey'
				  ariaLabel='loading'
				/>
			</React.Fragment>
			}	
		
		</div>
		
	
	);
}

export default Patients;

