import React, { useState, useEffect } from 'react';




const DropdownInput = ({ className, name, options, onChange, value, required, formErrors }) => {
    useEffect(() => {
        formErrors && validate();
    }, [formErrors])

    const [errors, setErrors] = useState([]);

    const handleSelection = (event) => {
        onChange(event.target.value);
        console.log(event.target.value);
    }

    const buttons = options && options.map((option, i) => (
        <option key={i} id={option.label} value={option.id} selected={option.id == value}
             >{option.label}</option>
    ))

    
    const validate = () => {
        required && (!value || value.length == 0) && setErrors(errors => [...errors, "This field is required"]);
    }
    const renderErrors = errors && errors.map((error, i) => 
    <div>{error}</div>
)

    return (
<span className='w-full '>
            <select onChange={handleSelection} className={className }>
                <option disabled selected value> {name}</option>
                {buttons}
            </select>
            
            
        </span>



    );
}


export default DropdownInput;
