import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import API from '../services/API';
import VetkitCard from '../components/cards/VetkitCard';
import VetkitAssignment from '../components/VetkitAssignment';
import Breadcrumbs from '../components/Breadcrumbs';
import { isPending } from '../utility/VetkitUtility';



const Provisioning = ({}) => { 
	
	const [vetkits, setVetkits] = useState(null);
	const [vetkitAssignments, setVetkitAssignments] = useState(null);

	useEffect (() => {
		loadVetKits();
	}, [])

	const loadVetKits = () => {
		API.get('/vetkits/').then((response) => {
			console.log("VETKITS", response.data.results);
			setVetkits(response.data.results);
		});

		API.get('/vetkitassignments/').then((response) => {
			console.log("VETKIT ASSIGNMENTS", response.data.results);
			setVetkitAssignments(response.data.results);
		});
	}

	const assignableVetkitCards = vetkits && vetkits.filter(vetkit => vetkit.is_assignable).map((vetkit, i) => (
		<VetkitCard key={i} vetkit={vetkit} onChange={loadVetKits} />
	))

	const pendingVetkitCards = vetkits && vetkits.filter(vetkit => isPending(vetkit)).map((vetkit, i) => (
		<VetkitCard key={i} vetkit={vetkit} onChange={loadVetKits} />
	))

	const renderVetkitAssignments = vetkitAssignments && vetkitAssignments.filter(a => a.date_completed == "In Progress").map((vetkitAssignment, i) => (
		<VetkitAssignment vetkitAssignmentId={vetkitAssignment.id} showClinician showPatient onChange={loadVetKits}/>
	))



    return (
		<Page title={"Provisioning"} showMenu>
				<Breadcrumbs links={[
					{
						label: "Provisioning",
						url: "/provisioning"
					}
				]}/>
				
			<h3>Pending VET Kits</h3>
				<div className="vetkits">
					{pendingVetkitCards}
					{pendingVetkitCards == "" && <h4>No VET Kits are on the way. If you are expecting a VET Kit, please contact Karuna.</h4>}
					
				</div>
				<br /><br />
			<h3>Available VET Kits</h3>
			<div className="vetkits">
					{assignableVetkitCards}
					{assignableVetkitCards == "" && <h4>No VET Kits to assign. If there are pending VET Kits in the section above, and you have received the kits, mark them as received and they will be able to be assigned here.</h4>}

			</div>
				<br />
				<br />
				<h3>Assigned VET Kits</h3>
				<div className="vetkits">
					{renderVetkitAssignments}
				</div>
			
			
		</Page>
     
    );

}

export default withRouter(Provisioning);
