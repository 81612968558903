import React, { useRef } from 'react';
import { useParams } from 'react-router';
import { useState } from 'react';
import Page from '../components/Page';
import { useEffect } from 'react';
import API from '../services/API';
import { getDuration } from '../utility/Visit';
import RomsGrid from '../components/compositions/RomsGrid';
import moment from 'moment-timezone';
import Exercise from '../components/Exercise';
import PersonCard from '../components/cards/PersonCard';
import Breadcrumbs from '../components/Breadcrumbs';
import html2PDF from 'html2pdf.js';
import { getTimezone } from '../utility/UserUtility';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend, BarChart, Bar, AreaChart, Area, Label } from 'recharts';
import { constant } from 'lodash';
import { element } from 'prop-types';
import ActivityPageGraph from '../components/graphs/ActivityPageGraph';
import { TailSpin	 } from  'react-loader-spinner'

const ActivityPage = ({  }) => {
  const colors = ['#5B8BC8', '#F36C33', '#FCB242', '#005BA3', '#414042'];
  const content = useRef(null);
  const activityName= useParams().activityName;
  const dayName = useParams().dayName;
  const patientId = useParams().patientId;
  const [duration, setDuration] = useState(0);
  const [pauseTime, setPause] = useState(0);
  const [tutorialTime, setTutorial] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [completedSessions, setCompleted] = useState(0);
  const [exercises, setExercises] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [clinicianData, setClinicianData] = useState(null);
  const [exerciseDates, setExerciseDates] = useState([]);
  const [FullRange, setFullRange] = useState(0);
  const [graphDataset, setGraphDataset] = useState(null);

  const [LeftRightValues,setLeftRightValues]= useState(null);
  const [personalBestScore, setPersonalBestScore]= useState(null);
  const [personalBestGoals, setPersonalBestGoals]= useState(null);

  const exerciseNames =[activityName]


  useEffect(() => {
    if(exercises===null)
    {
    getExerciseData();
    }
    if(patientId){
      getPatientData();
      getPersonalBest();
    }
    
  }, [exercises,FullRange,personalBestScore])

  const getPatientData = (id) => {
      
    API.get(`/patients/${patientId}`).then(response => {
      setPatientData(response.data);
      getClinicianData(response.data.physician_id)
    }).catch(error => console.log(error))
  }

  const getClinicianData = (id) => {
    API.get(`/physicians/${id}`).then(response => {
      setClinicianData(response.data);
    }).catch(error => console.log(error))
  }

  const ReformatName=(name)=>{return name.split(" ").join("");}

  const getPersonalBest=()=>{

		API.get(`/scorecards/?patient=${patientId}`).then(response=>{
			var result = response.data.results;
      var score=-1
      var goalDict={}
      result.forEach( element=>{

        if(parseInt( element.patient)===parseInt(patientId))
        {
          if("scores" in element)
          {

            if("activity"in element.scores)
            {
              var name = ReformatName(activityName)
              if(name in element.scores.activity)
              {
                

                if('top_score' in element.scores.activity[name])
                {
                  if(element.scores.activity[name].top_score>score)
                  {
                  score= element.scores.activity[name].top_score;
                  }
                }
                if("top_goals" in element.scores.activity[name]) 
                {
                  var goals=element.scores.activity[name].top_goals
                  for (let goal in goals) {
                    if(goal in goalDict)
                    {
                      if(goalDict[goal]<goals[goal])
                      {
                        goalDict[goal]=goals[goal]

                      }
                    }
                    else
                    {
                      goalDict[goal]=goals[goal]
                    }
                    
                  }
                }
                if("first_top_goals" in element.scores.activity[name]) 
                {
                  var goals=element.scores.activity[name].first_top_goals
                  for (let goal in goals) {
                    if(goal in goalDict)
                    {
                      if(goalDict[goal]<goals[goal])
                      {
                        goalDict[goal]=goals[goal]

                      }
                    }
                    else
                    {
                      goalDict[goal]=goals[goal]
                    }
                    
                  }
                }
                
              }

            }
          }
        }
        if(score>-1){setPersonalBestScore(score)}
        if(Object.keys(goalDict).length>0){setPersonalBestGoals(goalDict)}
      }
      )
			
		


		})
		.catch(
			error =>{
				console.log(error)
				setPersonalBestScore(null);
			}
		)
	}

  const getExerciseData = () => {
    
    

    API.get(`/exercises/?activity_name=${activityName}&visit__patient=${patientId}`).then(response => {
        setExercises(response.data)
        let tempDuration = duration;
        let tempScore= totalScore;
        var completedCount=0;
        let tempDates= exerciseDates;
        let tempTutorial = tutorialTime;
        let tempPause = pauseTime
        response.data.results.map(exercise => {
        let dur = moment.utc(getDuration(exercise));
        let score = exercise.scores.score;
        tempDates.push(exercise.start_time)
        if(exercise.status==="Completed"){completedCount++;}
        tempDuration += dur;
        tempScore+= score;
        tempPause+= exercise.paused_time * 1000 ;
        tempTutorial+=exercise.tutorial_time * 1000;
      })
     setCompleted(completedCount);
     setDuration(tempDuration);   
     setTotalScore(tempScore);   
     setExerciseDates(tempDates);
     setPause(tempPause)
     setTutorial(tempTutorial)

     if(tempDates.length>0)
     {
         var date2 = new Date()
         var date1= new Date (tempDates[tempDates.length-1])
         // To calculate the time difference of two dates
         var Difference_In_Time = date2.getTime() - date1.getTime();

         // To calculate the no. of days between two dates
         var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        let tempDif= FullRange;
         tempDif+=Math.round( Difference_In_Days )
         setFullRange(tempDif)
     }

     createGraphDataset(Math.round( Difference_In_Days ),response.data.results);
    

     if(response.data.results!==null &&activityName==="Left Right Discrimination" )
     {
       
        var leftRightGather=[];
         response.data.results.forEach(element => {
          API.get(`/events/?activity=${element.id}&ordering=event_type&limit=5000`).then(response=>{
            
            
                var adt = {}
                if(response.data.results.filter(event => event.event_type.includes("basic_avg_decision_time")).length>0)
                {
                  adt["Basic"]= response.data.results.filter(event => event.event_type.includes("basic_avg_decision_time"))
        
                }
        
                if(response.data.results.filter(event => event.event_type.includes("intermediate_avg_decision_time")).length>0)
                {
                  adt["Intermediate"]= response.data.results.filter(event => event.event_type.includes("intermediate_avg_decision_time"))
        
                }
                if(response.data.results.filter(event => event.event_type.includes("advanced_avg_decision_time")).length>0)
                {
                  adt["Advanced"]= response.data.results.filter(event => event.event_type.includes("advanced_avg_decision_time"))
                }
        if(Object.keys(adt).length>0){
        leftRightGather.push(adt)
        
        }
        
        
            
          }).catch(error => console.log(error));

         
         });
         setLeftRightValues(leftRightGather)
     }
    })
  }

  const breadcrumbLinks = () => {
    return [
      {
        label: "Patients",
        url: "/patients"
      },
      {
        label: patientData && `${patientData.first_name} ${patientData.last_name}`,
        url: `/patient/${patientData && patientData.id}`
      },
      {
        label: `${activityName}`,
        url: `/patient/${patientId}/activity/${activityName}`
      }
    ]
  }


    const createGraphDataset = (range,source) => {
		  let datasetHolder = {};
		  let today = new Date();
			let exerciseData = [];
			datasetHolder[activityName] = [];

			let length =  range;
			let seriesEnd =  0

			for (var i = length; i >= seriesEnd; i--) {
				let date = new Date();
				date.setDate(today.getDate() - i);
				let dataPerDate = source.filter(exercise => exercise.activity_name == activityName && moment(date).isSame(moment(exercise.start_time), 'date'));
				let dataPoint = {
					date: date.getTime(),
				}

				let hasData = false;

				if (dataPerDate.length > 0){
                    var tempScore=0;
                    var tempTime=0;
                    var tempAmount=0;
                    dataPerDate.forEach(s => {
                        tempScore+=s.scores.score;
                        tempTime+=moment.utc(getDuration(s));
                        tempAmount++;
                    })
					hasData = tempScore> 0;
					dataPoint[`score`] = tempScore/dataPerDate.length;
          dataPoint['time']=  tempTime/dataPerDate.length;
          dataPoint['amount']=  tempAmount
				 	exerciseData.push(dataPoint);
				} 
				if(hasData){
					datasetHolder[activityName] = exerciseData;
				}
		}	
		setGraphDataset(datasetHolder);
	}

  const LeftRightCheck = () =>{
      if(activityName==="Left Right Discrimination" && LeftRightValues!==null && LeftRightValues.length>0){
      
      var basicLeft=[];
      var basicRight=[];
      var interLeft=[];
      var interRight=[];
      var advLeft=[];
      var advRight=[];

      LeftRightValues.forEach(e =>{
        if(e["Basic"]!==undefined){
        basicLeft.push({date: new moment(e["Basic"][0].date_created).toDate().getTime(),score: parseFloat(e["Basic"][0].event_value )})
        basicRight.push({date: new moment(e["Basic"][1].date_created).toDate().getTime(),score: parseFloat(e["Basic"][1].event_value )})
        }

        if(e["Intermediate"]!==undefined){
          interLeft.push({date: new moment(e["Intermediate"][0].date_created).toDate().getTime(),score: parseFloat(e["Intermediate"][0].event_value )})
          interRight.push({date: new moment(e["Intermediate"][1].date_created).toDate().getTime(),score: parseFloat(e["Intermediate"][1].event_value )})
          }

          if(e["Advanced"]!==undefined){
            
            advLeft.push({date: new moment(e["Advanced"][0].date_created).toDate().getTime(),score: parseFloat(e["Advanced"][0].event_value )})
            advRight.push({date: new moment(e["Advanced"][1].date_created).toDate().getTime(),score: parseFloat(e["Advanced"][1].event_value )})
            }

      })

      return (
        <React.Fragment>

      <h2>Average Decision Time</h2>
      <ActivityPageGraph dateRange={FullRange}  datasource={{LeftRightDiscrimination: basicLeft}} labelFormat={"[value, `seconds`]"} names={["LeftRightDiscrimination"]}  title={" Average Decision Time left Hand Basic"} yKey={"date"} xKey={'score'}/>
      <ActivityPageGraph dateRange={FullRange} datasource={{LeftRightDiscrimination: basicRight}} labelFormat={"[value, `seconds`]"} names={["LeftRightDiscrimination"]}  title={" Average Decision Time Right Hand Basic"} yKey={'date'} xKey={'score'}/>
      <ActivityPageGraph dateRange={FullRange} datasource={{LeftRightDiscrimination: interLeft}} labelFormat={"[value, `seconds`]"} names={["LeftRightDiscrimination"]}  title={" Average Decision Time left Hand Intermediate"} yKey={'date'} xKey={'score'}/>
      <ActivityPageGraph dateRange={FullRange} datasource={{LeftRightDiscrimination: interRight}} labelFormat={"[value, `seconds`]"} names={["LeftRightDiscrimination"]}  title={" Average Decision Time Right Hand Intermediate"} yKey={'date'} xKey={'score'}/>
      <ActivityPageGraph dateRange={FullRange} datasource={{LeftRightDiscrimination: advLeft}} labelFormat={"[value, `seconds`]"} names={["LeftRightDiscrimination"]}  title={" Average Decision Time left Hand Advanced"} yKey={'date'} xKey={'score'}/>
      <ActivityPageGraph dateRange={FullRange} datasource={{LeftRightDiscrimination: advRight}} labelFormat={"[value, `seconds`]"} names={["LeftRightDiscrimination"]}  title={" Average Decision Time Right Hand Advanced"} yKey={'date'} xKey={'score'}/>

      </React.Fragment>



      )
      

  

      }
  }


		

  const generatePDF = () => {
    
    try {
      window.scrollTo(0,0);
      const svgElements = Array.from(content.current.querySelectorAll('svg'));
      svgElements.forEach(s => {
        const bBox = s.getBBox();
        s.setAttribute("x", bBox.x);
        s.setAttribute("y", bBox.y);
        s.setAttribute("width", bBox.width);
        s.setAttribute("height", bBox.height);
      })

      var opt = {
       pagebreak: {mode: ['avoid-all']}
      };
      
 

      html2PDF().set(opt).from(content.current).save(`DAY_VIEW_${patientData.first_name}_${patientData.last_name}_${dayName}`);
    } catch (error) {
      console.log(error);
    }
  }


  return(
    <Page title={activityName} showMenu>
      <Breadcrumbs links={breadcrumbLinks()} />
      {exercises && <React.Fragment>
      <div ref={content}>
        <div className="series">
          {patientData && <PersonCard person={patientData} type="patient" />}
          {clinicianData && <PersonCard person={clinicianData} type="clinician" />}
        </div>
        <br />
        {exercises?<h2>Attempts Section</h2>:""}

        {exercises&&<h3> amount of times activity attempted</h3> }
        {exercises && <React.Fragment><p>{exercises.count} attempts</p></React.Fragment>}
        {exercises&& <h3> amount of times activity was completed</h3> }
        {exercises && <React.Fragment><p>{completedSessions} attempts completed</p></React.Fragment>}
				<ActivityPageGraph dateRange={FullRange} datasource={graphDataset } names={exerciseNames} labelFormat={"[Math.round(value), `Attempts`]"} title={"Amount of Attempts at Activity by Date"} yKey={"date"} xKey={'amount'}/>

        <br /><br />
        {duration>0||pauseTime>0||tutorialTime>0?<h2>Time Section</h2>:""}

        {duration >0 &&<h3>Total Motion Time Spent in Activity</h3> }
        <p>{duration > 0 ? `${moment.duration(duration).hours() > 0 ? (moment.duration(duration).hours() + " hours and ") : ""}${moment.duration(duration).minutes()?moment.duration(duration).minutes() +" minutes ":""}  ${moment.duration(duration).seconds()>0? moment.duration(duration).seconds()+" seconds":""}` : ``}</p>     

        {duration >0 &&<h3>Average Motion Time Per Visit</h3> }
        <p>{duration > 0 ? `${moment.duration(duration/exercises.count).hours() > 0 ? (moment.duration(duration/exercises.count).hours() + " hours and ") : ""}${moment.duration(duration/exercises.count).minutes()?moment.duration(duration/exercises.count).minutes() +" minutes ":""}  ${moment.duration(duration/exercises.count).seconds()>0? moment.duration(duration/exercises.count).seconds()+" seconds":""}` : ``}</p>     
				<ActivityPageGraph dateRange={FullRange} datasource={graphDataset } names={exerciseNames} type={1} title={"Average Motion Time by Date"} yKey={"date"} xKey={'time'}/>

        <br /><br />

        {pauseTime > 0 && (moment.duration(pauseTime).minutes()>0 || moment.duration(pauseTime).hours()>0 ||moment.duration(pauseTime).seconds()>0)? <h3>Total Pause Time Spent in Activity</h3>: <h3>Pause Time is Either Not Available or too Small</h3>  }
        <p>{pauseTime > 0 ? `${moment.duration(pauseTime).hours() > 0 ? (moment.duration(pauseTime).hours() + " hours and ") : ""}${moment.duration(pauseTime).minutes()?moment.duration(pauseTime).minutes() +" minutes ":""}  ${moment.duration(pauseTime).seconds()>0? moment.duration(pauseTime).seconds()+" seconds":""}` : ``}</p>     
        { pauseTime > 0  && (moment.duration(pauseTime/exercises.count).minutes()>0 || moment.duration(pauseTime/exercises.count).hours()>0 || moment.duration(pauseTime/exercises.count).seconds()>0) &&exercises ? <h3>Average Pause Time Per Visit</h3> : pauseTime > 0 && (moment.duration(pauseTime).minutes()>0 || moment.duration(pauseTime).hours()>0 ||moment.duration(pauseTime).seconds()>0)?<p>Average Pause Time too Small to Show</p>:<p></p>}
        <p>{pauseTime > 0 ? `${moment.duration(pauseTime/exercises.count).hours() > 0 ? (moment.duration(pauseTime/exercises.count).hours() + " hours and ") : ""}${moment.duration(pauseTime/exercises.count).minutes()?moment.duration(pauseTime/exercises.count).minutes() +" minutes ":""}  ${moment.duration(pauseTime/exercises.count).seconds()>0? moment.duration(pauseTime/exercises.count).seconds()+" seconds":""}` : ``}</p>     
        <br /><br />
        {totalScore>0?<h2>Score Section</h2>:""}
        <h3>Total Score </h3>  
        {exercises && <React.Fragment>{Math.round(totalScore)}</React.Fragment>}
        <br /><br />
        {personalBestScore&&<h3>Personal Best</h3>}
        {personalBestScore &&<React.Fragment>{personalBestScore}</React.Fragment>}
        <br /><br />
        <h3>Average Score Per Visit</h3>  
        {exercises && <React.Fragment>{Math.round(totalScore/ exercises.count)}</React.Fragment>}
        <br /><br />
        <ActivityPageGraph dateRange={FullRange} datasource={graphDataset } names={exerciseNames} labelFormat={"[Math.round(value), `Score`]"} title={"Average Scores by Date"} yKey={"date"} xKey={'score'}/>

        <br /><br />

        
        {personalBestGoals&&<h2>Goals Section</h2>}
        {personalBestGoals&&<h3>Personal Best Goals</h3>}

        {personalBestGoals&&Object.entries(personalBestGoals).map((element,index)=>{
          return <h4>{element[0]+": "+element[1]}</h4>
        })}

        <br /><br />
      </div>

      <button onClick={generatePDF}>Export</button>
      </React.Fragment>}
      {!exercises &&<React.Fragment>
        <h4>Loading {activityName} Data... </h4><TailSpin	
			height="100"
			width="100"
			color='grey'
			ariaLabel='loading'
		  /><br /> <br />
        </React.Fragment>}
    </Page>
  )
}

export default ActivityPage;

