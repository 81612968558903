import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import CustomDropDown from '../CustomDropDown';
import AddWeekDropDown from './AddWeekDropDown.tsx';

export enum ViewState {
    Active = 1,
    Completed = 2,
    Pending = 3,
    Interrupted=4,
    Cancelled=5,
    CompletedPost=6
}

interface headerVariables
{
    selectedWeek: any;
    state: ViewState;
    coachingPlan:any;
    goToCurrent:any;
    setSelectedWeek:any;
    coachingPlanTemplate:any;
    setCoachingPlanTemplate:any;
    completeWeek:any;
    endPlan:any;
    saveChanges:any;
    addWeeks:any;
    startNewPlan:any;
    currentTemplate:any
    weekOptions:any
    
}

const EditCoachingPlanHead = ({weekOptions,selectedWeek,state,coachingPlan, goToCurrent, setSelectedWeek, coachingPlanTemplate,currentTemplate, setCoachingPlanTemplate,completeWeek,endPlan,saveChanges,addWeeks,startNewPlan}:headerVariables) => {

  const stateBadgeColor=["bg-brand-50",  "bg-warning-50","bg-success-50", "bg-error-50"]
  const stateTextColor=[
    "text-brand-500", "text-warning-500", "text-success-500",  "text-error-500"
  ]

  const Render=()=>{
    switch(state){
    case ViewState.Active:
      break;
    default:
      break;
    }
  }

  return (

    <React.Fragment key={state}>
      {
        state&&state===ViewState.Active&&<div className="w-full grow shrink relative  max-[1200px]:text-xs  max-[1200px]:gap-2 justify-start items-center gap-6 inline-flex">

        <div className="flex-col justify-start items-start inline-flex">
        { weekOptions!==null&&selectedWeek!==null&&<CustomDropDown jumpbackToDefault={true} customBadgePadding={"mr-14 max-[1200px]:text-xs "}  badgePercent={false} defaultValue={selectedWeek} options={weekOptions} right={false}  onchange={setSelectedWeek} textcolor='text-gray-700' badgeColors={stateBadgeColor} badgeText={stateTextColor} />}

        </div>
        <div className="justify-start items-center gap-2.5 flex ">

                 { currentTemplate!==null&&<CustomDropDown jumpbackToDefault={true} defaultValue={currentTemplate} options={coachingPlanTemplate} right={false} onchange={setCoachingPlanTemplate} textcolor='text-gray-700' />}
            <div className="w-6 h-6 justify-center items-center flex has-tooltip">
              <span className='tooltip rounded text-wrap shadow-lg p-1  mt-24'>Changing the Coaching Plan Track mid-plan will overwrite all custom activity assignments</span>
                <img className=" hs-tooltip w-6 h-6 relative flex-col justify-start items-start flex" src='/icons/alert-circle.svg' />
            </div>
        </div>
        {
          selectedWeek===weekOptions.length-1&& <AddWeekDropDown AddWeek={addWeeks}/>
        }
        <div className="grow shrink flex basis-0 self-stretch" />
        <button onClick={saveChanges} className="px-4 py-2  max-[1200px]:text-wrap hover:bg-brand-700 hover:border-brand-700 bg-brand-600 rounded-lg shadow border border-brand-600 justify-center items-center gap-1.5 flex">
            <img className="w-5 h-5 relative" src="/icons/save-01.svg" />
            <div className="px-0.5 justify-center items-center flex">
                <div className="text-white text-base max-[1200px]:text-xs font-semibold font-['Inter'] leading-normal">Save Changes</div>
            </div>
        </button>
        {selectedWeek!==weekOptions.length-1&&<button onClick={completeWeek} className="px-4 py-2  max-[1200px]:text-wrap hover:bg-success-700 hover:border-success-700  bg-success-600 rounded-lg shadow border border-success-600 justify-center items-center gap-1.5 flex">
            <img className="w-5 h-5 relative" src="/icons/check-circle-white.svg"/>
            <div className="px-0.5 justify-center items-center flex">
                <div className="text-white text-base max-[1200px]:text-xs font-semibold font-['Inter'] leading-normal">Complete Week</div>
            </div>
        </button>}
        
        <button onClick={endPlan} className="px-4 py-2  max-[1200px]:text-wrap hover:bg-error-800 hover:border-error-800 bg-error-700 rounded-lg shadow border border-error-700 justify-center items-center gap-1.5 flex">
            <img className="w-5 h-5 relative" src="/icons/slash-octagon.svg" />
            <div className="px-0.5 justify-center items-center flex">
                <div className="text-white text-base max-[1200px]:text-xs font-semibold font-['Inter'] leading-normal">End Plan</div>
            </div>
        </button>
    </div>
      }
      {
        state&&state===ViewState.Pending&&<div className="w-full grow shrink relative  max-[1200px]:text-xs  max-[1200px]:gap-2    justify-start items-center gap-6 inline-flex">

        <div className="flex-col justify-start items-start inline-flex">
        { weekOptions!==null&&selectedWeek!==null&&<CustomDropDown  customBadgePadding={"mr-14 "} badgePercent={false} defaultValue={selectedWeek} options={weekOptions} right={false}  onchange={setSelectedWeek} textcolor='text-gray-700' badgeColors={stateBadgeColor} badgeText={stateTextColor} />}

        </div>
        <button onClick={()=>{goToCurrent()}}  className="px-4 py-2 bg-white hover:bg-gray-300  max-[1200px]:text-wrap rounded-lg shadow border border-gray-300 justify-center items-center gap-1.5 inline-flex">
            <img className="w-5 h-5 p-[1.67px] justify-center items-center flex" src="/icons/arrow-circle-broken-right.svg" />
            <div className="px-0.5 justify-center items-center flex">
                <div className="text-gray-700 max-[1200px]:text-xs text-sm font-semibold font-['Inter'] leading-normal">Current Week</div>
            </div>
        </button>
        {
          selectedWeek===weekOptions.length-1&& <AddWeekDropDown AddWeek={addWeeks}/>
        }
        
        <div className="grow shrink flex basis-0 self-stretch" />
        <button onClick={saveChanges} className="px-4 py-2 text-wrap hover:bg-brand-700  max-[1200px]:text-wrap hover:border-brand-700 bg-brand-600 rounded-lg shadow border border-brand-600 justify-center items-center gap-1.5 flex">
            <img className="w-5 h-5 relative" src="/icons/save-01.svg" />
            <div className="px-0.5 justify-center items-center flex">
                <div className="text-white max-[1200px]:text-xs text-base font-semibold font-['Inter'] leading-normal">Save Changes</div>
            </div>
        </button>
        
    </div>
      }
      {
        state&&state===ViewState.Completed&&<div className="w-full grow shrink relative   max-[1200px]:text-xs  max-[1200px]:gap-2   justify-start items-center gap-6 inline-flex">

        <div className="flex-col justify-start items-start inline-flex">
        { weekOptions!==null&&selectedWeek!==null&&<CustomDropDown  customBadgePadding={"mr-14 "} badgePercent={false} defaultValue={selectedWeek} options={weekOptions} right={false}  onchange={setSelectedWeek} textcolor='text-gray-700' badgeColors={stateBadgeColor} badgeText={stateTextColor} />}

        </div>
        <button onClick={()=>{goToCurrent()}}  className="px-4 py-2  max-[1200px]:text-wrap bg-white hover:bg-gray-300 rounded-lg shadow border border-gray-300 justify-center items-center gap-1.5 inline-flex">
            <img className="w-5 h-5 p-[1.67px] justify-center items-center flex" src="/icons/arrow-circle-broken-right.svg" />
            <div className="px-0.5 justify-center items-center flex">
                <div className="text-gray-700 max-[1200px]:text-xs text-sm font-semibold font-['Inter'] leading-normal">Current Week</div>
            </div>
        </button>
        <div className=" mix-blend-multiply h-7 justify-start items-start inline-flex">
            <div className="pl-3 pr-2.5 py-1 bg-success-50 rounded-2xl justify-center items-center gap-[5px] flex">
                <img className="w-3 h-3 relative" src="/icons/check-green.svg" />
                <div key={coachingPlan} className="text-center  max-[1200px]:text-wrap max-[1200px]:text-xs text-success-700 text-sm font-medium font-['Inter'] leading-tight">Week Completed {coachingPlan!==undefined&&coachingPlan[selectedWeek]!==undefined&&coachingPlan[selectedWeek].end_date!==undefined&& coachingPlan[selectedWeek].end_date.format("MM/DD/YYYY")}</div>
            </div>
        </div>
        <div className="grow shrink flex basis-0 self-stretch" />
        
        
    </div>
      }
       {
        state&&state===ViewState.CompletedPost&&<div className="w-full grow shrink relative   max-[1200px]:text-xs  max-[1200px]:gap-2   justify-start items-center gap-6 inline-flex">

        <div className="flex-col justify-start items-start inline-flex">
        { weekOptions!==null&&selectedWeek!==null&&<CustomDropDown  customBadgePadding={"mr-14 "} badgePercent={false} defaultValue={selectedWeek} options={weekOptions} right={false}  onchange={setSelectedWeek} textcolor='text-gray-700' badgeColors={stateBadgeColor} badgeText={stateTextColor} />}

        </div>
        
        <div className=" mix-blend-multiply h-7 justify-start items-start inline-flex">
            <div className="pl-3 pr-2.5 py-1 bg-success-50 rounded-2xl justify-center items-center gap-[5px] flex">
                <img className="w-3 h-3 relative" src="/icons/check-green.svg" />
                <div className="text-center max-[1200px]:text-xs text-success-700 text-sm font-medium font-['Inter'] leading-tight">Week Completed {coachingPlan[selectedWeek]!==undefined&&coachingPlan[selectedWeek].end_date!==undefined&&coachingPlan[selectedWeek].end_date.format("MM/DD/YYYY")}</div>
            </div>
        </div>
        <div className="grow shrink flex basis-0 self-stretch" />
        <button onClick={startNewPlan}  className="px-4 py-2 bg-success-600 hover:border-success-700 hover:bg-success-700 rounded-lg shadow border border-success-600 justify-center items-center gap-1.5 inline-flex">
            <img className="w-5 h-5 p-[1.67px] justify-center items-center flex" src="/icons/plus-circle.svg" />
            <div className="px-0.5 justify-center items-center flex">
                <div className="text-white text-base max-[1200px]:text-xs font-semibold font-['Inter'] leading-normal">Start New Plan</div>
            </div>
        </button>
        
        
    </div>
      }
       {
        coachingPlan&&state&&state===ViewState.Interrupted&&<div className="w-full grow shrink relative  max-[1200px]:text-xs  max-[1200px]:gap-2    justify-start items-center gap-6 inline-flex">

        <div className="flex-col justify-start items-start inline-flex">
        { weekOptions!==null&&selectedWeek!==null&&<CustomDropDown  customBadgePadding={"mr-14 "} badgePercent={false} defaultValue={selectedWeek} options={weekOptions} right={false}  onchange={setSelectedWeek} textcolor='text-gray-700' badgeColors={stateBadgeColor} badgeText={stateTextColor} />}

        </div>
       
        <div className=" mix-blend-multiply h-7 justify-start items-start inline-flex">
            <div className="pl-3 pr-2.5 py-1 bg-error-50 rounded-2xl justify-center items-center gap-[5px]   max-[1200px]:text-wrap flex">
                <img className="w-3 h-3 relative" src="/icons/x-circle.svg" />
               

                <div className="text-center max-[1200px]:text-xs  max-[1200px]:text-wrap text-error-700 text-sm font-medium font-['Inter'] leading-tight">Week Interrupted {coachingPlan!==undefined&&coachingPlan[selectedWeek]!==undefined&&coachingPlan[selectedWeek].end_date!==undefined&& coachingPlan[selectedWeek].end_date.format("MM/DD/YYYY")}</div>
            </div>
        </div>
        <div className="grow shrink flex basis-0 self-stretch" />
        <button onClick={startNewPlan}  className="px-4 py-2 bg-success-600  max-[1200px]:text-wrap hover:border-success-700 hover:bg-success-700 rounded-lg shadow border border-success-600 justify-center items-center gap-1.5 inline-flex">
            <img className="w-5 h-5 p-[1.67px] justify-center items-center flex" src="/icons/plus-circle.svg" />
            <div className="px-0.5 justify-center items-center flex">
                <div className="text-white max-[1200px]:text-xs text-base font-semibold font-['Inter'] leading-normal">Start New Plan</div>
            </div>
        </button>
        
        
    </div>
      }
       {
        state&&state===ViewState.Cancelled&&<div className="w-full grow shrink relative  max-[1200px]:text-xs  max-[1200px]:gap-2   justify-start items-center gap-6 inline-flex">

        <div className="flex-col justify-start items-start inline-flex">
        { weekOptions!==null&&selectedWeek!==null&&<CustomDropDown  customBadgePadding={"mr-14 "} badgePercent={false} defaultValue={selectedWeek} options={weekOptions} right={false}  onchange={setSelectedWeek} textcolor='text-gray-700' badgeColors={stateBadgeColor} badgeText={stateTextColor} />}

        </div>
        
        <div className=" mix-blend-multiply h-7 justify-start items-start inline-flex">
            <div className="pl-3 pr-2.5 py-1 bg-error-50 rounded-2xl  max-[1200px]:text-wrap justify-center items-center gap-[5px] flex">
                <img className="w-3 h-3 relative" src="/icons/x-circle.svg" />
                <div className="text-center max-[1200px]:text-xs text-error-700 text-sm font-medium font-['Inter'] leading-tight">Week Cancelled {coachingPlan[selectedWeek].end_date.format("MM/DD/YYYY")}</div>
            </div>
        </div>
        <div className="grow shrink flex basis-0 self-stretch" />
        <button onClick={startNewPlan}  className="px-4 py-2  max-[1200px]:text-wrap bg-success-600 hover:border-success-700 hover:bg-success-700 rounded-lg shadow border border-success-600 justify-center items-center gap-1.5 inline-flex">
            <img className="w-5 h-5 p-[1.67px] justify-center items-center flex" src="/icons/plus-circle.svg" />
            <div className="px-0.5 justify-center items-center flex">
                <div className="text-white max-[1200px]:text-xs text-base font-semibold font-['Inter'] leading-normal">Start New Plan</div>
            </div>
        </button>
        
        
    </div>
      }
    </React.Fragment>
  )
}

export default EditCoachingPlanHead