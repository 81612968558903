import React, { useEffect, useRef, useState } from 'react';
import { isEmail, minLength, maxLength } from '../../utility/Forms';

const Input = ({ name, size, type, value,readonly=false ,onChange, noLabel, formErrors, validations, required , overrideLabel, overrideText, className=""}) => {

   
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if(formErrors!==null){
        console.log("FORM ERRORS", formErrors);
        }
        formErrors && validate();
    }, [formErrors])
   
    const onBlur = ( event ) => {

        // console.log("Blur", event);
        setErrors([]);
        validate();
    }

    const handleChange = ( event ) => {
        onChange(event.target.value);
        setErrors([]);
    }

    const validate = () => {
        value && validations && validations.map(validation => {
            switch(validation.type){
                case "email":
                    !isEmail(value) && setErrors(errors => [...errors, "Not a valid email address"]);
                    break;
                case "minLength":
                    !minLength(value, validation.value) && setErrors(errors => [...errors, `${name} must be at least ${validation.value} characters`]);
                    break;
                case "maxLength":
                    !maxLength(value, validation.value) && setErrors(errors => [...errors, `${name} cannot be more ${validation.value} characters`]);
                    break;
            }
        })
        required && (value === null || value === undefined || value.length === 0) && setErrors(errors => [...errors, `${name} is required`]);
    }

    const renderErrors = errors && errors.map((error, i) => 
        <div key={i}>{error}</div>
    )



    return(
        <React.Fragment>
            {/*!noLabel && <h4>{overrideLabel?overrideLabel: name}</h4>*/}
           { /*<div className="form-error">{renderErrors}</div>*/}
            <input 
                placeholder={overrideText!==null?(overrideText):(!noLabel ? `Enter ${name.toLowerCase()}` : name)} 
                className={className}
                type={type} 
                value={value} 
                onBlur={onBlur} 
                onChange={handleChange} 
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                readOnly={readonly}
                />
        </React.Fragment>
        
    ) 
}
export default Input;

