import React from 'react';
import moment from 'moment-timezone';
import { useState } from 'react';
import API from '../services/API';
import { useEffect } from 'react';
import { getDuration } from '../utility/Visit';
import { getDurationComplete } from '../utility/Visit';
 
import { getTimezone } from '../utility/UserUtility';
import { words } from 'lodash';
 
const Exercise = ({ exercise, hide, scorecard }) => {
  const [exerciseEvents, setExerciseEvents] = useState(null);
 const [SkipEvents, setSkipEvents] = useState(null);
 const [ArmEvents, setArmEvents] = useState(null);
 const [SitEvents, setSitEvents] = useState(null);
 const [RaiseEvents, setRaiseEvents] = useState(null);
 const [RulingEvents, setRulingEvents] = useState(null);
 const [LeftRightValues, setLeftRightValues ] = useState(null);
 const [noEvents, setNoEvents]= useState(false);
 useEffect(() => {
   if( SkipEvents===null && ArmEvents===null && RaiseEvents===null && SitEvents===null &&RulingEvents===null)
   getExerciseEvents();
  
 }, [])
 
 
 const timeSpent = () => {
   if(exercise.motion_time!==0){
     return <p>{getDuration(exercise) > 0 ? `The patient was in motion for ${moment.duration(getDuration(exercise)).hours() > 0 ? (moment.duration(getDuration(exercise)).hours() + " hours and ") : ""}${moment.duration(getDuration(exercise)).minutes()?moment.duration(getDuration(exercise)).minutes() +" minutes ":""}  ${moment.duration(getDuration(exercise)).seconds()>0? moment.duration(getDuration(exercise)).seconds()+" seconds":""}` : `There is no duration data for this day.`}</p>    
   }
   else
   {
     let duration =getDurationComplete(exercise)
 
     return <p>{getDurationComplete(exercise) > 0 ? `motion data not available, duration of ${moment.duration(getDurationComplete(exercise)).hours() > 0 ? (moment.duration(getDurationComplete(exercise)).hours() + " hours and ") : ""}${moment.duration(getDurationComplete(exercise)).minutes()?moment.duration(getDurationComplete(exercise)).minutes() +" minutes ":""}  ${moment.duration(getDurationComplete(exercise)).seconds()>0? moment.duration(getDurationComplete(exercise)).seconds()+" seconds":""}` : `There is no motion or duration data for this day.`}</p>    
 
 
   }
 }
 const pauseSpent = () => {
   return <p>{exercise.paused_time* 1000 > 0 ? `The patient was paused for ${moment.duration(exercise.paused_time* 1000).hours() > 0 ? (moment.duration(exercise.paused_time* 1000).hours() + " hours and ") : ""}${moment.duration(exercise.paused_time* 1000).minutes()?moment.duration(exercise.paused_time* 1000).minutes() +" minutes ":""}  ${moment.duration(exercise.paused_time* 1000).seconds()>0? moment.duration(exercise.paused_time* 1000).seconds()+" seconds":""}` : ""}</p>    
 
 }
 const tutorialSpent = () => {
   return <p>{exercise.tutorial_time* 1000 > 0 ? `The patient was in a tutorial for ${moment.duration(exercise.tutorial_time* 1000).hours() > 0 ? (moment.duration(exercise.tutorial_time* 1000).hours() + " hours and ") : ""}${moment.duration(exercise.tutorial_time* 1000).minutes()?moment.duration(exercise.tutorial_time* 1000).minutes() +" minutes ":""}  ${moment.duration(exercise.tutorial_time* 1000).seconds()>0? moment.duration(exercise.tutorial_time* 1000).seconds()+" seconds":""}` : ""}</p>    
 
 }
 
 const ArmCheck = () => {
   if(ArmEvents && ArmEvents.length>0){
     return <p>Arm used: {ArmEvents[0].event_value}</p>
   }
 }
   const SitCheck = () => {
     if(SitEvents && SitEvents.length>0){
       return <p>Patient Was {SitEvents[0].event_value}</p>
     }
 
 }
 
 const SkipCheck = () => {
   if(SkipEvents && SkipEvents.length>0){
     return <p>Patient Skipped {SkipEvents.length} Times Within The Activity</p>
   }
}

const ActivityRestartCheck = () => {
  if(exercise.restarted)
  {
  return <p>Patient restarted This Activity </p>
  }
}

const ActivitySkipCheck = () => {
    if(exercise.skipped)
    {
    return <p>Patient Skipped This Activity </p>
    }
}
 
const RaiseCheck = () => {
 if(RaiseEvents && RaiseEvents.length>0){
   return <p>Patient Raised Puzzle Pieces {RaiseEvents.length} Times</p>
 }
}
 
const ProgressCheck=()=>{
  if(exercise.progress!==undefined)
  {
    return <p>Progress: {parseFloat(exercise.progress).toFixed(2)*100}%</p>
  } 
}

const CompleteCheck=()=>{
  if("status" in exercise &&exercise.status!=="Completed")
  {
    return <p>Activity Not Completed</p>

  }
}

const LeftRightCheck=()=>{
  if(LeftRightValues!==null)
  {
    const h = Object.entries(LeftRightValues).map((element,index)=>(
      <div key={index} className="row">
						<h4 className="chart-label-narrow">{element[0]} </h4>
            <h4 className="chart-label-narrow">{element[1][0]!==null && element[1][0]!==undefined? element[1][0].event_value:"0"} </h4>
            <h4 className="chart-label-narrow">{element[1][1]!==null && element[1][1]!==undefined?element[1][1].event_value:"0"} </h4>

			</div>

    ))
    return (
    <React.Fragment>

    <h3> Average Decision Time</h3> 
    <div className="row">

						<p className="chart-label-narrow"><b>Difficulty</b></p>
						<p className="chart-label-narrow"><b>Left Hand</b></p>
            <p className="chart-label-narrow"><b>Right Hand</b></p>

				</div>
     {h}
    </React.Fragment>
    )

  }
}
 
const camelCaseParser=(text)=>{
  return text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
}

const ProtocolCheck=()=>{
  if(exercise.protocol_name!==undefined && exercise.protocol_name!==exercise.activity_name)
  {
    return <p>Part of {exercise.protocol_name} Protocol</p>
  }
}

const rulingList = RulingEvents && RulingEvents.map((element) => (
 
 <React.Fragment>
   {
     element.substring(element.length - 2, element.length - 1)==="1"? (<h4> {element.substring(0, element.length - 4) } : {(element.substring(element.length - 2, element.length - 1)==="1"?"yes":"no")}  </h4>):<span></span>
    
}
 </React.Fragment>
 
 
))
 
 
 
 const getExerciseEvents = () => {
  API.get(`/events/?activity=${exercise.id}&ordering=event_type&limit=5000`).then(response=>{
    var x = response.data.results.filter(event => event.event_value!==null );
    
     setExerciseEvents(x);

     setSkipEvents(response.data.results.filter(event => event.event_type==="SKIP"));
    
     setArmEvents(response.data.results.filter(event => event.event_type==="AFFECTED_ARM"||event.event_type==="affected_arm"));
     setSitEvents(response.data.results.filter(event => event.event_type==="seated or standing"||event.event_type==="POSE"));
     setRaiseEvents(response.data.results.filter(event => event.event_type.includes("PaintingPuzzle__PIECES_RAISED")));
      if(exercise.activity_name==="Left Right Discrimination")
      {
        var adt = {}
       
        if(response.data.results.filter(event => event.event_type.includes("BASIC_AVG_DECISION_TIME")).length>0)
        {
          adt["Basic"]= response.data.results.filter(event => event.event_type.includes("BASIC_AVG_DECISION_TIME"))

        }

        if(response.data.results.filter(event => event.event_type.includes("INTERMEDIATE_AVG_DECISION_TIME")).length>0)
        {
          adt["Intermediate"]= response.data.results.filter(event => event.event_type.includes("INTERMEDIATE_AVG_DECISION_TIME"))

        }
        if(response.data.results.filter(event => event.event_type.includes("ADVANCED_AVG_DECISION_TIME")).length>0)
        {
          adt["Advanced"]= response.data.results.filter(event => event.event_type.includes("ADVANCED_AVG_DECISION_TIME"))
        }


        

        setLeftRightValues(adt);
      }
     if(response.data.results.filter(event => (event.event_type==="RESULTS"||event.event_type==="ruling_results")).length>0)
     {
      
       var rulings= response.data.results.filter(event => event.event_type==="RESULTS"||event.event_type==="ruling_results")[0].event_value.split('[');
       if(rulings.length>1)
       {
       rulings.shift();
       setRulingEvents(rulings)
       }
 
     }
     if(x.length===0)
     {
      setNoEvents(true);
     }
 
    
   }).catch(error => console.log(error));
 }
 
const RenderGoalsLabel=()=>{
  if("scores" in exercise && "goals" in exercise.scores)
  {
    return    <span> <h3>Goals</h3></span>
  }
} 

const RenderNewGoals=()=>{
  if("scores" in exercise && "goals" in exercise.scores)
  {
    var goals=exercise.scores.goals
    return goals.map(goal=>{
      return <div >
     
     <div key={0} className="goals">
       <h4>{goal.label}</h4>
       <h4><b>{goal.completions}</b></h4>
     </div>
     {"top_alltime" in goal && goal.top_alltime? <p className="goallabel1">This was an all-time best</p>: <span></span>}
     {"top_weekly" in goal && goal.top_weekly? <p className="goallabel2">This was a weekly best</p>: <span></span>}
     {scorecard!==null&& "scores" in scorecard && "activity" in scorecard.scores&&  exercise.activity_id in scorecard.scores.activity && "first_top_goals" in scorecard.scores.activity[exercise.activity_id] && goal.label in scorecard.scores.activity[exercise.activity_id].first_top_goals && scorecard.scores.activity[exercise.activity_id].first_top_goals[goal.label] !==goal.completions ?<p className="goallabel3">Current all-time best is {scorecard.scores.activity[exercise.activity_id].first_top_goals[goal.label]}</p>:<span></span>}
     {scorecard!==null&& "scores" in scorecard && "activity" in scorecard.scores&&  exercise.activity_id in scorecard.scores.activity && "top_goals" in scorecard.scores.activity[exercise.activity_id] && goal.label in scorecard.scores.activity[exercise.activity_id].top_goals && scorecard.scores.activity[exercise.activity_id].top_goals[goal.label] !==goal.completions ?<p className="goallabel3">Current all-time best is {scorecard.scores.activity[exercise.activity_id].top_goals[goal.label]}</p>:<span></span>}

   </div>
    })
  }
}


 const renderGoals = ( goalType  ) => {
   var goal=0;
   var top=null;
   if(exercise.activity_name=="Breathing")
   {

    return;
   }
   
  
    let events = exerciseEvents.filter(event => event.event_type === 'goals'    &&  JSON.parse(event.event_value).key.goal_type==goalType); 
   top=events[events.length-1]
   events.forEach(event => {
     if(JSON.parse(event.event_value).value>JSON.parse(top.event_value).value)
     {top=event}
   });
   if(top!=null)
   {
     return <div >

       {top!=null && <h3>{goalType == 4 ? "Reps" : "Goals"}</h3>}

       <div key={0} className="goals">
         <h4>{JSON.parse(top.event_value).key.label}</h4>

         <h4><b>{JSON.parse(top.event_value).value}</b></h4>
       </div>
     </div>
   }
 

  
  
 }
 
 const renderScore = () => {

   return "score" in exercise.scores&& exercise.scores.score!==0 && <div className="score">
     

     <h4>SCORE:</h4>
     <h3>{exercise.scores.score}</h3>
     
     {"top_alltime" in exercise.scores && exercise.scores.top_alltime? <p className="score1">This was an all-time best</p>: <span></span>}
     {"top_weekly" in exercise.scores && exercise.scores.top_weekly? <p className="score2">This was a weekly best</p>: <span></span>}
  

     {scorecard!==null&& "scores" in scorecard && "activity" in scorecard.scores&&  exercise.activity_id in scorecard.scores.activity && "top_score" in scorecard.scores.activity[exercise.activity_id] && scorecard.scores.activity[exercise.activity_id].top_score!== exercise.scores.score?<p className="score3">Current all-time best is {scorecard.scores.activity[exercise.activity_id].top_score}</p>:<span></span>}
     {scorecard!==null&& "scores" in scorecard && "activity" in scorecard.scores&&  exercise.activity_id in scorecard.scores.activity && "top_score" in scorecard.scores.activity[exercise.activity_id] && scorecard.scores.activity[exercise.activity_id].top_score=== exercise.scores.score?<p className="score3">Is current all-time best</p>:<span></span>}

     </div>
     
    
 }
 
 return (
   <div className="exercise">
     <h3>{ camelCaseParser( exercise.activity_name)}</h3>
  {
      !hide?  (  <React.Fragment>

     <div className="start-end">
       <div className="small">
         <h4>START </h4> {exercise.start_time!=null?<p>{moment(exercise.start_time).tz(getTimezone()).format("h:mm a")}</p>:<p>none</p>}
       </div>
       <div className="small">
         <h4>END </h4> {exercise.end_time!=null?<p>{moment(exercise.end_time).tz(getTimezone()).format("h:mm a")}</p>:<p>none</p>}
       </div>
     </div>
     </React.Fragment>):""
  }
{
  !hide?(
    <React.Fragment>

     <p>{ProtocolCheck()}</p>
     <p>{ProgressCheck()}</p>
     <p>{ActivitySkipCheck()}</p>
     <p>{ActivityRestartCheck()}</p>

     <p>{CompleteCheck()}</p>
     <p>{SitCheck()}</p>
     <p>{ArmCheck()}</p>
     <p>{SkipCheck()}</p>
     <p>{RaiseCheck()}</p>

     {rulingList }
     </React.Fragment>

  ):""
}

     <p>{timeSpent()}</p>
     <p>{pauseSpent()}</p>

     {
  !hide?(
    <React.Fragment>
     <p>{LeftRightCheck()}</p>
<br/>
     {exerciseEvents && <div className="events">
       {renderGoals(4)}
       {renderGoals(0)}
       { RenderGoalsLabel()}
       {RenderNewGoals()}
       {renderScore()}
       </div>}
       </React.Fragment>):""
       }
   </div>
 )
}
 
export default Exercise;

