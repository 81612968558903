import VetkitAssignment from "../components/VetkitAssignment";

export const isPending = ( vetkit ) => {
  return (vetkit.location.includes('Transit') && destination(vetkit) != "Karuna") || (vetkit.status == "Maintenance" && destination(vetkit) != "Karuna");
}

export const destination = ( vetkit ) => {
  const words = vetkit.location.split(" ");
  return words[words.length - 1];
}

export const canReturnToKaruna = ( vetkit ) => {
  return vetkit.location == "At Provider";  
}


export const canReturnToProvider = (vetkit) => {
  return vetkit.location == "At Patient";
}

export const canCompleteAssigment = ( vetkit ) => {
  console.log("checking complete assignment", vetkit);
  return vetkit.location == "At Patient";
}

