import React, { useState, useEffect } from 'react';
import API from '../../services/API';
import { withRouter, useParams } from 'react-router';
import { element, object } from 'prop-types';







const CoachingPlanList = ({  coachingPlan,protocols}) => { 

	const camelCaseParser=(text)=>{
		return text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
	}

	const GetTotalProg=(prog)=>{
		var sum=0
		var count =0
		Object.entries(prog).forEach(element=>{
			count++;
			sum+=element[1][Object.keys(element[1])[0]]
		})
		return sum/count;
	}

	
    let total =0;
    coachingPlan && Object.entries(coachingPlan.progress).forEach(element=>{
		if(typeof element[1]!=="object")
		{
         total+=element[1]*100;
		}
		else
		{
			total+=GetTotalProg(element[1]);

		}
    })    
	
	

	const activityList = coachingPlan &&coachingPlan.progress && Object.entries(coachingPlan.progress).map((actData, index) => (
		<div key={index} className="row">
			<h4 className="chart-label">{camelCaseParser( protocols[ actData[0]])} </h4>
            <h4 className="chart-label-narrow">{ typeof actData[1]==="object"?(GetTotalProg(actData[1])).toFixed():(actData[1]*100).toFixed()}%</h4>
           
		</div>
	))
		
	

	return (
		<div className="CoachingPlanList">

			<React.Fragment>
			{Object.entries(coachingPlan.progress).length>0?<h4>Week {coachingPlan.plan_week}</h4>:<h3>No Bonus Activity Data To Display In Week {coachingPlan.plan_week}</h3>}
			{activityList}
            <div  className="row">
			<h4 className="chart-label">Total Percentage </h4>
            <h4 className="chart-label-narrow">{Object.entries(coachingPlan.progress).length>0? (total/  Object.entries(coachingPlan.progress).length).toFixed():"0"}%</h4>

            {        
}
			</div>           
			

			</React.Fragment>
			<br />

		</div>
		

		
	);
}


export default withRouter(CoachingPlanList);
