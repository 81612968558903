import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { sendToKaruna,  returnToProvider } from '../../services/VetkitServices';
import { canReturnToKaruna, canReturnToProvider } from '../../utility/VetkitUtility';
import ButtonConfirmation from '../cards/ButtonConfirmation';
import DropdownInput from '../input/DropdownInput'
import Input from '../input/Input';

const ReturnToProviderButton = ({ vetkit, label, onChange }) => {

  const [deliveryMethod, setDeliveryMethod] = useState(1);
  const [returnReason, setReturnReason] = useState(1);
  const [returnNotes, setReturnNotes] = useState("");

 

  const returnToProviderAction = () => {
    returnToProvider(vetkit.vetkit_assignment, deliveryMethod, returnReason, returnNotes).then(response => {
      onChange && onChange();
    });
  }


  return(
    <React.Fragment>
      {vetkit && canReturnToProvider(vetkit) && <ButtonConfirmation label={label ? label : `Start return`} action={returnToProviderAction} message="Please confirm that the patient has returned the headset to your office. Also, please specify the delivery method and reason for return." >
        <DropdownInput 
        name="Delivery method"
        options={[{label: "By mail", id: 1}, {label: "Handed directly to provider", id: 2}]}
        value={deliveryMethod}
        onChange={setDeliveryMethod}
        />
        <DropdownInput
        name="Return reason"
        options={[{label: "Protocol complete", id: 1}, {label: "Equipment malfunction", id: 2}]}
        value={returnReason}
        onChange={setReturnReason}
        />
        <Input name="Notes" value={returnNotes} onChange={setReturnNotes} />
        
        </ButtonConfirmation>}    
    </React.Fragment>
    
  )
}

export default withRouter(ReturnToProviderButton);