import React, { useState, useEffect } from 'react';
import { withRouter,useParams } from 'react-router';
import { labelToURL } from '../../utility/Utility';
import moment from 'moment-timezone';
import { useHistory } from "react-router-dom";
import { getTimezone } from '../../utility/UserUtility';

const OutcomeVerRow = ({ patient, outcomeCollection=[], Click, currentPatient, index}) => {
	const patientId = useParams().id;
	const timezone = getTimezone();
	let history = useHistory();
	const [show, setShow] = useState(false);
	const LoadPage=(id)=>{

		if(id){

		history.push(`${id && `/outcomes/${id}`}`);
        }

	}



	useEffect(() => {
	}, []);

    const HandleClick=()=>{
        var x= !show;
        setShow(x);
		if(x){
			Click(index);
		}
		else{
			Click(-1);
		}    
    }

    useEffect(() => {
        if(currentPatient!==index && show)
		{
			setShow(false);
		}
	}, [currentPatient]);

	const DateDisplay=(day)=>{
		var d = moment(day).tz(timezone);
		return d.format("MM/DD/YYYY");
		

	}

	const dateConvert=(day)=>{
		var date = moment(day).tz(timezone);
		var formatted= date.format('MM/DD/YYYY');
		return formatted

	}
    const outcomes= outcomeCollection&&outcomeCollection.map((outcome, index) => 
    <React.Fragment>

    <div className="DayRow" onClick={(()=>{LoadPage(outcome.id)})}>
	<h4 style={{paddingLeft:"24px",float:"left"}}>{""}</h4>

<span  className="row-table">

            {
				<h4 className="chart-label-center nohover">{"Week "+outcome.included_weeks+" Report"}</h4>

			}
			{
				<h4 className="chart-label-center nohover">{DateDisplay( outcome.prepared_date)}</h4>

			}
            {
				<h4 className="chart-label-center" >{""}</h4>

			}{
				<h4 className="chart-label-center" >{""}</h4>

			}{
				<h4 className="chart-label-center" >{""}</h4>

			}{
				<h4 className="chart-label-center" >{""}</h4>

			}
            {
				<h4 className="chart-label-center" >{"Click Row to Review"}</h4>

			}
			
		</span>	
		</div>
    </React.Fragment>
    )
	return (
<React.Fragment>
		<div className="PatientRow" onClick={HandleClick}>
		
<span  className="row-table">
{
		<h4 style={{float:"left"}}>{!show?"►":"▼"}</h4>
	}
{
				<h4 className="chart-label-center">{patient.first_name+" "+patient.last_name+" ("+(outcomeCollection? outcomeCollection.length.toString() : "0")+")"}</h4>
}
{
                <h4 className="chart-label-center">{patient.coach_name}</h4>
			}
            {
                <h4 className="chart-label-center">{patient.personal_routine!==undefined?(patient.personal_routine.plan_week!==undefined?patient.personal_routine.plan_week:"No Current Coaching Plan"):"No Current Coaching Plan"}</h4>
			}
            {
                <h4 className="chart-label-center">{patient.session_count}</h4>
                
			}
            
            {
                <h4 className="chart-label-center">{patient.physician_name}</h4>
			}
            {
                <h4 className="chart-label-center">{patient.profile.third_party_administrators}</h4>
			}
            {
                <h4 className="chart-label-center">{patient.profile.insurers}</h4>
			}
            
		</span>	
		</div>
        {show&&currentPatient===index?(outcomes.length>0?outcomes:(<React.Fragment>

<div className="DayRow" onClick={()=>{}}>
<h4 style={{paddingLeft:"24px",float:"left"}}>{""}</h4>

<span  className="row-table">

			<h4 className="chart-label-centerp nohover" >{"No Outcome Reports"}</h4>
			<h4 className="chart-label-centerp nohover" >{""}</h4>
			<h4 className="chart-label-centerp nohover" >{""}</h4>
			<h4 className="chart-label-centerp nohover" >{""}</h4>
			<h4 className="chart-label-centerp nohover" >{""}</h4>
			<h4 className="chart-label-centerp nohover" >{""}</h4>
			<h4 className="chart-label-centerp nohover" >{""}</h4>
			<h4 className="chart-label-centerp nohover" >{""}</h4>
			<h4 className="chart-label-centerp nohover" >{""}</h4>		
	</span>	
	</div>
</React.Fragment>)):""}
        </React.Fragment>
	)

	
}

export default withRouter(OutcomeVerRow);