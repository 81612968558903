import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import API from '../../services/API';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend, BarChart, Bar, AreaChart, Area, Label } from 'recharts';
import moment from 'moment-timezone';
import RangePicker from './RangePicker';

const ActivityPageGraph = ({ datasource, yKey,xKey,names, labelFormat,title ,dateRange, type= 0}) => { 




	const colors = ['#5B8BC8', '#F36C33', '#FCB242', '#005BA3', '#414042'];

	

	const graphs = names && names.map((Name, index) => (
		
		<React.Fragment key={index}>
			{datasource && datasource[Name].length > 1 &&
				<React.Fragment>
				<h3>{title}</h3>
					<LineChart width={400} height={150} className="chart" data={datasource && datasource[Name]} margin={{ top: 32, right: 5, left: 0, bottom: 24 }} >

						<XAxis dataKey={yKey}
							domain={[moment().subtract(dateRange, "days").valueOf(), new Date().getTime()]}
							type='number'
							scale="time"
							tickFormatter={tick => moment(tick).format('MM/DD/YY')}
							tick={{ fontSize: 12 }}
							tickMargin={8}
						>
						</XAxis>
						<YAxis tick={{ fontSize: 12 }}
							tickMargin={8}
							domain={['auto', 'auto']}
						/>
						<Tooltip itemStyle={{ "color": "#0898D0", "text-align": "center" }} item labelFormatter={(label) => { return moment(label).format("MMM Do, YYYY") }} formatter={(value, name) => { return eval(labelFormat )}} />
						<Line key={index} type="monotone" dataKey={xKey} stroke={colors[index % colors.length]} fillOpacity={0.75} />
					</LineChart>

				</React.Fragment>
			
			}
		</React.Fragment>
		
	))


    const graphTime = names && names.map((Name, index) => (
		
		<React.Fragment key={index}>
			{datasource && datasource[Name].length > 1 &&
				<React.Fragment>
				<h3>{title}</h3>
					<LineChart width={400} height={150} className="chart" data={datasource && datasource[Name]} margin={{ top: 32, right: 5, left: 0, bottom: 24 }} >

						<XAxis dataKey={yKey}
							domain={[moment().subtract(dateRange, "days").valueOf(), new Date().getTime()]}
							type='number'
							scale="time"
							tickFormatter={tick => moment(tick).format('MM/DD/YY')}
							tick={{ fontSize: 12 }}
							tickMargin={8}
						>
						</XAxis>
						<YAxis tick={{ fontSize: 12 }}
							tickMargin={8}
							domain={['auto', 'auto']}
						/>
						<Tooltip itemStyle={{ "color": "#0898D0", "text-align": "center" }} item labelFormatter={(label) => { return moment(label).format("MMM Do, YYYY") }} formatter={(value, name) => { return [`${moment.duration(value).hours() > 0 ? (moment.duration(value).hours() + ' hours and ') : ''}${moment.duration(value).minutes()} minutes.`, `Time`]}} />
						<Line key={index} type="monotone" dataKey={xKey} stroke={colors[index % colors.length]} fillOpacity={0.75} />
					</LineChart>

				</React.Fragment>
			
			}
		</React.Fragment>
		
	))

	

	

	return (
		<div className="graph">

			{type===0?graphs:graphTime}
			
		</div>
		
	);
}


export default ActivityPageGraph;
