import React from 'react'

const SuccessNotification = ({index, whenClick, message}) => {
  return (
    <span className='w-full justify-center items-center inline-flex '>

    <div key={index} id={index} class="sticky w-[calc(100%-5px)] mt-[5px] h-10 px-3 py-2 text-success-700 bg-success-100 rounded-md border border-success-700 justify-start items-center gap-3 inline-flex" role="alert">
                      <img src="/icons/check-green.svg" className='w-5 h-5 p-0.5 fill-success-700 justify-center items-center inline-flex'/>
                      <div className="text-sm font-normal font-['Inter'] leading-tight">{message}</div>
                      <span class="absolute  right-0 px-4 py-2">
                        <svg class=" fill-current h-6 w-6 text-gray-700 inline-flex" role="button" onClick={ whenClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                      </span>
      </div>
      </span>
                    
  )
}

export default SuccessNotification