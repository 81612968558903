import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import API from '../services/API';
import {  } from '../services/VetkitServices';
import { canCompleteAssigment } from '../utility/VetkitUtility';
import ReturnToProviderButton from './buttons/ReturnToProviderButton';
import PersonCard from './cards/PersonCard';
import VetkitCard from './cards/VetkitCard';
import { useHistory } from "react-router-dom";


const VetkitAssignment = ({ vetkitAssignmentId, showPatient, showClinician, onChange ,patientData=null, clinicianData=null}) => { 

	const [assignment, setAssignment] = useState(null);
	const [clinician, setClinician] = useState(null);
	const [patient, setPatient] = useState(null);
	const [vetkit, setVetkit] = useState(null);
	let history = useHistory(); 


	useEffect(() => {
		console.log(vetkitAssignmentId);
		getVetkitAssignment();
	}, [])

	useEffect(() => {
		console.log("ASSIGNMENT", assignment)
		if(assignment){
			showClinician && assignment.physician_id && getClinicianData(assignment.physician_id);
			getPatientData(assignment.patient);
			getVetkitData(assignment.vetkit);
		}
		
	}, [assignment])

	const updateData = () => {
		onChange && onChange();

		history.push(window.location.url);
	}

	const getVetkitAssignment = () => {
		API.get(`/vetkitassignments/${vetkitAssignmentId}`).then(response => {
			console.log(response.data);
			setAssignment(response.data);
		}).catch(error => console.log(error));
	}

	const getClinicianData = (id) => {
		if(clinicianData===null)
		{
		API.get(`/physicians/${id}`).then(response => {
			console.log(response.data);
			setClinician(response.data);
		}).catch(error => console.log(error));
		}
		else
		{
			setClinician(clinicianData)
		}
	}

	const getPatientData = (id) => {
		if(patientData===null){
		API.get(`/patients/${id}`).then(response => {
			console.log(response.data);
			setPatient(response.data);
		}).catch(error => console.log(error));
		}
		else{
			setPatient(patientData)
		}
	}

	const getVetkitData = (id) => {
		API.get(`/vetkits/${id}`).then(response => {
			console.log(response.data);
			setVetkit(response.data);
		}).then(error => console.log(error));
	}

	const renderClinician = clinician && <PersonCard person={clinician} type="physician" />;
	const renderPatient = showPatient && patient && <PersonCard person={patient} type="patient" />;
	const renderVetkit = vetkit && <VetkitCard vetkit={vetkit} onChange={updateData} />
	const renderPin = assignment && assignment.pin_code && <h3>Pin Code: {assignment.pin_code}</h3>

	const completeAssignment = () => {
		// sendToProvider(assignment.id).then(response => {
		// 	history.push(window.location.url);
		// });
	}



	return (
		
		<div className="vetkit-assignment">
			<h5>VET Kit Assignment</h5>
			<h4>Due date</h4>
			<h3>{assignment && moment(assignment.date_due).format("MMM DD, YYYY")}</h3>
			<div className="items">
				{renderVetkit}
				{renderPatient}
				{renderClinician}
			</div>
			{renderPin}

			
			<ReturnToProviderButton vetkit={vetkit} onChange={updateData} label="Complete assignment" />
		</div>
	);
}

export default withRouter(VetkitAssignment);

