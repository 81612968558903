import cookie from 'react-cookies';
import { debug } from 'react-router-breadcrumbs';
import Cookies from 'universal-cookie';
import API from './API';
import { useHistory } from "react-router-dom";


API.interceptors.response.use(response => {
	return response;
 }, error => {
	if(error.response!==undefined){
   if (error.response.status === 401) {
	const domain = window.location.origin.includes("localhost") ? "" : "karunalabs.com";

	console.log(401)
	cookie.remove('token');
	cookie.remove('csrftoken', { path: '/' });
	cookie.remove('role', { path: '/' });
	cookie.remove('user', { path: '/' });
	localStorage.clear();
	let history = useHistory(); 
	if ( !document.URL.includes("login") ) {
			history.push('/login');
	}   
	}
	}
	if(error.message!==undefined)
	{
		if(error.message==="Network Error")
		{

		}
	}
   return error;
 });

export const login = (email, password, onLoginSuccess, onLoginFail) => {
	const cookies = new Cookies(null, { path: '/' });

	const domain = window.location.origin.includes("localhost") ? "" : "karunalabs.com";
		console.log('Token: ' + cookie.load('token'))

	console.log("clear tokens")
	cookie.remove('token', { path: '/' });
		cookie.remove('csrftoken', { path: '/' });
		cookie.remove('role', { path: '/' });
		cookie.remove('user', { path: '/' });
	localStorage.clear();
	console.log('Token: ' + cookie.load('token'))

	console.log(window.location.origin);

	API.post('login/', JSON.stringify({
		email: email,
		password: password
		
	}))
		.then(response => {
			
			if(response.message!=undefined && response.message.includes("Request failed with status code 400"))
			{
				onLoginFail && onLoginFail("Username or password is incorrect");
				return;
			}

			const domain = window.location.origin.includes("localhost") ? "" : "karunalabs.com";
			console.log(window.location.origin);
			if (response.data.token && response.data.token != "") {

				console.log("Saving cookie", response.data.token);
				console.log(window.location.protocol);
				const isSecure = window.location.protocol === "https:";
				console.log(isSecure)
				!isSecure && console.log("IS NOT SECURE");
				console.log(1);
				cookies.set('token', response.data.key, { maxAge: 34560000,  path: "/", sameSite: isSecure ? 'none' : false, secure: isSecure });
				console.log(2);

				cookies.set('role', getRole(response.data.role), {maxAge: 34560000, path: "/", sameSite: isSecure ? 'none' : false, secure: isSecure  })
				
				cookies.set('user', response.data.user, { maxAge: 34560000, path: "/", sameSite: isSecure ? 'none' : false, secure: isSecure  })
				console.log(3);
				onLoginSuccess && onLoginSuccess(response.data)
			}
			else {
				onLoginFail && onLoginFail("Username or password is incorrect");

			}

		})
		.catch(error => {
			console.log(cookie);
			console.error(error); 
			cookie.remove('token');
		cookie.remove('csrftoken', { path: '/' });
		cookie.remove('role', { path: '/' });
		cookie.remove('user', { path: '/' });
			onLoginFail && onLoginFail(error.message );   





		});

}

export const ribsLogin = ( server, token, onLoginSuccess, onLoginFail ) => {
	const cookies = new Cookies(null, { path: '/' });

	console.log("Token from ribs", token)
	API.post(`ribs/${server}/login/`, JSON.stringify({access_token: token})).then(response => {
		const domain = window.location.origin.includes("localhost") ? "" : "karunalabs.com";
		console.log(response);
		if (response.data.key && response.data.key != "") {

			console.log("Saving cookie", response.data.key);

			const isSecure = window.location.protocol == "https:";

			!isSecure && console.log("IS NOT SECURE");


			cookies.set('token', response.data.key, { maxAge: 7200,  path: '/', sameSite: isSecure ? 'none' : false, secure: isSecure });

			onLoginSuccess && onLoginSuccess(response.data)
		}
		else {
			onLoginFail && onLoginFail("Username or password is incorrect");
		}
	}).catch(error => {
		console.error(error);
		onLoginFail && onLoginFail(error.message);
	});
}


export const getRole = (roleId) => {
	switch(roleId){
		case 1:
			return "Provider admin";
		case 2:
			return "Physician";
		case 3: 
			return "Patient";
		case 4:
			return "Coach";
		case 5:
			return "Facilitator"
		case 7:
			return "Employer admin"
		case 8:
			return "Insurer admin"
		case 9:
			return "TPA admin"
		case 10:
			return "Utilization reviewer admin"
		case 11:
			return "Nurse Case Manager Admin"

		default:
			return "None";
	}
}

export const logout = (onLogout) => {

	const domain = window.location.origin.includes("localhost") ? "" : "karunalabs.com";
	console.log("DOMAIN", domain);
	
	API.post('/logout/').then(response => {
		console.log(response)
		console.log("removing cookies", { path: '/' });
	cookie.remove('token', { path: '/' });
	cookie.remove('csrftoken', { path: '/' });
	cookie.remove('role', { path: '/' })
	cookie.remove('user', { path: '/' })
	localStorage.clear();
	console.log('Token: ' + cookie.load('token'))

	onLogout && onLogout();
		
		
	 }).catch(error => {
		 
		console.log("removing cookies");
		cookie.remove('token', { path: '/' });
		cookie.remove('csrftoken', { path: '/' });
		cookie.remove('role', { path: '/' });
		cookie.remove('user', { path: '/' });
	localStorage.clear();
	onLogout && onLogout();
	 });

	 

	
	
	
	
}





