import React from 'react'
import PropTypes from 'prop-types'

const ReviewSectionGenerator = ({hasPhysicianReview=false}) => {
  if(hasPhysicianReview)
    {
    return (
<div className=" h-6 justify-center items-center gap-3 inline-flex content-center	">
    <img className="w-6 h-6 relative bg-gray-50 rounded-full" src='/icons/check-circle-blue.svg' />
    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex text-wrap	">
        <div className="self-stretch text-wrap	 text-gray-600 text-base max-[1200px]:text-sm font-normal font-['Inter'] leading-normal">Physician Review section will be included.</div>
    </div>
</div>  )
  }
  else
  {
    return (
        <div className=" h-6 justify-center items-center gap-3 inline-flex content-center	">
            <img className="w-6 h-6 relative bg-gray-50 rounded-full" src='/icons/x-circle-red.svg' />
            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex text-wrap	">
                <div className="self-stretch text-wrap	 text-gray-600 text-base max-[1200px]:text-sm font-normal font-['Inter'] leading-normal">Physician Review section will not be included.</div>
            </div>
        </div>  
        )
  }
}


export default ReviewSectionGenerator