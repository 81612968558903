import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import API from '../services/API';
import PropTypes from 'prop-types';
import Input from '../components/input/Input';

import ProfileInput from '../components/input/ProfileInput';
import DropdownInput from '../components/input/DropdownInput';
import cookie from 'react-cookies';
import Breadcrumbs from '../components/Breadcrumbs';
import FormErrors from '../components/FormErrors';
import Spacer from '../components/Spacer';
import { TailSpin} from  'react-loader-spinner'

const EditPatient = ({ history }) => {

	const [patientId, setPatientId] = useState(useParams().id);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [patientName, setPatientName] = useState(null);
	const [profile, setProfile] = useState(null);
	const [loadedProfile, setLoadedProfile] = useState(null);
	const [email, setEmail] = useState(null);
	const [clinicianId, setClinicianId] = useState(undefined);
	const [clinicians, setClinicians] = useState(null);
	const [userIsClinician, setUserIsClinician] = useState(false);
	const [userIsProviderAdmin, setUserIsProviderAdmin] = useState(false);
	const [errors, setErrors] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (patientId) {
			getPatientData();
		}

		getClinicians();


	}, []);

	useEffect(() => {
		if (clinicians != null) {
			checkIfClinician();
			checkIfProviderAdmin();
		}

	}, [clinicians])

	useEffect(() => {
		console.log("loaded profile set")
		setProfile(loadedProfile);
	}, [loadedProfile])

	const checkIfClinician = () => {
		console.log(cookie.load('role'))
		if (cookie.load('role') === 'Physician') {

			//have to do this because the clinician's user id is not the
			//same as the clinician id
			let userId = cookie.load('user').pk;
			console.log(userId)
			let clinician = clinicians.find(c => c.id == userId);
			console.log("Clinician", clinician)
			if (clinician) {
				console.log("Setting clinician id to", clinician.role_id)
				setUserIsClinician(true);
				
				setClinicianId(clinician.role_id);
			}
		}
	}

	const checkIfProviderAdmin = () => {
		console.log(cookie.load('role'))
		if (cookie.load('role') === 'Provider admin') {

			setUserIsProviderAdmin(true);
			
		}
	}

	

	const handleSubmit = (event) => {


		event.preventDefault();

		let data = {
			first_name: firstName,
			last_name: lastName,
			profile: profile,
			clinician: clinicianId,
		};


		if (email) {
			data.email = email;
		}
		else
		{
			setErrors("Please provide Patient email")
			return;
		}

		console.log(data);


		if (patientId) {
			updateData(JSON.stringify(data));
		}
		
	}

	const getClinicians = () => {
		if (cookie.load('role') !== 'Coach'&&cookie.load('role') !== 'Facilitator'){

		API.get('/physicians/')
			.then(response => {
				setClinicians(response.data.results);
			})
		}
	}



	const getPatientData = () => {
		API.get(`/patients/${patientId}`)
			.then(response => {
				console.log("Patient data", response.data);
				setFirstName(response.data.first_name);
				setLastName(response.data.last_name);
				setEmail(response.data.email);
				if (cookie.load('role') !== 'Coach'){
				setClinicianId(response.data.physician_role_id);
				}
				setLoadedProfile({
					height: Number(response.data.profile.height),
					gender: Number(response.data.profile.gender),
					language: Number(response.data.profile.language),
					payer_type: Number(response.data.profile.payer_type)
				})
				setLoading(false);

			})
			.catch(error => console.error(error));
	}

	const updateData = (data) => {
		API.put(`/patients/${patientId}/`, data)
			.then((response) => {
				console.log(response);
				setLoading(false);


				goToPatient(patientId, true);
			})
			.catch(error => {
				// console.error(error.response)
				error && error.response && setErrors(error.response.data);
				// window.scrollTo(0);
			}
				);
	}


	const toPatient =()=>{
		goToPatient(patientId);
	}
	const goToPatient = (id, success=false) => {

		if(success)
		{
		//	localStorage.setItem("success","updated patient profile" );

		}
		history.push(`/patient/${id}/`);
	}

	const clinicianOptions = clinicians && clinicians.map(clinician => ({ id: clinician.role_id, label: `${clinician.first_name} ${clinician.last_name} ` }));

	const breadcrumbLinks = () => {


		if (patientId) {
			return [
				{
					label: "Patients",
					url: "/"
				},
				{
					label: firstName!=="" ? `${firstName} ${lastName}`:"Patient",
					url: `/patient/${patientId}`
				},
				{
					label: "Edit Patient",
					url: `/patient/${patientId}/edit/`
				}
			]
		}
		else {
			return [
				{
					label: "Patients",
					url: "/patients"
				},
				{
					label: "Create patient",
					url: "/create-patient"
				},
			]
		}


	}

	const wrapper = {
		alignItems: 'right',
		justifyContent: 'left',
		display: 'flex',
		verticalAlign: "middle",
		width: '50px',
		whiteSpace: "nowrap",
    
    
      };
      const wrapperS = {
        
       
        
    
      };

      let userAgent = navigator.userAgent;

	return (
		<Page errors={errors} headerObj={{trail:breadcrumbLinks(), body:{subheader:"Edit Patient",name:firstName+" "+lastName,buttons:[{title:"back",click:toPatient,img:"/icons/flip-backward.svg"}]}}} internal={true}>
{
	!loading?
		<form onSubmit={handleSubmit}>
		<Spacer headerObj={{trail:["login"], body:{}}} internal={true}/>

		<div className=" w-full  flex-col  items-start gap-5 m-0 pl-6 pt-6 inline-flex">

    <div className=" w-1/3  flex-col justify-start items-start gap-8 flex">
        <div className="self-stretch h-[540px] flex-col justify-start items-start gap-6 flex">
            <div className="self-stretch justify-start items-start gap-8 inline-flex">
                <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                    <div className="self-auto w-full h-[70px] flex-col justify-start items-start gap-1.5 flex">
                        <div className="text-slate-700 text-sm w-full font-medium font-['Inter'] leading-tight">First Name</div>
						<Input
						className='self-auto text-gray-500 text-base w-full  font-normal  px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex'
						type="text"
						name="First Name"
						value={firstName}
						onChange={setFirstName}
						required
						validations={[{ type: "minLength", value: 2 }, { type: "maxLength", value: 30 }]}
						formErrors={errors} 
					/>
                     
                    </div>
                </div>
                <div className="grow shrink w-full basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                    <div className="self-stretch h-[70px] w-full flex-col justify-start items-start gap-1.5 flex">
                        <div className="text-gray-700 text-sm  w-full font-medium font-['Inter'] leading-tight">Last Name</div>
					<Input 
						className='self-auto text-gray-500 text-base font-normal  w-full  px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex'
						type="text" 
						name="Last Name" 
						value={lastName} 
						onChange={setLastName} 
						required 
						validations={[{ type: "minLength", value: 2 }, { type: "maxLength", value: 30 }]} 
						formErrors={errors} 
					/>

                      
                    </div>
                </div>
            </div>
            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                    <div className="text-slate-700 text-sm font-medium font-['Inter'] leading-tight">Email</div>
					<Input 
						className="self-stretch  text-gray-500 text-base font-normal px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex"
						type="text" 
						name="Email" 
						value={email} 
						onChange={setEmail} 
						required
						validations={[{ type: "email" }]} 
						formErrors={errors} 
					/>
					
                </div>
            </div>
			<ProfileInput setProfile={setProfile} loadedProfile={loadedProfile} formErrors={errors} />
			
        </div>
        <div className="self-stretch h-12 flex-col justify-start items-start gap-4 flex">
            <button  className="self-stretch px-[18px] py-3 bg-brand-600 hover:bg-brand-700 rounded-lg shadow border border-brand-600 justify-center items-center gap-1.5 inline-flex">
                <div className="px-0.5 justify-center items-center flex">
                    <div className="text-white text-base font-semibold font-['Inter'] leading-normal">Save Patient Info</div>
                </div>
            </button>
        </div>
    </div>
		</div>
		</form>
		:
		<div className=" w-full h-full justify-center items-center inline-flex flex-col	">
	<TailSpin	
				height="100"
				width="100"
				color='grey'
				ariaLabel='loading'
			  />
			  <br /> <br />
			  <div/>
		  <h4 className="font-['Inter']">Loading...</h4>
	</div>
}
		
		</Page>

	);

}

EditPatient.propTypes = {
	patientHeight: PropTypes.number,
	inches: PropTypes.number,
	feet: PropTypes.number
};

export default withRouter(EditPatient);
