import axios from 'axios';
import cookie from 'react-cookies';
import { debug } from 'react-router-breadcrumbs';
import { useHistory } from "react-router-dom";


const headers = cookie.load('token') ? {
	'Accept': 'application/json; version=1.0',
	'Content-Type': 'application/json',
	'Authorization': 'Token ' + cookie.load('token')
} :
	{
		'Accept': 'application/json; version=1.0',
		'Content-Type': 'application/json'
	}
console.log(headers)

	axios.interceptors.response.use(response => {
		return response;
	 }, error => {
	   if (error.response.status === 401) {
		const domain = window.location.origin.includes("localhost") ? "" : "karunalabs.com";
		console.log(401)
		cookie.remove('token', { path: '/' });
		cookie.remove('csrftoken', { path: '/' });
		cookie.remove('role', { path: '/' });
		cookie.remove('user', { path: '/' });
		localStorage.clear();
		let history = useHistory(); 
		if ( !document.URL.includes("login") ) {
			history.push('/login');
		}	   }
	   return error;
	 });

export default axios.create({
	baseURL: process.env.REACT_APP_BACKEND_API_URL,
	responseType: 'json',
	headers: headers,
	withCredentials: false
})
