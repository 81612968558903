import React from 'react';
import Page from '../components/Page';
import { useParams, withRouter } from 'react-router';
import { useEffect } from 'react';
import { ribsLogin } from '../services/AccountServices';
import cookie from 'react-cookies';

const RIBSVisitSummaryRedirect = ({ history }) => {

  const visitId = useParams().visitId;
  const ribsToken = useParams().ribsToken;
  const ribsServer = useParams().ribsServer;
  const domain = window.location.origin.includes("localhost") ? "" : "karunalabs.com";

  useEffect(() => {
    console.log("visit id", visitId)
    if (ribsToken && ribsServer){
      ribsLogin(ribsServer, ribsToken, onLoginSuccess, onLoginFail);
    }
    
  }, [])

  const onLoginSuccess = ( data ) => {
    console.log(data);
    cookie.save('role', 'Physician', { maxAge: 7200, domain: domain, path: '/', sameSite: 'none', secure: true  })
    if(visitId){
      history.push(`/visit/${visitId}/ribs`);
    }
    else{
      history.push('/');
    }   
  }

  const onLoginFail = ( message ) => {
    history.push('/');
  }

  return(
    <Page >

    </Page>
  )

}

export default withRouter(RIBSVisitSummaryRedirect);


