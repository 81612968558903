import React, { useState, useEffect } from 'react';
import ErrorNotification from './Notifications/ErrorNotification';
import SuccessNotification from './Notifications/SuccessNotification';
import classNames from 'classnames';


const Notification = ({errors, successes, internal, headerObj, clear=false}) => {

  const [NoteList, setNoteList] = useState([]);
  const [indexToRemove, setIndexToRemove] = useState(null);
  const [trueErrors, setTrueErrors] = useState(null);
  const [trueSuccess, setTrueSuccess] = useState(null);

/*useEffect(()=>{
  console.log(NoteList)
  if(NoteList.length===0)
  {
    setTrueErrors(null)

  }
},[NoteList])*/
 


  useEffect(() => {
   // console.log(errors)

    if(trueErrors===null)
    {
      setTrueErrors(errors)
      
    }
    if(errors===null)
    {
      setTrueErrors(null);
    }
    if(trueSuccess===null)
    {
      setTrueSuccess(successes)
    }
    if(successes===null)
    {
      setTrueSuccess(null);
    }
    if(clear===true)
    {
      setTrueErrors(null);
      setTrueSuccess(null)
    }
    var count=0;
    var elist=[];
 // console.log(errors!==null &&errors!==undefined)
    if(errors!==null &&errors!==undefined)
    {
    // console.log(trueErrors!==errors)
      if(trueErrors!==errors)
      {
        if(errors!==null &&errors!==undefined){
        (typeof errors === 'string' || errors instanceof String)? 
                  elist= errors.split('\n').map((i,index) => {
                    if(i.length>0){
                      return <ErrorNotification  id={index} key={index} whenClick={()=>{setIndexToRemove(index);}} message={i}/>
                    }
                    })
                  :
                  elist =errors && errors.errors.map((error, index) =>  <ErrorNotification id={count++} whenClick={()=>{setIndexToRemove(index)}} message={error.message}/>
      )
                }
      }
      
    }
    var slist=[]
    count= elist.length;
    if(successes!==null &&successes!==undefined && (typeof successes === 'string' || successes instanceof String))
    {
      if(trueSuccess!==successes){
        slist= successes.split('\n').map((i,index) => {
            return <SuccessNotification  id={index+count} key={index+count} whenClick={()=>{setIndexToRemove(index+count)}} message={i}/>
        })
      }
      

    }
    var notif=[];
    notif = notif.concat(elist);
    notif = notif.concat(slist);
    notif = notif.filter(function( element ) {
      return element !== undefined;
   });
    if(notif.length>0) {

    setNoteList(notif);
    }
    if(notif.length===0&&(errors===null||errors===undefined)&&(successes===null||successes===undefined))
    {
      setNoteList([]);
    }

}, [errors,successes,headerObj])


  useEffect(() => {
    if(indexToRemove!==null){
    setIndexToRemove(null);
    setNoteList( RemoveNoteElementAtIndex(indexToRemove))
    }
    
}, [indexToRemove])










function RemoveNoteElementAtIndex(index) {

  var arr= NoteList;
  var trueIndex= arr.findIndex(x =>  parseInt(x.key) === index)
  index=trueIndex;
 
  if (index < 0 || index >= arr.length) {
   // console.log(arr)

    if(arr.length===0){
    //  setTrueErrors(null);
    //  errors=null;
    }
    return arr; 
  }

  arr.splice(index, 1);
 // console.log(arr)
  if(arr.length===0){
   // setTrueErrors(null);
    //errors=null;

  }

  return arr;
}

  return (

    <React.Fragment>
          {NoteList.length>0?
          (
            <div className={internal?` z-10 right-0 left-[calc(100%-calc(100%-196px))] pointer-events-auto	`:'z-10 pointer-events-auto	f right-0 left-0  '}id="notification Container">
                {
                NoteList
                }

            </div>
          ):
          <span className='absolute' id="notification Container empty"></span>
        }
    </React.Fragment>
  )
}

export default Notification