import React from 'react';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useEffect } from 'react'; 
import { words } from 'lodash';
 
const PersonalBestCard = ({ Name, Data , hide=false}) => {
  
 
 const DisplayScores= ()=>{
    if(Data!==null&&Data["score"]!==undefined){

    return <p>Score: {Data["score"]} { Data["first"]!==undefined &&Data["first"]===true?"(First Attempt)":""}</p>    
    }
 }
 
 const DisplayGoals= ()=>{
    var goals=[]
    Data !==null && Object.entries(Data.goals).forEach(element=>{
        var name =element[0]
        var line = name+": "+element[1]["completions"]+(element[1].first?" (First Attempt) ":"")
        goals.push(line);
    
    })
    return goals;
 }
 
 
 return (
   <div className="PersonalBest">
     <h3>{ Name}</h3>
  {
      !hide?  (  <React.Fragment>

     <div >
       <div className="small-text">
        {DisplayScores()}
        {
            DisplayGoals().map(element=>{
                return <div>{element}</div>
            })
        }
        </div>

     </div>
     </React.Fragment>):""
  }



     
   </div>
 )
}
 
export default PersonalBestCard;

