import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Spacer from '../components/Spacer';
import Page from '../components/Page';
import Input from '../components/input/Input';
import API from '../services/API';
import { useHistory } from "react-router-dom";
import { TailSpin	 } from  'react-loader-spinner'
import PatientProfile from './PatientProfile';
import cookie from 'react-cookies';
import moment from 'moment';


const PatientReviewReportPage = ({history}) => {
	const patientId = useParams().id;
    const role = cookie.load('role') ? cookie.load('role') : 'None';
    const [patientData, setPatientData] = useState(null);
    const [vetkitVersion, setVetkitVersion]= useState(null)
    const [physician, setPhysician] =useState(null);
    const [tpa, setTpa] =useState(null);
    const [insurer, setInsurer] =useState(null);
    const [provider, setProvider] =useState(null);
    const [headerInfo, setHeaderInfo] =useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(true);
	
	const [outcomeReports, setOutcomeReports] = useState(null);
    const [download, setDownload] = useState(false);

    const[width,setWidth]= useState(null);
  const tableRef = useRef(null);
  const pageRef = useRef(null);
  const[pagewidth,setPageWidth]= useState(null);


  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const breadcrumbLinks = () => {
    return [
        {
            label: "Patients",
            url: "/"
        },
        {
            label: (patientData!==null?patientData.first_name+" "+patientData.last_name:"Patient"),
            url: `/patient/${patientId}`
        }
        
    ]
}



const handleResize = () => {
    setDimensions({
    width: window.innerWidth,
    height: window.innerHeight,
    });
  }
useEffect(() => {
  window.addEventListener("resize", handleResize, false);
}, []);

useEffect(() => {
    if(pageRef.current!==null)
    {
       
        setPageWidth(pageRef.current.offsetWidth)
        

    }
    if(tableRef.current!==null)
    {
     
       
        setWidth(tableRef.current.offsetWidth)
        

    }
}, [dimensions]);

const divStyle = {
  width: (width<window.innerWidth? "fitContent":"90%")

}
useEffect(() => {
}, [divStyle]);

useEffect(()=>{
},[width])

useEffect(() => {

    if(pageRef.current!==null)
    {
       
        setPageWidth(pageRef.current.offsetWidth)
        

    }
}, [pageRef.current,loading]);

useEffect(() => {

    if(tableRef.current!==null)
    {
     
        setWidth(tableRef.current.offsetWidth)
        

    }
}, [tableRef.current,loading]);

useEffect(()=>{
    //setDivStyle({	width: width!==null?(width<window.screen.width? "fitContent":"90%"):("90%")})
},[width,pagewidth])

	useEffect(() => {

		API.get(`/outcome_reports/?patient_id=${patientId}`)
			.then(response => {
                 var reports = response.data.results
                reports= reports.sort((a, b) =>(Number.parseInt(a.included_weeks) !== Number.parseInt(b.included_weeks)?( Number.parseInt(a.included_weeks) < Number.parseInt(b.included_weeks) ? 1 : -1):(moment(a.prepared_date).isBefore( moment(b.prepared_date))?1:-1)))
console.log(reports)
				setOutcomeReports(reports);
                setLoading(false)
			})
			.catch(
				error => {
					console.error(error)
                    setErrors(error)
					setOutcomeReports([]);
                    setLoading(false)
				}
				);
		
	}, []);

    useEffect(() => {
		if (patientId==null) {
			history.push('/');
		}

		if (patientData===null) {
			getPatientData();
			
		}
	});

    const toCoach=()=>{
        history.push(`/patient/${patientId}/coaching-plan`);

    }

    const toActivity=()=>{
        history.push(`/patient/${patientId}/activity`);

    }

    const toReports=()=>{
        history.push(`/patient/${patientId}/reports`);

    }

    useEffect(() => {
        let tabs=[]
        if(role==="Coach"||role==="Facilitator")
        {
            tabs.push({text:"Coaching Plan",click:toCoach,current:(window.location.href.includes("coaching-plan")?true:false)})
        }
        tabs.push({text:"Activity",click:toActivity,current:(window.location.href.includes("activity")?true:false)})
        if(role==="Coach"||role==="Facilitator")
        {
            tabs.push({text:"Reports",click:toReports,current:(window.location.href.includes("reports")?true:false)})
        }
		setHeaderInfo(
            {trail:breadcrumbLinks(),body:{subheader:(patientData!==null?"#"+patientId+" | "+getGender(patientData.profile.gender)+" | "+getPatientHeight():""),name:(patientData!==null?patientData.first_name+" "+patientData.last_name:""),vetkit:vetkitVersion,info:({tpa:tpa,provider:provider,insurer:insurer,physician:physician}),tabs: tabs,sideButton:{img:"/icons/edit.svg", text:"Edit Patient", click:EditPatient}}}
        );
	},[patientData]);

   /* const Redirect=()=>{
        if(redirect)
        {
            if(role==="Coach")
            {
                history.push(`/patient/${patientId }/coaching-plan`);
            }
            else
            {
                history.push(`/patient/${patientId }/activity`);

            }
        }
    }*/



    const getPatientData = () => {

        var pdata = localStorage.getItem("patient");
        pdata =JSON.parse(pdata);
        

        if(pdata!==null&&pdata.id.toString()===patientId)
        {
            var data= pdata;
            console.log("Patient data found", data);
            setPatientData(data);
            if(data.client!==undefined&&data.client.version!==undefined)
            {
                var val= data.client.version.split("+unitycloudbuild")[0];
                if(val.includes("prod")){val=val.split("-")[0]}
                setVetkitVersion(val);
            }
            data.physician_name!==undefined?setPhysician(data.physician_name ):setPhysician("n/a");   
            data.provider_name!==undefined?setProvider(data.provider_name ):setProvider("n/a");   
            if(data.profile!==undefined)
            {
                var profile= data.profile
                profile.third_party_administrators!==undefined?setTpa(profile.third_party_administrators):setTpa("n/a");   
                profile.insurers!==undefined?setInsurer(profile.insurers):setInsurer("n/a");   

            }
            else{
                setTpa("n/a");
                setInsurer("n/a");
            }

            localStorage.setItem("patient",JSON.stringify(data));
            /*if(redirect){
                Redirect()
            }*/
            //else
            //{
           // setLoading(false);
            //}
        }
        else
        {
		API.get(`/patients/${patientId}`)
			.then(response => {
                var data= response.data;
				console.log("Patient data", data);
                localStorage.setItem("patient",JSON.stringify(data));

				setPatientData(data);
                if(data.vetkit!==undefined)
                {
                    setVetkitVersion(data.vetkit);
                }
                data.physician_name!==undefined?setPhysician(data.physician_name ):setPhysician("n/a");   
                data.provider_name!==undefined?setProvider(data.provider_name ):setProvider("n/a");   
                if(data.profile!==undefined)
                {
                    var profile= data.profile
                    profile.third_party_administrators!==undefined?setTpa(profile.third_party_administrators):setTpa("n/a");   
                    profile.insurers!==undefined?setInsurer(profile.insurers):setInsurer("n/a");   

                }
                else{
                    setTpa("n/a");
                    setInsurer("n/a");
                }
                 
               // if(redirect){
               //     Redirect()
               // }
                //else
                //{
              //  setLoading(false);
                //}
			})
			.catch(error => console.error(error));
        }
	}

    const getPatientHeight = () => {
		let feet = patientData.profile.height * 3.2808;
		var inches = Math.round((feet - Math.floor(feet)) * 12)
		if(inches>=12)
		{
			inches-=12
			feet+=1

		}
		return `${Math.floor(feet)}' ${inches}"`;
	}

    const getGender = (gender) => {
		switch (gender) {
			case 0:
				return "Female";
				break;
			case 1:
				return "Male";
				break;
			default:
				return "Nonbinary";
		}
	}

    const EditPatient=()=>{
        if(patientId!==null&&patientId!==undefined){
        history.push(`/patient/${patientId }/edit`);
        }
    }

    const RefreshOutcomes=()=>{

        console.log("refreshing")
        window.location.reload();
       /* setLoading(true)
        API.get(`/outcome_reports/?patient_id=${patientId}`)
                .then(response => {
                    var reports = response.data.results
                reports= reports.sort((a, b) =>(Number.parseInt(a.included_weeks) !== Number.parseInt(b.included_weeks)?( Number.parseInt(a.included_weeks) < Number.parseInt(b.included_weeks) ? 1 : -1):(moment(a.prepared_date).isBefore( moment(b.prepared_date))?1:-1)))

				setOutcomeReports(reports);
                    setLoading(false);
                    setDownload(false);

                })
                .catch(
                    error => {
                        console.error(error)
                        setErrors(error)
                        setOutcomeReports([]);
                        setLoading(false)

    
                    }
                    );*/
      }

   

	const GetAllPDF=()=>{
        console.log(patientId)
	if(patientId!==null&&patientId!==undefined){
        var url =`/reports/${patientId}`
        localStorage.clear();
        window.open(url, '_blank');
    }
	}

    const DisapproveReport = async (id, week, post=false)=>{
        setDownload(true);

        await API.get(`/outcome_reports/${id}/disapprove` ).then((response) => {
			console.log(response);

			if(response.code ==="ERR_BAD_RESPONSE"){
				throw new Error(response.message);

			}
            localStorage.setItem("success",!post?"Disapproved outcome report for week "+week:"Disapproved 6 Months Post Outcome Report");

			RefreshOutcomes();


    
        
        }).catch(error => {
			
        console.error(error);
        setDownload(false);
		setErrors(error.message);

        
        })

    }

    const DisapproveWarning= (id,week,post=false)=>{

        let text = "Confirm Disapprove Report\n\nDisapproving this report will disallow external organizations and non-reviewers from downloading it. To edit and re-approve the report, see the Outcome Report Review table in your Home page.\n\nPress OK to disapprove."
        if (window.confirm(text) === true)
        {
            DisapproveReport(id,week,post);     
        } 
    
       }

    const DeleteWarning= (id,week,post=false)=>{

        let text = "Confirm Delete Report\n\nAre you sure you want to delete this outcome report? There is no way to recover it once deleted!\n\nPress OK to delete or Cancel to return to editing.";
        if (window.confirm(text) === true)
        {
            DeleteReport(id,week,post);     
        } 
    
       }

       

    const DeleteReport = async (id, week,post=false)=>{
        setDownload(true);

        await API.get(`/outcome_reports/${id}/delete` ).then((response) => {

        console.log(response)
		

		if(response.code ==="ERR_BAD_RESPONSE"){
			throw new Error(response.message);

		}
        localStorage.setItem("success",!post?"Deleted outcome report for week "+week:"Deleted Outcome 6 Month Post Outcome Report");

        RefreshOutcomes();

   
        }).catch(error => {
			
        setDownload(false);
		setErrors(error.message);


        
        })
        
    }


    const GetPDF=async(id,week,date)=>{
		
		if(id!==undefined&&week!==undefined&&date!==undefined){

			setDownload(true);

			await API.get(`/outcome_reports/${id}/?pdf_data=${true}/` ).then((response) => {

				console.log(response);
				if(response.data.report_pdf_data!==undefined)
				{
					var url =`/report/${id}`
					localStorage.setItem("pdf",JSON.stringify(response.data) );
					window.open(url, '_blank');
				}
				setDownload(false);

		
			
			}).catch(error => {
			console.error(error)
			setDownload(false);

			
			})

        }

	}

    const toOutcomePage=()=>{
        if(patientId!==null&&patientId!==undefined){
        history.push(`/patient/${patientId}/outcomes`);
        }

    }

    const RenderStatus=(report)=>{
            if(report.approved)
            {
                return(
                    <div className=" pl-1.5 pr-2 py-0.5 bg-success-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">
<div className="w-2 h-2 relative">
<div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-success-500 rounded-full" />
</div>
<div className="text-center text-success-700 text-xs font-medium font-['Inter'] leading-[18px]">Approved</div>
</div>
                )
            }
            else
            {

                if(report.valid_approvers===undefined||report.valid_approvers===null||report.valid_approvers.length===0 )
                {
                    return(
                        <div className=" mix-blend-multiply justify-start items-start inline-flex">
        <div className="pl-1.5 pr-2 py-0.5 bg-error-50 rounded-2xl justify-center items-center gap-1.5 flex">
            <div className="w-2 h-2 relative">
                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-error-500 rounded-full" />
            </div>
            <div className="text-center text-error-700 text-xs font-medium font-['Inter'] leading-[18px]">Missing Approver</div>
        </div>
    </div>
                    )
                }
                else{
                return(
                    <div className=" mix-blend-multiply justify-start items-start inline-flex">
    <div className="pl-1.5 pr-2 py-0.5 bg-warning-50 rounded-2xl justify-center items-center gap-1.5 flex">
        <div className="w-2 h-2 relative">
            <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-warning-500 rounded-full" />
        </div>
        <div className="text-center text-warning-700 text-xs font-medium font-['Inter'] leading-[18px]">In Review</div>
    </div>
</div>
                )
                }
            }
    }

    const RenderTable = () => {
        var t= []
        for(var i=0;i<outcomeReports.length;i++){
            let id =(outcomeReports[i].id)
            let week =outcomeReports[i].included_weeks;
            let prepared= outcomeReports[i].prepared_date;
            let permissions=outcomeReports[i].user_can_delete;
            let postProgramReport = outcomeReports[i].is_post_program_checkin!==undefined && outcomeReports[i].is_post_program_checkin===true
             t.push(<React.Fragment><tr className='whitespace-nowrap border-y-2 even:bg-white odd:bg-white	' >
        <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{!postProgramReport? "Week "+outcomeReports[i].included_weeks:"6 Months Post"}</td>
        <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{RenderStatus(outcomeReports[i])}</td>
        <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{moment(outcomeReports[i].prepared_date).format("MM/DD/YYYY")}</td>
        <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>
        <td className="whitespace-nowrap border-x-0		 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "></td>
        

        <td className="whitespace-nowrap border-x-0		content-center	text-center px-6 py-3  text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">
        {
            permissions===true&& <span><button className=" mr-1 px-3.5 py-2 bg-error-600 hover:bg-error-700 rounded-lg shadow border border-orange-800 justify-center items-center gap-2 inline-flex" onClick={()=>{DeleteWarning(id,week,postProgramReport)}}>
            <img className="relative" src="/icons/trash-01.svg" />
            <div className="text-white text-sm font-semibold font-['Inter'] leading-tight">Delete</div>
            </button>
            {outcomeReports[i].approved&&<button className="mr-1 px-3.5 py-2 bg-warning-600 hover:bg-warning-700 rounded-lg shadow border border-warning-600 justify-center items-center gap-2 inline-flex" onClick={()=>{DisapproveWarning(id,week,postProgramReport)}}>
        <img className="relative" src="/icons/file-x-02.svg" />
        <div className="text-white text-sm font-semibold font-['Inter'] leading-tight">Disapprove</div>
        </button>}
        
        
        </span>
       
        }
        {
        outcomeReports[i].approved&&<button className=" px-3.5 py-2 bg-brand-600 hover:bg-brand-700 rounded-lg shadow border border-brand-600 justify-center items-center gap-2 inline-flex" onClick={()=>{GetPDF(id,week,prepared)}}>
        <img className="relative" src="/icons/file-download-02.svg" />
        <div className="text-white text-sm font-semibold font-['Inter'] leading-tight">Download</div>
        </button>}
    

        </td>
      </tr></React.Fragment>)
      }
      return t;
    
    }

  return (
<Page errors={errors} headerObj={headerInfo!==null?headerInfo:{trail:breadcrumbLinks()}}  internal={true}>
            <Spacer headerObj={headerInfo!==null?headerInfo:{trail:breadcrumbLinks()}} internal={true}/>  
            <div className="w-full h-full justify-center items-center  inline-flex  " ref={pageRef}>

    {loading===false&&download===false&&patientData!==null?(
            <React.Fragment >
   
   

    <div class={" bg-white h-[90%]   rounded-lg shadow-lg border-2 border-gray-200 flex-col  items-end flex "+(outcomeReports.length>0?((width<=pagewidth)?"w-[90%]":"w-[90%]"):"w-[90%]")} >
        <div className="w-full h-[10%] px-6 pt-5 pb-[19px] justify-start items-center gap-x-1 inline-flex">
            <div className="w-[75%] h-full justify-start items-center   gap-x-4 inline-flex  ">
                <div className="text-gray-900 text-lg font-medium font-['Inter'] leading-7 whitespace-nowrap">Outcome Reports &ensp;</div>
                { outcomeReports!==null&&outcomeReports.length>0&& <div className="mix-blend-multiply justify-start items-start flex">
            <div className="px-2 py-0.5 bg-brand-50 rounded-2xl justify-center items-center flex">
              <div className="text-center text-brand-800 text-xs font-medium font-['Inter'] leading-[18px]">{outcomeReports.length} Submitted</div>
            </div>
          </div> } 
            </div>
            <div className='gap-x-4 inline-flex items-center 	 '>
            <div className="grow shrink w-full basis-0 relative   items-center  flex justify-end">
                <button className="px-4 h-1/2 w-full  py-2.5 max-[1200px]:px-4 max-[1200px]:py-2 bg-white rounded-lg  shadow border border-gray-300 hover:bg-gray-100 justify-center items-center gap-1.5 inline-flex" onClick={toOutcomePage}>
                    <img className=" grow shrink h-5 relative" src="/icons/file-plus-03.svg" />
                    <div className="px-0.5 justify-center items-center flex grow shrink">
                        <div className="text-gray-700 	whitespace-nowrap	text-base max-[1200px]:text-xs font-semibold font-['Inter']">Create New Report</div>
                    </div>

                </button>
            </div>

            <div className="grow shrink w-full basis-0 h-full  items-center  flex justify-end">

                <button className="px-4 py-2.5  max-[1200px]:px-4 max-[1200px]:py-2 bg-white rounded-lg shadow border border-gray-300 hover:bg-gray-100 justify-center items-center gap-1.5 flex" onClick={GetAllPDF}>
                    <img className="grow shrink h-5  relative" src="/icons/folder-download.svg" />
                    <div className="px-0.5 justify-center items-center flex grow shrink">
                        <div className="text-gray-700  	whitespace-nowrap	 text-base max-[1200px]:text-xs font-semibold font-['Inter'] leading-normal">Download All</div>
                    </div>
                </button>
            </div>
            </div>
            
        </div>
            {
            outcomeReports&&outcomeReports.length>0?
            <div class="grid grid-col w-full whitespace-nowrap overflow-y-auto 	">
                <div class=" whitespace-nowrap	w-full">
                    <div class="whitespace-nowrap w-full	inline-block align-middle ">
                    <div class=" whitespace-nowrap w-full	">
                        
                        <table class=" w-full" ref={tableRef}>
                        <thead className='whitespace-nowrap	w-full'>
                        <tr className='shadow-[inset_0_1px_0_#d3d3d3,inset_0_-1px_0_#d3d3d3] bg-gray-25 whitespace-nowrap	sticky top-0 z-[2]'>
                            <th  scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">Report Week</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Status</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Created</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	text-opacity-0	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Created</th>

                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0 text-opacity-0		  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Created</th>

                            <th scope="col" class="sticky top-0 z-[2] text-center	 whitespace-nowrap	border-x-0	  px-6 py-3  text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Actions</th>
                            </tr>
                        </thead>
                        <tbody className='whitespace-nowrap	'>
                            <React.Fragment>
                        {
                            outcomeReports&& RenderTable()
                        }
                        </React.Fragment>
                        </tbody>
                    
                        </table>
                        
                    </div>
                    </div>
                </div>
            </div>:
            	<div className=" w-full h-full justify-center items-center inline-flex flex-col	">
            <p className="px-6 text-gray-900 text-lg font-medium font-['Inter']">No Reports Found</p>
</div>
            }
        </div>
    </React.Fragment>


        ):
        (
            <div className=" w-full h-full justify-center items-center inline-flex flex-col	">
            <TailSpin	
                        height="100"
                        width="100"
                        color='grey'
                        ariaLabel='loading'
                      />
                      <br /> <br />
                      <div/>
                  <h4 className="font-['Inter']">Loading...</h4>
            </div>
        )
    }
        </div>

    </Page>
  )
}

export default withRouter(PatientReviewReportPage)