import React, { useState, useEffect } from 'react';
import API from '../../services/API';
import { withRouter, useParams } from 'react-router';
import { TailSpin	 } from  'react-loader-spinner'
import moment from 'moment-timezone';
import { element } from 'prop-types';

const RatingsList= ({   dayName, progress, protocol}) => { 

	const [protocols, setProtocols] = useState(protocol);

	useEffect(() => {
		//getProtocols();
		
	  }, [])

      const camelCaseParser=(text)=>{
		return text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
	}

	const GetRatingLabel=(score)=>{
		var label=""
		if (4000<=score)
		{label="Excellent"}
		else  if( 2500<=score)
		{label="Great"}
		else if( 1000<=score)
        {
            label="Good"
		}
        else if(0<=score){
            label="Keep Going!"
        }

    return label;
	  }

	const getProtocols= () => {
		API.get(`/protocols`).then(response => {
			var protocol ={}
			response.data.results.forEach(element => protocol[element.id]=element.display_name);
			setProtocols(protocol)

		}).catch(error => console.log(error));
	  }
       

	
	
	if(progress && dayName)
	{
		progress=progress.filter(prog => "end_time" in prog && moment (prog.end_time).format("YYYY-MM-DD") ===moment(dayName).format("YYYY-MM-DD") && "score" in prog.rating);
		progress = progress.sort((a, b) => moment(a.end_time).diff(moment(b.end_time)))
	}

    const GetActList=(scores)=>{
        var response=[];
        scores.forEach(element=>{
            response.push(Object.keys(element)[0].replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1"))
        })

        return <React.Fragment>
            {
                
scores.map((element,index)=>{
    if(!response[index].includes("Breathing")){
       return <div>{response[index]}</div>
    }
    })
}
            
            
        </React.Fragment>

		
        

    }


   
const DisplayList = protocols && progress &&progress.length>0 && progress.map((prot, index) => (
    <div key={index} className="row">
    <h4 className="chart-label">{index+1} </h4>
    <h4 className="chart-label">{"score" in prot.rating? prot.rating.score:"no score found"}</h4>
    <h4 className="chart-label">{"score" in prot.rating? GetRatingLabel( prot.rating.score):"no label found"}</h4>

	<h4 className="chart-label"> {"scores" in prot? GetActList(prot.scores):"no activities found"}</h4>
    <h4 className="chart-label">{prot.plan_week!==undefined?prot.plan_week:"None"} </h4>

</div>
))
		
	return (
	  
		<React.Fragment>
            
		{progress.length>0?<h3>Karuna Ratings recorded on this day</h3>:<span></span>}
        {DisplayList?<div className="row">

<p className="chart-label"><b>Personal Routine Completion</b></p>
<p className="chart-label"><b>Rating Score</b></p>
<p className="chart-label"><b>Rating Label</b></p>
<p className="chart-label"><b>Activities</b></p>
<p className="chart-label"><b>Week</b></p>



</div>:<span></span>}
		{ DisplayList ?DisplayList:<span></span>}
	  <br/>
		</React.Fragment>

      
	);
}


export default withRouter(RatingsList);
