import React, { useState, useEffect } from 'react';
import API from '../../services/API';
import {  Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import { getDuration } from '../../utility/Visit';


const CategoryPieChart = ({ type, categoryName, limit, label, filter }) => { 

	const [rawData, setRawData] = useState([]);
	const [dataset, setDataset] = useState(null);

	useEffect(() => {
		getData();


	}, []);

	useEffect(() => {
		createDataset();
	}, [rawData])

	const filterQuery = filter ? `&${filter.id}=${filter.value}` : '';
	

	const getData = () => {
		API.get(`/${type}/?limit=${limit}${filterQuery}`)
		.then(response => {
			console.log(response);
			setRawData(response.data.results);
		})
		.catch(error => console.error(error));
	}

	const timecodeToMinutes = (timecode) => {
		console.log(timecode)
		return timecode * 0.00001666667;
	}

	const createDataset = () => {
		let datasetHolder = [];
		let categories = [...new Set(rawData.map(data => data[categoryName]))];
		// console.log("Categories: ", categories);

		for(var i = 0; i < categories.length; i++){
			let activities = rawData.filter(data => data[categoryName] === categories[i]);
			// console.log("activities", activities);
			let durations = [...new Set(activities.map(activity => timecodeToMinutes(getDuration(activity))))];
			// console.log("durations", durations);
			let amount = durations.reduce((a, b) => a + b, 0);

			if (amount > 1){
				datasetHolder.push({ category: categories[i], amount: amount });
			}
			
		}

		if(datasetHolder.length > 0 ){
			setDataset(datasetHolder);
		}

	
	}



	const colors = ['#5B8BC8', '#F36C33', '#FCB242', '#005BA3', '#414042'];

	const radian = Math.PI / 180; 


	const formatLabel = (label, x, y) => {
		let words = label.split(' ');

		// console.log(words);
		// // if(words.length > 1){
		// 	return words.map((word, i) => (
		// 		<tspan x={x} y={y - 10 + i * 10} dy="10px">{word}</tspan>
		// 	))
		// // }
		
		//swapping out label for connect the dots because someone
		//capitalized the 'the' unfortunately
		return label.replace("The", "the");
	}

	const renderCustomizedLabel = ({ 
		cx, cy, midAngle, innerRadius, outerRadius, percent, index,
	}) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
		const x = cx + radius * Math.cos(-midAngle * radian);
		const y = cy + radius * Math.sin(-midAngle * radian);

		const pRadius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const px = cx + pRadius * Math.cos(-midAngle * radian);
		const py = cy + pRadius * Math.sin(-midAngle * radian);

		return (

			
			<React.Fragment>
				
				<text x={x} y={y} style={{ fontSize: '10px', overflow: 'wrap', maxWidth: '30px' }} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
					{dataset[index].category}
				</text>
				{percent > 0.02 &&
					<text x={px} y={py}  style={{ fontSize: percent > 0.06 ? '14px' : '10px', pointerEvents: 'none' }} fill="white" textAnchor='middle' dominantBaseline='central'>
						{`${Math.round(percent * 100)}%`}
					</text>
				
				}
				
			</React.Fragment>
			
		);
	};


	return (
		<div className="graph">	
			{dataset && 
			<React.Fragment>
				<h3>{label}</h3>
				<PieChart width={450} height={300} className="chart" data={dataset} margin={{ top: 0, right: 100, left: 100, bottom: 0 }}  >	
				<Pie data={dataset} 
					dataKey="amount" 
					nameKey="category" 
					cx="50%" cy="50%" 
					innerRadius={40} 
					outerRadius={90} 
					paddingAngle={0}

						label={renderCustomizedLabel}
					>
					{
						dataset.map((entry, index) => <Cell key={index} fill={colors[index % colors.length]}  />)
					}
					</Pie>
					<Tooltip item  formatter={(value, name) => { return [(value > 60 ? `${Math.round(value/60)} hours,` : '') + ` ${Math.round(value%60)} minutes`, "Time spent"] }} />
					{/* <Legend /> */}
				</PieChart>
			</React.Fragment>
			}
		
		</div>
		
	);
}


export default CategoryPieChart;
