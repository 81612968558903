import React from 'react'

const SectionDivider = ({label}) => {
    return (
        <div className={"inline-flex relative mb-4 mt-2 items-center justify-center w-full py-2"}>
        <hr className="w-full h-0.5 bg-gray-200 border-0 rounded "/>
        <div className="absolute px-2 -translate-x-1/2 bg-white left-1/2 text-center text-gray-900 text-lg font-semibold font-['Inter'] leading-tight">
           {label}
        </div>
    </div>
      )
}

export default SectionDivider