import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import API from '../services/API';
import Breadcrumbs from '../components/Breadcrumbs';
import { arrivedAtProvider, sendToKaruna } from '../services/VetkitServices';
import { canReturnToKaruna, isPending } from '../utility/VetkitUtility';



const VetkitProfile = ({history}) => { 
	const vetkitId = useParams().id;
	const [vetkitData, setVetkitData] = useState(null);
	const [assignment, setAssignment] = useState(null);


	useEffect(() => {

		if(!vetkitId){
			history.push('/vetkits/');
		}

		if (!vetkitData) {
			getData();
			getAssignment();
		}
		
	});


	const getData = () => {
		API.get(`/vetkits/${vetkitId}/`)
			.then(response => {
				console.log(response);
				setVetkitData(response.data);
			})
			.catch(error => console.error(error));
	}

	const getAssignment = () => {
		API.get(`/vetkitassignments`)
			.then(response => {
							console.log("assignments", response);
			})
			.catch(error => console.error(error));
	}

	const goToAssignVetkit = () => {
		history.push(`/vetkit/${vetkitId}/assign`);
	}

	const assignVetkitButton = vetkitData && vetkitData.is_assignable && <button onClick={goToAssignVetkit}>Assign VET kit</button>


	

    return (
		<Page title={vetkitData && vetkitData.name} showMenu>
				<Breadcrumbs links={[
					{
						label: "Provisioning",
						url: "/provisioning"
					},
					{
						label: vetkitData && vetkitData.name,
						url: window.location.url
					}
				]} />
			{vetkitData && 
			<div>
					{(isPending(vetkitData))
					&&
						<button onClick={() => arrivedAtProvider(vetkitId)}>Mark as arrived</button>}

					{canReturnToKaruna(vetkitData) 
					
						&&
						<button onClick={() => sendToKaruna(vetkitId)}>Return to Karuna</button>}
				
				<h3>Location</h3>
				<p>{vetkitData.location}</p>
				
					{assignVetkitButton}
					<br /><br /><br />
				<h5>Headset</h5>
					<p className="small"> {vetkitData.headset}</p>
					<br />
				<h5>Mifi</h5>
				<p className="small">{vetkitData.mifi}</p>
				
			
			</div>}
		</Page>
     
    );

}

export default withRouter(VetkitProfile);
