import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import API from '../services/API';
import PersonCard from '../components/cards/PersonCard'
import moment from 'moment';
import Exercise from '../components/Exercise';
import Breadcrumbs from '../components/Breadcrumbs';
import { getDuration } from '../utility/Visit';
import RomsGrid from '../components/compositions/RomsGrid';

const VisitProfile = ({ history }) => { 
	const visitId = useParams().visitId;
	const patientId = useParams().patientId;
	const [visitData, setVisitData] = useState(null);
	const [exerciseData, setExerciseData] = useState(null);
	const [patientData, setPatientData] = useState(null);
  const [clinicianData, setClinicianData] = useState(null);
  const [roms, setRoms] = useState(null);
	const date = visitData && moment(visitData.start_time).format('YYYY-MM-DD');

	useEffect(() => {
		if(!visitId){
			history.push(`/`);
		}
		getVisitData();		
  }, []);

  useEffect(() => {
    if(visitData && patientData){
      getRoms();
    }
  }, [visitData, patientData])

	useEffect(() => {
		if (visitData && patientData) {
			getRoms();
		}
	}, [visitData, patientData])


	const getPatientData = (id) => {
		API.get(`/patients/${id}`).then(response => {
			// console.log(response.data);
			setPatientData(response.data);
			getClinicianData(response.data.physician_id)
		}).catch(error => console.log(error))
	}

	const getClinicianData = (id) => {
		API.get(`/physicians/${id}`).then(response => {
			// console.log(response.data);
			setClinicianData(response.data);
		}).catch(error => console.log(error))
	}


	const getVisitData = () => {
		API.get(`/visits/${visitId}`)
			.then(response => {
				// console.log("VISIT", response.data);
				setVisitData(response.data);
				getPatientData(response.data.patient);
			
			})
			.catch(error => console.error(error));

			API.get(`/exercises?visit=${visitId}&ordering=visit__end_time`).then(response => {
				// console.log("Exercises", response.data)
				setExerciseData(response.data.results)
			}).catch(error => console.log(error));

	}


	const getRoms = () => {
		// console.log("getting roms")
		API.get(`/roms/?date_created_client__date=${date}&patient=${patientData.id}`).then(response => {
			// console.log("ROMs", response.data);
			setRoms(response.data.results.filter(rom => moment(rom.date_created_db).isBefore(moment(visitData.end_time)) && moment(rom.date_created_db).isAfter(moment(visitData.start_time))))
		}).catch(error => console.log(error));
	}


	const exercises = exerciseData && exerciseData.map((exercise, i) => (
		<Exercise key={i} exercise={exercise} />
	))



	
	

	const breadcrumbLinks = () => {


		if(patientId){
			return [
				{
					label: "Patients",
					url: "/patients"
				},
				{
					label: patientData && `${patientData.first_name} ${patientData.last_name}`,
					url: `/patient/${patientData && patientData.id}`
				},
				{
					label: "Visit summary",
					url: `/patient/${patientId}/visit/${visitId}`
				}
			]
		}
		else{
			return [
				{
					label: "Visits",
					url: "/visits"
				},
				{
					label: "Visit summary",
					url: `/visit/${visitId}`
				}
			]
		}


	}


	return (
		<Page title={visitData && "Visit Summary"} showMenu>
			<Breadcrumbs links={breadcrumbLinks()} />

			{visitData && <div>
				<div className="series">
					{patientData && <PersonCard person={patientData} type="patient" />}
					{clinicianData && <PersonCard person={clinicianData} type="clinician" />}
				</div>


				<h4>Date</h4>
				<h3>{moment(visitData.start_time).format("MM/DD/YY")}</h3>

				<h4>Duration</h4>
				<h3>{getDuration(visitData)} minutes</h3>
				<br />
				<h2>Ranges of Motion</h2>
				{roms && <RomsGrid roms={roms} />}

				<br /><br />
				<h2>Exercises</h2>
				{exercises}



			</div>}


		</Page>

	);

}

export default withRouter(VisitProfile);
