import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import PatientList from '../components/compositions/PatientList';
import Breadcrumbs from '../components/Breadcrumbs';




const Patients = ({}) => { 
	//get list

    return (
			<Page title={"Patients"} showMenu>
				<Breadcrumbs links={[
					{
						label: "Patients",
						url: "patients"
					}
					
				]} />
				<PatientList />
		</Page>
     
    );

}

export default withRouter(Patients);
