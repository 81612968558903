import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import API from '../services/API';
import Input from '../components/input/Input';
import { Link } from 'react-router-dom';



const ResetPasswordConfirm = ({history}) => { 

	const uid = useParams().uid;
	const token = useParams().token;
	const [password1, setPassword1] = useState(null);
	const [password2, setPassword2] = useState(null);
	const [changed, setChanged] = useState(false);


	const handleSubmit = (event) => {
		event.preventDefault();
		const data = JSON.stringify({ 
				new_password1: password1,
				new_password2: password2,
				token: token,
				uid: uid
			});

			console.log(data);

		API.post('password/reset/confirm/', data).then(response => {
					console.log(response);
					setChanged(true);
				}).catch(error => console.log(error));
		}

    return (
		<Page title={ null}>
			<div className="login">
 				<h2>{!changed ? "Choose a new password" : "Success"}</h2>
					{!changed ?				
						<form onSubmit={handleSubmit}>
							<Input noLabel name="New password" type="password" value={password1} onChange={(value) => setPassword1(value)} />
							<Input noLabel name="Confirm password" type="password" value={password2} onChange={(value) => setPassword2(value)} />
							<button onClick={handleSubmit}>Submit</button>
						</form>
						:
						<p>Password changed. <Link to="/login">Click here</Link> to sign in.</p>
					}	
			</div>
				
		</Page>
     
    );

}

export default withRouter(ResetPasswordConfirm);
