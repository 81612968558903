import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import API from '../services/API';
import VideoCard from '../components/cards/VideoCard';
import Breadcrumbs from '../components/Breadcrumbs';



const Media = ({}) => { 
	let content = window.Content;

	const videos = (series) => {
		return series.videos.map((video, i) =>
			<VideoCard key={i} video={video} series={series} index={i} />
		)
	}


	const series = () => {
		return content.videoSeries.map((series, i) => (
			<div key={i}>
				<h5>Video Series</h5>
				<h2 className="alternate">{series.title}</h2>

				<div className="series">

					{videos(series)}
				</div>
			</div>

		));
	}
    return (
			<Page className="media" title={"Media"} showMenu>
				<Breadcrumbs links={[
					{
						label: "Media",
						url: "/media"
					}
				]} />
				{series()}
		</Page>
     
    );

}

export default withRouter(Media);
