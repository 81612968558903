import React, { useState, useEffect } from 'react';
import Input from '../components/input/Input';
import cookie from 'react-cookies';
import { withRouter, useHistory, Redirect } from 'react-router';
import Page from '../components/Page';
import api from '../services/API';
import { login } from '../services/AccountServices';
import { Link } from 'react-router-dom';
import FormErrors from '../components/FormErrors';
import Spacer from '../components/Spacer';

const Login = ({ history, location, ...rest }) => {

	const [username, setUsername] = useState(null);
	const [password, setPassword] = useState(null);
	const [loginText, setLoginText] = useState("Sign in");
	const [errors, setErrors] = useState(null);
	const [successes, setsuccesses] = useState(null);

	const usernameChanged = (value) => {
		setLoginText("Sign in");
		setUsername(value);
	}

	const passwordChanged = (value) => {
		setLoginText("Sign in");
		setPassword(value);
	}


	const onLoginSuccess = (userData) => {
		if (location && location.state && location.state.from) {
			history.push(location.state.from);
		}

		else {
			console.log(userData.user)
			var roletype= userData.user.role!==null?userData.user.role.kind:null;
			var role=""
			switch(roletype){
				case 1:
					role= "Provider admin";
					break;
				case 2:
					role= "Physician";
					break;

				case 3: 
				role= "Patient";
				break;

				case 4:
					role= "Pain coach";
					break;

				case 5:
					role=  "Facilitator"
					break;

				case 7:
					role=  "Employer admin"
					break;

				case 8:
					role=  "Insurer admin"
					break;

				case 9:
					role= "TPA admin"
					break;

				case 10:
					role=  "Utilization reviewer admin"
					break;
				case 11:
					role = "Nurse Case Manager Admin"

		
				default:
					role=  "None";
					break;

		
		}
		if(role.includes("admin"))
		{
			history.push('/reportsExternal');
		}
		else
		{
			history.push('/');
		}
	}
	}


	/*useEffect(() => {
		const listener = event => {
		  if (event.code === "Enter" || event.code === "NumpadEnter") {
			console.log("Enter key was pressed. Run your function.");
			handleSubmitButton(event);
		  }
		};
		document.addEventListener("keydown", listener);
		return () => {
		  document.removeEventListener("keydown", listener);
		};
	  }, []);*/

	const onLoginFail = (message) => {
		setLoginText("Sign in");
		setErrors(message);

	}

	const handleSubmitButton = (e) => {
		e.preventDefault();

		setLoginText("Signing in");
		setErrors(null);
		login(username, password, onLoginSuccess, onLoginFail);
	}

	const clearCookie=()=>{

	cookie.remove('token', { path: '/' });
		cookie.remove('csrftoken', { path: '/' });
		cookie.remove('role', { path: '/' });
		cookie.remove('user', { path: '/' });
	localStorage.clear();
	}
	const handleSubmit = (e) => {
		e.preventDefault();

		setLoginText("Signing in");
		setErrors(null);

		login(username, password, onLoginSuccess, onLoginFail);
	}

	const handleKeypress = (e) => {
		//it triggers by pressing the enter key
		
	  if (e.key === "Enter") {
		handleSubmit(e);
 	  }
	};
	
clearCookie();
	return (
		<Page errors={errors} internal={false}>

		<div className="h-screen flex items-center justify-center pb-12 ">

					<div className=" w-96  h-96 flex-col justify-center  content-center	 items-center gap-8 inline-flex ">
						<div className="self-stretch h-36 flex-col justify-start items-center gap-6 flex" id="title">
							<div className="justify-start items-start inline-flex">
									<img className="w-12 h-12" src="./icons/icon.png" />
							</div>
							<div className="self-stretch h-20 flex-col justify-start items-start gap-3 flex lg:h-40">
								<div className="self-stretch text-center text-[#101828] text-3xl font-semibold  leading-9 " >Provider Portal</div>
								<div className="self-stretch text-center text-[#475467] text-base font-normal  leading-normal">Welcome back! Please enter your details.</div>
							</div>
						</div>

						<form class="w-full max-w-sm" onSubmit ={handleSubmitButton}  onKeyPress={(e) => handleKeypress(e)}     >
						<div className="self-stretch h-64 rounded-xl flex-col justify-start items-center gap-6 flex" id="form">
							<div className="self-stretch h-40 flex-col justify-start items-start gap-5 flex">
								<div className="self-stretch h-16 flex-col justify-start items-start gap-1.5 flex">
									<div className="self-stretch h-16 flex-col justify-start items-start gap-1.5 flex">
										<div className="text-slate-700 text-sm font-medium  leading-tight">Email</div>
<Input name="Email" className={"shadow appearance-none border rounded-lg w-full py-2.5 px-3.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"} value={username} type="text"  onChange={usernameChanged} noLabel required validations={[{ type: "email" }]} formErrors={errors} />


									</div>
								</div>
								<div className="self-stretch h-16 flex-col justify-start items-start gap-1.5 flex">
									<div className="self-stretch h-16 flex-col justify-start items-start gap-1.5 flex">
										<div className="text-slate-700 text-sm font-medium  leading-tight">Password</div>
										<Input name="Password"  className={"shadow appearance-none border rounded-lg w-full py-2.5 px-3.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"} value={password} type="password"  onChange={passwordChanged} noLabel required formErrors={errors}/>
										
									</div>
								</div>
							</div>
							<div className="self-stretch justify-center items-center inline-flex">
									<a href="/password/reset" className="text-brand-600 text-sm font-semibold  leading-tight">Forgot password</a>
								
							</div>
							<div className="self-stretch h-11 flex-col justify-start items-start gap-4 flex">
								<button type="submit" className="self-stretch px-4 py-2.5 bg-brand-600 hover:bg-brand-700 rounded-lg shadow justify-center items-center gap-1.5 inline-flex">
									<div className="px-0.5 justify-center items-center flex">
										<div className="text-white text-base font-semibold  leading-normal">{loginText}</div>
									</div>
								</button>
							</div>
						</div>
						</form>
					</div>
					
		</div>
		
		</Page>

		

	);


}

export default withRouter(Login);
