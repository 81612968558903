import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import API from '../services/API';
import TimeSeriesGraph from '../components/graphs/TimeSeriesGraph';
import RomsGraph from '../components/graphs/RomsGraph';
import Breadcrumbs from '../components/Breadcrumbs';


const ClinicianProfile = ({history}) => { 
	const clinicianId = useParams().id;
	const [clinicianData, setClinicianData] = useState(null);


	useEffect(() => {

		if(!clinicianId){
			history.push(`/clinicians/`);
		}

		if (!clinicianData) {
			getData();
		}
		
	});


	const getData = () => {
		API.get(`/physicians/${clinicianId}`)
			.then(response => {
				console.log(response);
				setClinicianData(response.data);
			})
			.catch(error => console.error(error));
	}

	
	const goToEditClinician = () => {
		history.push(`/clinician/${clinicianId}/edit`);
	}

    return (
		<Page title={clinicianData && `${clinicianData.first_name} ${clinicianData.last_name}`} showMenu>
				<Breadcrumbs links={[
					{
						label: "physicians",
						url: "/clinicians"
					},
					{
						label: clinicianData && `${clinicianData.first_name} ${clinicianData.last_name}`,
						url: window.location.url
					}
				]} />
			{clinicianData && <div>
					

					<button onClick={goToEditClinician} >Edit Physician</button>
					<br></br>
					<br></br>
				
			
			</div>}
			{/* {patientData && <TimeSeriesGraph type="visits" timeKey="start_time" limit="200" label="Recent visits" filter={{id:"patient", value:patientData.display_name}} />} */}
			{/* {patientData && <RomsGraph timeKey='db_created' patientId={patientData.profile.id}/>} */}

		</Page>
     
    );

}

export default withRouter(ClinicianProfile);
