import React, { useState, useEffect } from 'react';
import Input from './Input';
import DropdownInput from './DropdownInput';
import CustomDropDown from '../compositions/CustomDropDown';


const HeightInput = ({ metric, patientHeight, setPatientHeight, initialHeight, formErrors }) => {
	const [feet, setFeet] = useState(null);
	const [inches, setInches] = useState(null);
	const [init, setInit] = useState(false);


	useEffect(() => {
		console.log("Initial height", initialHeight)
		if(initialHeight &&!init){
			console.log(initialHeight)
			setInit(true)
			heightToImperial(initialHeight);
		}
	
	}, [initialHeight]);

	useEffect(() => {
		if(feet!==null&&inches!==null){
			
		heightToMetric();
		}
	}, [feet, inches]);


	const heightToImperial = (height) => {
	
		console.log("Converting height to imperial")
		let rawFeet = height * 3.2808;
		var inches = Math.round((rawFeet - Math.floor(rawFeet)) * 12)
		var tempboost=0;
		if(inches===12){
			tempboost=1;
			inches=0;
		}
		if(rawFeet<3){rawFeet=3;}
		console.log(inches)
		setInches(inches);
		setFeet(Math.floor(rawFeet)+tempboost);

	}

	function rangeCheck(number)
	{
		console.log('x')
		if(feet>6){return 0}
		if(number>11){return 11}
		if(number<0){return 0}
		return number

	}

	const heightToMetric = () => {
		console.log(feet)
		console.log(inches)

		
		let heightInInches = parseInt(feet * 12) + parseInt(inches);
		

		let newHeight = heightInInches * 0.0254;


		// if (Math.round(patientHeight * 100) / 100 != Math.round(newHeight * 100) / 100){
			setPatientHeight(newHeight);
		// }
	}

	const handleFeetChange = (event) => {
		console.log('x')

		var number = parseInt(event);
		if(number>7){number= 7; }
		if(number<3){number=3 }
		if(number>=7){setInches(0);}
		console.log(inches)

		setFeet(number);
	}
	
	const renderErrors = 
		(patientHeight < 0.9144 || patientHeight > 2.136) && "Patient height must be between 3 and 7 feet tall."
	


const FeetOptions = [
	{
		name: "3 feet",
		id: 0,
		value: 3
	},
	{
		name: "4 feet",
		id: 1,
		value: 4
	},
	{
		name: "5 feet",
		id: 2,
		value: 5
	},
	{
		name: "6 feet",
		id: 3,
		value: 6
	},
	{
		name: "7 feet",
		id: 4,
		value: 7
	},
];

const inchOptions = [
	{
		name: "0 inches",
		id: 0,
		value:0
	},
	{
		name: "1 inches",
		id: 1,
		value:1
	},
	{
		name: "2 inches",
		id: 2,
		value:2
	},
	{
		name: "3 inches",
		id: 3,
		value:3
	},
	{
		name: "4 inches",
		id: 4,
		value:4
	},
	{
		name: "5 inches",
		id: 5,
		value:5
	},
	{
		name: "6 inches",
		id: 6,
		value:6
	},
	{
		name: "7 inches",
		id: 7,
		value:7
	},
	{
		name: "8 inches",
		id: 8,
		value:8
	},
	{
		name: "9 inches",
		id: 9,
		value:9
	},
	{
		name: "10 inches",
		id: 10,
		value:10
	},
	{
		name: "11 inches",
		id: 11,
		value:11
	}
];


	return (
		feet!==null&&inches!==null&&<React.Fragment>
			 <div className="w-full self-stretch justify-start items-end gap-8 inline-flex">
                <div className="grow shrink  w-full basis-0 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="self-stretch w-full h-[70px] flex-col justify-start items-start gap-1.5 flex">
                        <div className="text-gray-700 w-full text-sm font-medium font-['Inter'] leading-tight">Height</div>
						<CustomDropDown defaultValue={feet} textcolor='text-gray-500' options={FeetOptions} onchange={setFeet}  fullwidth={true}/>

						{/*<DropdownInput
			className={"self-stretch w-full basis-0 text-gray-500 text-base font-normal font-['Inter'] px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex"}
			name="feet" 
			options={FeetOptions} 
			required 
			value={feet} 
			onChange={setFeet}
	formErrors={formErrors} />*/}
                        
                    </div>
                </div>
                <div className="grow shrink w-full  basis-0 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="self-stretch w-full h-[70px] flex-col justify-start items-start gap-1.5 flex">
					<div className="text-white w-full text-sm font-medium font-['Inter'] leading-tight">height</div>
					<CustomDropDown defaultValue={inches} textcolor='text-gray-500' options={inchOptions} onchange={setInches}  fullwidth={true}/>

					{/*<DropdownInput
			className={"self-stretch w-full basis-0 text-gray-500 text-base font-normal font-['Inter']  px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex"}
			name="inches" 
			options={inchOptions} 
			required 
			value={inches} 
			onChange={setInches}
			formErrors={formErrors} />*/}
                     
                    </div>
                </div>
            </div>
		</React.Fragment>
	
	)

	
}

export default HeightInput;