import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import { TailSpin	 } from  'react-loader-spinner'
import cookie from 'react-cookies';
import moment from 'moment';
import { Chart as ChartJS, defaults } from "chart.js/auto";
import {  Line } from "react-chartjs-2";
import DropdownInput from '../input/DropdownInput';
import CustomDropDown from './CustomDropDown';

const CoachingPlanPanel = ({stats=null, currentWeek=null}) => {
  const [weekstats,setWeekStats]=useState(null)
  const [weekOptions,setWeekOptions]= useState(null)
  const [missingData,setMissingData]=useState(false)
  const [currentView, setCurrentView] = useState("total")

  useEffect(()=>{
    if(stats===-1)
    {
      setMissingData(true);
      return;
    }
    if(stats!==null&&stats!==undefined)
    {
      console.log(stats)
      var size = Object.keys(stats).length-1
      var track =(Object.keys(stats)[size])
      var currentTrack=(stats[track])

      if(currentTrack.plan_weeks!==undefined)
      {
        let w=-1;

        let options = [
          {
            name: "All Time",
            id: 0,
            value: "total"
          }
          
        ];
        var weeks={}
        var total={sessions:0,time:0,bonusStart:0,bonusCompletions:0,routineStart:0,routineCompletions:0,mood:[],pain:[]}
        Object.keys(currentTrack.plan_weeks).forEach((e,i)=>{
          if(i<=currentWeek){
            w=i;

          options.push( {
            id: i+1,
            name: "Week "+i.toString(),
            value: i
          })}
          let weekdata =currentTrack.plan_weeks[e]
          let week={sessions:0,time:0,bonusStart:0,bonusCompletions:0,routineStart:0,routineCompletions:0,mood:-1,pain:-1}
          if(weekdata.visits!==undefined)
          {
            week['sessions']+= weekdata.unique_days
            total['sessions']+= weekdata.unique_days


          }
          if(weekdata.checkins!==undefined)
            {
              var pain = weekdata.checkins[0].pain_level
              var mood= weekdata.checkins[0].mood_level
              week.mood=mood;
              week.pain=pain
              total.mood.push([mood,i])
              total.pain.push([pain,i])

              /*if(total.mood===-1)
                {
                  total.mood=mood;
                }
              else{
                if(i=== Object.keys(currentTrack.plan_weeks).length-1){
                  total.mood= Math.abs((mood-total.mood)/total.mood)
                  total.moodweek=i
                }
              }*/
              /*if(total.pain===-1)
                {
                  total.pain=pain;
                  total.painweek=i;
                }
              else{
                if(i=== Object.keys(currentTrack.plan_weeks).length-1){
                  total.pain= Math.abs((pain-total.pain)/total.pain)
                }
              }*/
              
            }
          if(weekdata.time_spent!==undefined)
          {
            week['time']+= weekdata.time_spent
            total['time']+= weekdata.time_spent
          }
          if(weekdata.bonus_activity!==undefined)
          {
            week['bonusStart']+= weekdata.bonus_activity.starts
            week['bonusCompletions']+= weekdata.bonus_activity.completions
            total['bonusStart']+= weekdata.bonus_activity.starts
            total['bonusCompletions']+= weekdata.bonus_activity.completions
          }
          if(weekdata.personal_routine!==undefined)
          {
            week['routineStart']+= weekdata.personal_routine.starts
            week['routineCompletions']+= weekdata.personal_routine.completions
            total['routineStart']+= weekdata.personal_routine.starts
            total['routineCompletions']+= weekdata.personal_routine.completions
          }
          weeks[i]=week
        })
        weeks["total"]=total;
        setWeekStats(weeks)
        setCurrentView(w!==-1?w:"total")
        setWeekOptions(options)
      }
      else
      {
        setMissingData(true);
        return;
      }
    }

  },[stats])

  const RenderMoodPain=(view,data)=>{
    if(view==="total")
      {
        console.log(data)
          if(data.length===0){return "No data found"}
          else if(data.length===1){
            return <p>{data[0][0]} from week {data[0][1]}</p>
          }
          else{
            var change = (data[data.length-1][0]-data[0][0])/data[0][0]
            change*=100
            console.log(change)
            return ( <p className={change>0?"text-success-600":"text-warning-600"}>{change>0?"+":""}{Math.round(change)}%</p>)

          }
      }
      else{
        if(data!==-1)
          {
            return data
          }
        else{
          return "-"
        }
      }

  }

  const RenderTable=(week)=>{

    var table=[];
    table.push(
      <tr className='  border-0   even:bg-white odd:bg-white ' >
        <td className=" 	w-1/2	 grow shrink border-t-0 border-b-0 border-x-0 px-6  text-start  text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "><p className='whitespace-nowrap  inline-flex'><span className='text-gray-900 	'>VET™ Sessions</span></p> 			<div class=' align-text-bottom	has-tooltip float-bottom text-center	content-center	items-center	 pl-2 object-bottom whitespace-nowrap  inline-flex 	'><span class='tooltip rounded shadow-lg p-1 -mt-12 ml-2'>Unique days in which the patient completed<br/> at least one VET™ activity</span><img className='h-4 w-4 hs-tooltip ' src ={"/icons/help-circle.svg"} ></img></div>
        {console.log(weekstats[currentView])}

        <div className='float-end 	'>{weekstats[currentView].sessions}</div>
</td>
        <td className="w-1/2		border-t-0 border-x-0 px-6  text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">
        <span className='text-gray-900 	'>Reported Pain   </span>      <div className='float-end 	'>{RenderMoodPain(currentView,weekstats[currentView].pain)}</div>
</td>
      </tr>
    )
    let time = weekstats[currentView].time;
    table.push(
      <tr className=' border-y-2  even:bg-white odd:bg-white '  >
        <td className="	w-1/2	 border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "><span className='text-gray-900 '>VET™ Time</span> <div className='float-end 	'>{time>60?((moment.duration(time*1000).hours()>0? moment.duration(time*1000).hours()+"h ":"")+( moment.duration(time*1000).minutes()>0? moment.duration(time*1000).minutes()+"m ":"")):moment.duration(time*1000).seconds()+"s " }</div></td>
        <td className="	w-1/2	 border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "><span className='text-gray-900 '>Reported Mood</span> <div className='float-end 	'>{RenderMoodPain(currentView,weekstats[currentView].mood)}</div></td>
      </tr>
    )
    table.push(
      <tr className=' border-y-2  even:bg-white odd:bg-white '  >
        <td className="	w-1/2	 border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "><span className='text-gray-900 	'>Bonus Activity Starts</span> <div className='float-end 	'>{weekstats[currentView].bonusStart}</div></td>
        <td className="	w-1/2	 border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">
        <span className='text-gray-900 	'>Routine Starts   </span>      <div className='float-end 	'>{weekstats[currentView].routineStart}</div>
        </td>
      </tr>
    )
    table.push(
      <tr className=' border-y-2   even:bg-white odd:bg-white ' >
        <td className="	w-1/2	 border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "><span className='text-gray-900 	'>Bonus Activity Completions</span> <div className='float-end 	'>{weekstats[currentView].bonusCompletions}</div></td>
        <td className="	w-1/2	 border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] "><span className='text-gray-900 '>Routine Completions</span> <div className='float-end 	'>{weekstats[currentView].routineCompletions}</div></td>
      </tr>
    )

    return table;
  }

  return (
    
    <React.Fragment>
    { (stats===null||weekstats===null)&&missingData!==true?
        (<div className=" w-full h-full justify-start items-center inline-flex flex-col 	">
        <TailSpin	
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                  />
                  <br /> <br />
                  <div/>
              <h4 className="font-['Inter']">Loading usage stats...</h4>
        </div>):(
            <div className=" w-full h-[90%] justify-start items-center inline-flex flex-col 	">
              {missingData===false?    <React.Fragment>

              <div className="w-full h-[10%]   gap-5 inline-flex justify-start items-center ">
                    <div className="grow shrink basis-0 text-gray-900 text-xl font-semibold font-['Inter'] ml-[26px]">Usage Stats</div>
                    
                    <div className=" flex-col justify-start items-start inline-flex mr-[26px]">
                      {
                        weekOptions!==null&&<CustomDropDown options={weekOptions} onchange={setCurrentView} defaultValue={currentView} />
                      }
                     
                       
                    </div>
              </div>
              <div className="w-[calc(100%-48px)] h-[80%] border-0  mt-5">
             
              <table className='w-full h-[100%] divide-gray-700 border-0  dark:divide-gray-700 rounded-[10px]'>
                   
                        <tbody className='border-0 '>
                            <React.Fragment>
                        
                           {RenderTable(currentView)}
                         
                        
                        </React.Fragment>
                        </tbody>
              </table >
              </div>
              </React.Fragment>:    <React.Fragment>

              <div className=" w-full h-full justify-start items-center inline-flex flex-col">
                No Usage Data Found
              </div>
                </React.Fragment>
}
               
                
            </div>
        )
    }
    </React.Fragment>
    )
}

export default CoachingPlanPanel