import React, { useState, useEffect } from 'react';
import API from '../../services/API';
import { withRouter, useParams } from 'react-router';
import { element } from 'prop-types';
import CoachingPlanList from './CoachingPlanList';
import { TailSpin	 } from  'react-loader-spinner'
import moment from 'moment-timezone';


const PriorityProtocolList= ({  priorityProtocol, dayName, protocol}) => { 

	const [protocols, setProtocols] = useState(protocol);

	useEffect(() => {
		//getProtocols();
		
		
	  }, [])

      const camelCaseParser=(text)=>{
		return text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
	}

	const getProtocols= () => {
		API.get(`/protocols`).then(response => {
			var protocol ={}
			response.data.results.forEach(element => protocol[element.id]=element.display_name);
			setProtocols(protocol)

		}).catch(error => console.log(error));
	  }
       
	

	const DisplayList = protocols && priorityProtocol&& priorityProtocol.progress &&priorityProtocol.progress.length>0 && priorityProtocol.progress.map((prot, index) => (
        <div key={index} className="row">
        <h4 className="chart-label">{camelCaseParser( protocols[ Object.keys(prot)[0]])} </h4>
		<h4 className="chart-label-narrow">{(parseInt( prot[Object.keys(prot)[0]])*100).toFixed()}%</h4>

    </div>
	))
		
	return (
			<React.Fragment>



			{<h3>My Routine : { priorityProtocol!==null&& ("is_complete" in priorityProtocol ) && priorityProtocol.is_complete===true? "Complete": "Incomplete" }</h3>}
			{priorityProtocol!==null?<h4>Week: {priorityProtocol.plan_week}</h4>:<>not found</>}
			{protocols!==null? (priorityProtocol? DisplayList:<h4>No Data Recorded for Routine</h4>) : <TailSpin	
			height="100"
			width="100"
			color='grey'
			ariaLabel='loading'
		  />&&
      	<br />&& <br />&&
      <h4>Loading...</h4>
	  }
      <br/>
			</React.Fragment>
		

		
	);
}


export default withRouter(PriorityProtocolList);
