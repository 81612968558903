import React from 'react';
import Page from '../components/Page';
import { useParams, withRouter } from 'react-router';
import { useEffect } from 'react';
import cookie from 'react-cookies';
import API from '../services/API';
import { getRole } from '../services/AccountServices';
import Axios from 'axios';

const UnityVisitSummaryRedirect = ({ history }) => {

  const token = useParams().token;
  const visitId = useParams().visitId;
  const domain = window.location.origin.includes("localhost") ? "" : "karunalabs.com";

  useEffect(() => {
    if(token && visitId){
      console.log('saving cookie')
      cookie.save('token', token, { maxAge: 7200, path: '/', sameSite: 'none', secure: true  });

      API.get('user/', {headers:{
        'Accept': 'application/json; version=1.0',
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + token
      }}).then(response => {
        console.log(response.data);      
        cookie.save('role', getRole(response.data.role.kind), { maxAge: 7200, domain: domain, path: '/', sameSite: 'none', secure: true  });
        history.push(`/visit/${visitId}`);
      }).catch(error => console.log(error.response));
    }
    
  }, [])
  

  return(
    <Page >

    </Page>
  )

}

export default withRouter(UnityVisitSummaryRedirect);


