import React, { useState, useEffect } from 'react';
import API from '../../services/API';
import { withRouter, useParams } from 'react-router';
import { element, object } from 'prop-types';
import PersonalBestCard from './PersonalBestCard';

const NewBests = ({  exerciseData, Render=false}) => { 

	const [display, setDisplay] = useState(false);
	useEffect(() => {
        
    }, [Render])



	var modDict={}
    exerciseData.forEach(element => {
		if(modDict[element.activity_name]===undefined)
		{
			if("top_alltime" in element.scores && element.scores["top_alltime"]===true){
				modDict[element.activity_name]={}

				if("first_top_alltime" in element.scores && element.scores["first_top_alltime"]===true)
				{
					modDict[element.activity_name]["first"]=true;
				}
				else
				{
					modDict[element.activity_name]["first"]=false;

				}
				modDict[element.activity_name]["score"]=element.scores.score;

			}
			if("goals" in element.scores)
			{
				element.scores.goals.forEach(goal=>{
	
					if("top_alltime" in goal && goal["top_alltime"]===true)
					{
						if(modDict[element.activity_name]===undefined)
						{
							modDict[element.activity_name]={}

						}
						modDict[element.activity_name]["goals"]={}
						modDict[element.activity_name]["goals"][goal.label]={}
						if("first_top_alltime" in goal && goal["first_top_alltime"]===true)
						{
							modDict[element.activity_name]["goals"][goal.label]["first"]=true;

						}
						else
						{
							modDict[element.activity_name]["goals"][goal.label]["first"]=false;

						}
						modDict[element.activity_name]["goals"][goal.label]["completions"]=goal.completions;

						
		
					}
	
				})
			}
		}
		else
		{
			if(modDict[element.activity_name]["score"]===undefined){
				modDict[element.activity_name]["score"]=0;
			}
			if("top_alltime" in element.scores && element.scores["top_alltime"]===true &&element.scores.score>modDict[element.activity_name]["score"]){
				if("first_top_alltime" in element.scores && element.scores["first_top_alltime"]===true)
				{
					modDict[element.activity_name]["first"]=true;
				}
				else
				{
					modDict[element.activity_name]["first"]=false;

				}
				modDict[element.activity_name]["score"]=element.scores.score;

			}
			if("goals" in element.scores)
			{
				element.scores.goals.forEach(goal=>{
					if(modDict[element.activity_name]===undefined)
						{
							modDict[element.activity_name]={}

						}
					if(	modDict[element.activity_name]["goals"]===undefined		){
						modDict[element.activity_name]["goals"]={}	
					}

					if(modDict[element.activity_name]["goals"][goal.label]!==undefined)
					{
						if("top_alltime" in goal && goal["top_alltime"]===true  &&modDict[element.activity_name]["goals"][goal.label]["completions"]<goal.completions)
						{
							if("first_top_alltime" in goal && goal["first_top_alltime"]===true)
							{
								modDict[element.activity_name]["goals"][goal.label]["first"]=true;

							}
							else
							{
								modDict[element.activity_name]["goals"][goal.label]["first"]=false;

							}
							modDict[element.activity_name]["goals"][goal.label]["completions"]=goal.completions;

						}
		
					}
					else
					{
						if("top_alltime" in goal && goal["top_alltime"]===true)
						{
							modDict[element.activity_name]["goals"][goal.label]={}

							if("first_top_alltime" in goal && goal["first_top_alltime"]===true)
							{
								modDict[element.activity_name]["goals"][goal.label]["first"]=true;

							}
							else
							{
								modDict[element.activity_name]["goals"][goal.label]["first"]=false;

							}
							modDict[element.activity_name]["goals"][goal.label]["completions"]=goal.completions;

							
			
						}
					}
	
				})
			}
		}
       

    });
        
	

	const activityList = modDict&&Object.keys(modDict).length>0 && Object.keys(modDict).map((key, index) => (
		
		<PersonalBestCard Name={key} Data={modDict[key]} hide={!display} />
	))
	

	return (
		<span>
			<React.Fragment>
			{display &&exerciseData.length>0&&<br/>&&<br/>&&<h3>New Personal Bests</h3>}
			{!display &&exerciseData.length>0&&<br/>&&<br/>&&<h3>Hiding Personal Bests</h3>}
			
			
			{display &&activityList} 
			{!display&&exerciseData.length>0&&<br/>&&<br/>&& <button onClick={() => setDisplay(true)}>Show Personal Bests</button>}
			{display &&exerciseData.length>0&& <br/>&&<br/>&&<button onClick={() => setDisplay(false)}>Hide Personal Bests</button>}
			</React.Fragment>
			<br />			<br />

		</span>
	);
}


export default withRouter(NewBests);
