import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import Breadcrumbs from '../components/Breadcrumbs';
import API from '../services/API';
import cookie, { load } from 'react-cookies';
import { weeksToDays } from 'date-fns';
import { camelCase } from 'lodash';

import { TailSpin	 } from  'react-loader-spinner'



const BadgePage = ({ history }) => { 
    ///////////////
    // WEB HOOKS //
    ///////////////
    const patientId = useParams().id;
	const [patientName, setName] = useState(null);
	const [badges, setBadges] = useState(null);
	const [loading,setLoading]= useState(true);

    useEffect(() => {
		if (!patientId) {
			history.push('/patients/');
		}

		if (!patientName) {
			getPatientData();
		}
		
		
	});

	const badgeParse=(text)=>{
		if(text==="1x")
		{return "Bronze"}
		if(text==="2x")
		{return "Silver"}
		if(text==="3x")
		{return "Gold"}
		if(text==="5x")
		{return "Gold Legacy"}
		return ""
	
	}

	const camelCaseParser=(text)=>{
		return text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
	}

	const showBadges = () => {
		var badgeList={}
		if(badges!==null)
		{
			
		  for (const [key, value] of Object.entries(badges)) 
		  {
			if(value!==[] && value.length>0)
			{
			  
			   
			  value.forEach(e =>{
				  if(e.week_earned>=0){
			
				if(badgeList[e.week_earned]===undefined){badgeList[e.week_earned]=[]}
				 badgeList[e.week_earned].push([key,e]);
				  }
				})
			  
			}
		  }
		  var weeks=[]
		  if(Object.keys(badgeList).length>0)
		  {
			for (const [key, value] of Object.entries(badgeList)) 
			{
				if(value!==null && value!==[] &&value.length>0)
				{
					weeks.push(showBadgeForWeek(key, value))
				}
			}
	
		  }
		  return weeks
		}
		else{
			return <React.Fragment><h1>No Badges Found For Patient</h1></React.Fragment>
		}
	  
	  }

	  const showBadgeForWeek=(week, weekBadges)=>{
		var badgeList={}
		if(weekBadges!==null)
		{
		  weekBadges.forEach(e =>
		  {
			if(e[1]!==undefined)
			{

			  if(badgeList[e[0]]===undefined){
				badgeList[e[0]]=[]
			  }
			  badgeList[e[0]].push(e[1]);
				
			}
		  }
		  )
		  if(Object.keys(badgeList).length>0)
		  {
			console.log(badgeList)
			  
			const cpBadges = badgeList["coaching_plan"] && badgeList["coaching_plan"].map((element) => (
	 
			  <React.Fragment>
				{
				<h4>{camelCaseParser(element.id.split("_")[0])+("week_earned" in element? " Week "+element.week_earned:""   )}  </h4>
			 }
			  </React.Fragment>
			  
			  
			 ))
			 const cpwBadges = badgeList["coaching_plan_weekly"] && badgeList["coaching_plan_weekly"].map((element) => (

			  <React.Fragment>

				{
					
            <h4>{camelCaseParser(element.id.split("_")[0]) +" "+element.id.split("_")[1]+" "+badgeParse(element.id.split("_")[element.id.split("_").length-1])}  </h4>
		}
			  </React.Fragment>
			  
			  
			 ))
			 const ppwBadges = badgeList["personal_routine_weekly"] && badgeList["personal_routine_weekly"].map((element) => (

				<React.Fragment>
  
				  {
					  
					  <h4>{ "Personal Routine Week "+element.id.split("_")[1]+" "+badgeParse(element.id.split("_")[element.id.split("_").length-1]) }  </h4>
					}
				</React.Fragment>
				
				
			   ))
			 const ActBadges = badgeList["activity"] && badgeList["activity"].map((element) => (
			  <React.Fragment>

				{
					
            <h4>{camelCaseParser(element.id.split("_")[1])+" "+badgeParse(element.id.split("_")[element.id.split("_").length-1]) +("week_earned" in element? " Week "+element.week_earned:""   )}  </h4>
		}
			  </React.Fragment>
			  
			  
			 ))
		  return(
			<React.Fragment>
				<h2>Week {week}</h2>
			  {badgeList["activity"] ?<h3>Activity Badges</h3>:""}
			  {badgeList["activity"] ?ActBadges:""}
			  {badgeList["activity"] ?<React.Fragment><br/></React.Fragment>:""}

			  {badgeList["coaching_plan_weekly"] ?<h3>Coaching Plan Weekly Badges</h3>:""}
			  {badgeList["coaching_plan_weekly"] ?cpwBadges:""}
			  {badgeList["coaching_plan_weekly"] ?<React.Fragment><br/></React.Fragment>:""}

			  {badgeList["coaching_plan"] ?<h3>Coaching Plan Badges</h3>:""}
			  {badgeList["coaching_plan"] ?cpBadges:""}
	          {badgeList["coaching_plan"] ?<React.Fragment><br/><br/></React.Fragment>:""}

			  {badgeList["personal_routine_weekly"] ?<h3>My Routine Weekly Plan Badges</h3>:""}
			  {badgeList["personal_routine_weekly"] ?ppwBadges:""}
	          {badgeList["personal_routine_weekly"] ?<React.Fragment><br/><br/></React.Fragment>:""}

			</React.Fragment>
	
		  )
	
		  }
		}

	  }

	const getPatientData = () => {
		
		var badges ={"activity":[],"coaching_plan_weekly": []}

			API.get(`/patients/${patientId}/`)
			.then(response => {

				setLoading(false);
				if(response.data.badges===undefined){

				API.get(`/badges/?patient=${patientId}`).then(response => {
           
					response.data.forEach(e=>{
					 
					  if(parseInt( e.patient)===parseInt( patientId) )
					  {
					   badges.activity= badges.activity.concat(e.badges.activity)
					   badges.coaching_plan_weekly= badges.coaching_plan_weekly.concat(e.badges.coaching_plan_weekly)
					  }
					})
					setBadges(badges);
				  }).catch(error => console.log(error))
				}
				else{
					setBadges(response.data.badges);

				}
				setName(response.data.first_name+" "+response.data.last_name);
			})
			.catch(error => console.error(error));
	}

    return (

			<Page title={`Patient's Badges`} showMenu>
				<Breadcrumbs links={[
				{
					label: "Patients",
					url: "/patients"
				},
				{
					label: patientName && `${patientName}`,
					url: `/patient/${patientId}`
				}, 
                {
                    label: "badges",
                }
			]} />
	{loading?	<React.Fragment>
      
	  <TailSpin	
			height="100"
			width="100"
			color='grey'
			ariaLabel='loading'
		  />
      	<br /> <br />
      <h4>Loading...</h4>
	  
      </React.Fragment>
	  
	  :showBadges()
	}
		</Page>

     
    );

}

export default withRouter(BadgePage);
