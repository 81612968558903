import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import API from '../services/API';
import PropTypes from 'prop-types';
import Input from '../components/input/Input';
import DropdownInput from '../components/input/DropdownInput';
import ProfileInput from '../components/input/ProfileInput';
import FormErrors from '../components/FormErrors';
import { forEach, result } from 'lodash';

const EditClinician = ({ history, location }) => {

	const [clinicianId, setClinicianId] = useState(useParams().id);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [provider, setProvider] = useState(null);

	const [createdNew, setCreatedNew] = useState(false);
	const [providers, setProviders] = useState([]);

	const [errors, setErrors] = useState(null);

	useEffect(() => {
		if (clinicianId) {
			getData();
		}
		else{
			GetProviders();
		}
	}, [clinicianId]);



	const handleSubmit = (event) => {
		console.log("submitt")

		event.preventDefault();

		const data = JSON.stringify({
			first_name: firstName,
			last_name: lastName,
			provider: provider,
			email: email
		});

		if (clinicianId) {
			updateData(data);
		}
		else {
			createClinician(data);
		}
	}

	const GetProviders = ()=>{
		API.get(`/providers/`)
			.then(response => {
				var provs=[]
				
				response.data.results.forEach(i=>
					{
						
						provs.push({
							label:i.name, 
							id: i.id,
						})
						
					})
				setProviders(provs);

			})
			.catch(error => {
				console.error(error.response)
			
			});
	}

	const getData = async  () => {
		var prov=null;
		await API.get(`/physicians/${clinicianId}`)
			.then(response => {
				setFirstName(response.data.first_name);
				setLastName(response.data.last_name);
				setEmail(response.data.email);
				prov =(response.data.provider_name)

			})
			.catch(error => {
				console.error(clinicianId)
				console.error(error.response)
			
			});
			await API.get(`/providers/`)
			.then(response => {
				console.log(response)
				var provs=[]
				response.data.results.forEach(i=>
					{
						if(prov===i.name){
							setProvider(
								i.id
							)
						}
						provs.push({
							label:i.name, 
							id: i.id,
						})
						
					})
				setProviders(provs);

			})
			.catch(error => {
				console.error(error.response)
			
			});
	}

	const updateData = (data) => {
		API.put(`/physicians/${clinicianId}/`, data)
			.then((response) => {
				console.log(response);
				goToClinician(clinicianId);
			})
			.catch(error => {
				console.error(error.response)
				setErrors(error.response && error.response.data);
			});
	}

	const createClinician = (data) => {
		API.post(`/physicians/`, data)
			.then((response) => {
				console.log(response);
				setCreatedNew(true);
				setClinicianId(response.data.id);
			})
			.catch(error => {
				console.error(error.response)
				setErrors(error.response && error.response.data);
			});
	}

	const goToClinician = (id) => {
		
		history.push(`/physician/${id}/`);
	}

	const ToClinician = () => {
		history.push(`/physician/${clinicianId}/`);
	}


	const emailBody = `Hi ${firstName}\n\nYou have a new Clinician account in the Karuna Provider Portal. Follow this link to set a password and log in: ${window.location.origin}/password/set/${email} \n \nThanks,\nTeam Karuna`;
	const emailSubject = `Your Clinician Account at the Karuna Provider Portal`;


	return (
		<Page title={clinicianId ? "Edit Clinician" : "Create Clinician"} showMenu>
			<div className="section">
				<FormErrors errors={errors} />
				{!createdNew &&
					<form onSubmit={handleSubmit}>
					<Input
						type="text"
						name="First name"
						value={firstName}
						onChange={setFirstName}
						required
						validations={[{ type: "minLength", value: 2 }, { type: "maxLength", value: 30 }]}
						formErrors={errors}
					/>
					<Input
						type="text"
						name="Last name"
						value={lastName}
						onChange={setLastName}
						required
						validations={[{ type: "minLength", value: 2 }, { type: "maxLength", value: 30 }]}
						formErrors={errors}
					/>
						<Input
							type="text"
							name="Email"
							value={email}
							onChange={setEmail}
							validations={[{ type: "email" }, { type: "maxLength", value: 30 }]}
							required
							formErrors={errors}
						/>

{ <DropdownInput
			name="Provider" 
			options={providers} 
			required 
			value={provider} 
			onChange={setProvider}
			formErrors={errors} />
}
			
						<button>{clinicianId ? "Save" : "Create"}</button>
					</form>
				}
				{createdNew && <div>
					<p>Successfully created {firstName} {lastName}! <a href={`mailto:${email}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`}>Click here to email instructions to new clinician.</a></p>
					<p>Or, copy this link and send it to them directly: {`${window.location.origin}/password/set/${email}`} </p>
					<button onClick={ToClinician}>Go to phyisican</button>


				</div>}

			</div>

		</Page>

	);

}

EditClinician.propTypes = {
	patientHeight: PropTypes.number,
	inches: PropTypes.number,
	feet: PropTypes.number
};

export default withRouter(EditClinician);
