import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';

import MetricsList from '../components/compositions/MetricsList';
import Breadcrumbs from '../components/Breadcrumbs';




const AnalyticsDashboard = ({}) => { 
    return (
			<Page title={"Analytics Dashboard"} showMenu>
				<Breadcrumbs links={[
					{
						label: "Analytics",
						url: "/analytics"
					}
				]} />
				<MetricsList />
		</Page>
     
    );

}

export default withRouter(AnalyticsDashboard);
