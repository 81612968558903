import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import API from '../services/API';
import { useHistory } from "react-router-dom";
import Page from '../components/Page';
import HeadSetSection from '../components/compositions/HeadSetSection';
import VideoCard from '../components/cards/VideoCard';
import PatientList from '../components/compositions/PatientList';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import OutcomeTableVerification from '../components/table/OutcomeTableVerification';
import OutcomeTable from '../components/table/OutcomeTable';
import { withRouter, useParams, useLocation } from 'react-router';
import Spacer from '../components/Spacer';
import Input from '../components/input/Input';
import { TailSpin	 } from  'react-loader-spinner'
import moment from 'moment';
import { set } from 'lodash';
import classNames from 'classnames';

const Gallery = ({}) => { 
	const {state} = useLocation();
	const [errors, setErrors] = useState(null);
	const [activeCount, setActiveCount] = useState(0);
	const [attentionCount, setAttentionCount] = useState(0);
	const [userIsClinician, setUserIsClinician] = useState(false);
	const [patients, setPatients] = useState(null);
	const [masterPercentage, setMasterPercentage] = useState(0);
  const [slavePercentage, setSlavePercentage] = useState(0);
  const [filter, setFilter]=useState(null);
  const [showInactive, setShowInactive] = useState(false);
  const [filteredpatients, setFilteredPatients] = useState(null);
  const [loading, setLoading] = useState(true);
  const [issues, setIssues] = useState(true);

  const[width,setWidth]= useState(null);
  const tableRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

const handleResize = () => {
    setDimensions({
    width: window.innerWidth,
    height: window.innerHeight,
    });
  }
useEffect(() => {
  window.addEventListener("resize", handleResize, false);
}, []);

useEffect(() => {
  }, [dimensions]);

  const divStyle = {
	width: (width<window.innerWidth? "fitContent":"90%")

  }


	let content = window.Content;

	const role = cookie.load('role') ? cookie.load('role') : 'None';
	const isClinician = role === "Physician" 


	const batchPatients= async (next, retrieved, setCount=false)=>{
		var path = next.split(".com")[1];
		await API.get(path)
			.then(response => {
				console.log(response.data.results);
				retrieved.push(...response.data.results)
				if(response.data.next===null)
				{
					setPatients(retrieved);
					if(!setCount){
					setLoading(false);
					}
					else
					{
						setActiveCount(retrieved.length);
					}

				}
				else{
					batchPatients(response.data.next,retrieved)
				}
			})
			.catch(
				error => {
					console.error(error)
					setPatients([]);
				}
				);

	}

	const  GetPatients=async()=>{
		
		setLoading(true)
		if(role === 'Physician'){
			setUserIsClinician(true);
		}
		var retrievedPatients=[]

		if(showInactive)
		{
				await API.get(`/patient_usage_stats/?ordering=first_name&limit=10&detail=true&exclude_visits_without_coaching_plans=true`)
			.then(response => {
				retrievedPatients.push(...response.data.results)

				if(response.data.next===null)
				{
					setPatients(retrievedPatients);
					setLoading(false);

				}
				else{
					batchPatients(response.data.next,retrievedPatients)
				}

	
				
			})
			.catch(
				error => {
					console.error(error)
					setPatients([]);
					setErrors(error)
					setLoading(false);

	
				}
				);
		}
		else
		{
			await API.get(`/patient_usage_stats/?ordering=first_name&limit=10&&active=true&detail=true&exclude_visits_without_coaching_plans=true`)
			.then(response => {
				console.log(response.data.results);
				retrievedPatients.push(...response.data.results)

				if(response.data.next===null)
				{
					setPatients(retrievedPatients);
					setActiveCount(retrievedPatients.length);


				}
				else{
					batchPatients(response.data.next,retrievedPatients,true)
				}	
				
	
			})
			.catch(
				error => {
					console.error(error)
					setPatients([]);
					setErrors(error)
					setLoading(false);

	
				}
				);
			await API.get('/patient_alerts/').then(response => {
					var data= response.data
					console.log(data)
					setIssues(data)
					setAttentionCount(data.accounts_requiring_attention!==undefined?data.accounts_requiring_attention:0);
					setLoading(false)
				})
				.catch(
					error => {
						console.error(error)
						setAttentionCount(0)
						setErrors(error)
						setLoading(false);
	
		
					}
					);;
		}


	}

	useEffect(() => {
	}, [divStyle]);

	useEffect(() => {
		GetPatients();
	}, [showInactive]);

	useEffect(()=>{
		//setDivStyle({	width: width!==null?(width<window.screen.width? "fitContent":"90%"):("90%")})
	},[width])
	
	useEffect(() => {

		if(tableRef.current!==null)
		{
			
			setWidth(tableRef.current.offsetWidth)
			

		}
	}, [tableRef.current,loading]);

	
	

	

	useEffect(() => {
		if(filter)
		{
			var filtered=[]

			var f=filter.trim();
			f=f.toLowerCase();
            filtered=patients.filter(p=>{
				var name = p.first_name+" "+p.last_name;

                return name.toLowerCase().includes(f)
            })
            setFilteredPatients(filtered);

		}
		else
		{
			setFilteredPatients(patients)

		}
		
	}, [patients,filter]);

	const breadcrumbLinks = () => {


			return [
				{
					label: "Patients",
					url: "/"
				}
				
			]
		
		


	}
	
	let history = useHistory();

	API.interceptors.response.use(response => {
		return response;
	 }, error => {
	   if (error.response.status === 401) {
		const domain = window.location.origin.includes("localhost") ? "" : "karunalabs.com";
	
		console.log(401)
		cookie.remove('token');
			cookie.remove('csrftoken', { path: '/' });
			cookie.remove('role', { path: '/' });
			cookie.remove('user', { path: '/' });
		localStorage.clear();
		history.push('/login');
	   }
	   return error;
	 });

	 const RenderState=(state,week,synch,trueWeek=null, hasPB)=>{
		if(!synch &&hasPB &&state!=="EXPIRED")
		{
			if(state==="INACTIVE")
			{
				return(<div className="  pl-1.5 pr-2 py-0.5 bg-gray-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">
					<div className="w-2 h-2 relative">
						<div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-gray-500 rounded-full" />
					</div>
					<div className="text-center text-gray-700 text-xs font-medium font-['Inter'] leading-[18px]">Inactive</div>
				</div>)
			}
			else{
			return (
				
				<div className="has-tooltip relative  pl-1.5 pr-2 py-0.5 bg-error-100 rounded-2xl justify-center items-center gap-1.5 inline-flex">
					<span className='tooltip rounded shadow-lg p-1 ml-[36rem] [@media(max-width:1200px)]:ml-[30rem]   -mt-16  absolute'>Coaching Plan has fallen behind the coaching program.<br/> Advance the plan to be 1 week ahead of the number of coaching sessions.</span>

					<div className="w-2 h-2 relative">
						<div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-error-500 rounded-full" />
					</div>
					<div className="text-center text-error-700 text-xs font-medium font-['Inter'] leading-[18px]">Out of Sync</div>
				</div>
			)
		}
		}
		else{
		switch(state)
		{
			case "ACTIVE":
				return (
					<div className="  pl-1.5 pr-2 py-0.5 bg-emerald-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">
						<div className="w-2 h-2 relative">
							<div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-emerald-500 rounded-full" />
						</div>
						<div className="text-center text-emerald-700 text-xs font-medium font-['Inter'] leading-[18px]">Active (Week {week!==null?week.week:(trueWeek!==null?trueWeek:"not found")})</div>
					</div>
				)
				break;
			case "DEFAULT":
				return (
					<div className="has-tooltip relative pl-1.5 pr-2 py-0.5 bg-amber-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">
						<span className='tooltip rounded shadow-lg p-1  ml-[36rem] [@media(max-width:1200px)]:ml-[30rem] -mt-16  absolute'>Default Week 0 plan is still assigned.<br/> Create the Coaching Plan and advance to Week 1 after the patient\’s Set-Up.</span>

						<div className="w-2 h-2 relative">
							<div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-amber-500 rounded-full" />
						</div>
						<div className="text-center text-amber-700 text-xs font-medium font-['Inter'] leading-[18px]">Not Started</div>
					</div>
				)
				break;
				case "PENDING":
					return (
						<div className="   pl-1.5 pr-2 py-0.5 bg-amber-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">

							<div className="w-2 h-2 relative">
								<div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-amber-500 rounded-full" />
							</div>
							<div className="text-center text-amber-700 text-xs font-medium font-['Inter'] leading-[18px]">Not Started</div>
						</div>
					)
					break;
					case "LAPSED":
						return (
							<div className="  pl-1.5 pr-2 py-0.5 bg-amber-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">
								<div className="w-2 h-2 relative">
									<div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-amber-500 rounded-full" />
								</div>
								<div className="text-center text-amber-700 text-xs font-medium font-['Inter'] leading-[18px]">Paused</div>
							</div>
						)
						break;
						case "EXPIRED":

							return (
								<div className=" has-tooltip relative pl-1.5 pr-2 py-0.5 bg-amber-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">
									<span className='tooltip rounded shadow-lg p-1 ml-[40rem] [@media(max-width:1200px)]:ml-[24rem] -mt-16  absolute'>Coaching Plan has been in its final week for too long.<br/> End the plan if the patient has completed the program, or add more weeks to the plan.</span>

									<div className="w-2 h-2 relative">
										<div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-amber-500 rounded-full" />
									</div>
									<div className="text-center text-amber-700 text-xs font-medium font-['Inter'] leading-[18px]">Not Ended</div>
								</div>
							)
							break;

			case "INACTIVE":
				return(<div className="  pl-1.5 pr-2 py-0.5 bg-gray-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">
						<div className="w-2 h-2 relative">
							<div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-gray-500 rounded-full" />
						</div>
						<div className="text-center text-gray-700 text-xs font-medium font-['Inter'] leading-[18px]">Inactive</div>
					</div>)

				break;
			default:
				break;
		}
	}

	 }

	 const LoadPage=(id)=>{

		if(id){
	
		history.push(`${id && `/patient/${id}/`}`);
		}
	
	}

	 const RenderTable = () => {
		   var t= []
		   for(var i=0;i<filteredpatients.length;i++){
			   let id =(filteredpatients[i].id)
			   let week= (filteredpatients[i].plan_stats!==undefined&&filteredpatients[i].plan_stats[Object.keys(filteredpatients[i].plan_stats).pop()].plan_weeks!==undefined?{week: Object.keys(filteredpatients[i].plan_stats[Object.keys(filteredpatients[i].plan_stats).pop()].plan_weeks).pop(), weekData:filteredpatients[i].plan_stats[Object.keys(filteredpatients[i].plan_stats).pop()].plan_weeks[Object.keys(filteredpatients[i].plan_stats[Object.keys(filteredpatients[i].plan_stats).pop()].plan_weeks).pop()]}:null)
			 	
			   let trueWeek=   filteredpatients[i].coaching_plan_week!==undefined?filteredpatients[i].coaching_plan_week:null
			   let name = filteredpatients[i].first_name.length<15? (filteredpatients[i].first_name+" "+filteredpatients[i].last_name):(filteredpatients[i].first_name+" "+filteredpatients[i].last_name[0])
			   let synch =filteredpatients[i].practice_better_synced;
			   let hasPB = filteredpatients[i].practice_better_account;
			   let track= filteredpatients[i].coaching_plan_state==="ACTIVE" &&filteredpatients[i].plan_stats!==undefined?filteredpatients[i].plan_stats[Object.keys(filteredpatients[i].plan_stats).pop()]:null
			 
				t.push(<React.Fragment><tr className='whitespace-nowrap border-y-2	 even:bg-white odd:bg-white hover:bg-gray-100 hover:cursor-pointer' onClick={()=>{LoadPage(id)}} >
		  { hasPB?<td className="whitespace-nowrap relative   gap-x-2	border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-900 font-['Inter'] "><div class='has-tooltip float-left mt-2  pr-2 whitespace-nowrap 	'><span class='tooltip rounded shadow-lg p-1 ml-4 -mt-6'>Has link to Practice Better</span><img className='h-4 w-4 hs-tooltip ' src ={"/icons/link-green.svg"} ></img></div>{" "+name}<br/><span className='text-gray-500'>{" #"+id}</span></td>:<td className="whitespace-nowrap relative   gap-x-2	border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-900 font-['Inter'] "><div class='has-tooltip float-left mt-2  pr-2 whitespace-nowrap 	'><span class='tooltip rounded shadow-lg p-1 ml-4 -mt-6'>No link to Practice Better</span><img className='h-4 w-4 hs-tooltip ' src ={"/icons/link-broken-red.svg"} ></img></div>{" "+name}<br/><span className='text-gray-500'>{" #"+id}</span></td>}
		   <td className="whitespace-nowrap border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{RenderState(filteredpatients[i].coaching_plan_state,week, synch,trueWeek,hasPB)}</td>
		   <td className="whitespace-nowrap border-x-0	 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{filteredpatients[i].total_stats!==undefined?filteredpatients[i].total_stats.unique_days:"\u2014"}</td>
		   <td className="whitespace-nowrap border-x-0	 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{filteredpatients[i].total_stats!==undefined?filteredpatients[i].total_stats.time_spent > 0 ? `${(filteredpatients[i].total_stats.time_spent)<60?moment.duration(filteredpatients[i].total_stats.time_spent*1000).seconds()+ " s":""} ${moment.duration(filteredpatients[i].total_stats.time_spent*1000).hours() > 0 ? (moment.duration(filteredpatients[i].total_stats.time_spent*1000).hours() + " hrs ") : ""}${moment.duration(filteredpatients[i].total_stats.time_spent*1000).minutes()?moment.duration(filteredpatients[i].total_stats.time_spent*1000).minutes() +" m ":""}` : "0 s" : "\u2014"}</td>
		   <td className="whitespace-nowrap	border-x-0  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{week!==null&&week.weekData.unique_days!==undefined?week.weekData.unique_days:"\u2014"}</td>
		   <td className="whitespace-nowrap	border-x-0  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{week!==null&&week.weekData.time_spent!==undefined?week.weekData.time_spent > 0 ? `${(week.weekData.time_spent)<60?moment.duration(week.weekData.time_spent*1000).seconds()+ " s":""} ${moment.duration(week.weekData.time_spent*1000).hours() > 0 ? (moment.duration(week.weekData.time_spent*1000).hours() + " hrs ") : ""}${moment.duration(week.weekData.time_spent*1000).minutes()?moment.duration(week.weekData.time_spent*1000).minutes() +" m ":""}` : "0 s" : "\u2014"}</td>
		   <td className="whitespace-nowrap	 border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{week!==null&&week.weekData.personal_routine!==undefined?week.weekData.personal_routine.completions:"\u2014"}</td>
		   <td className="whitespace-nowrap	 border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">{week!==null&&week.weekData.bonus_activity!==undefined?week.weekData.bonus_activity.completions:"\u2014"}</td>

		 </tr></React.Fragment>)
		 }
		 return t;
	   
	   }

	 
	return (
		<Page errors={errors} headerObj={{trail:breadcrumbLinks()}} internal={true} >
      <Spacer headerObj={{trail:breadcrumbLinks()}} internal={true} />


  <div className="w-full h-full justify-center items-start inline-flex mt-8">
	{!loading&&filteredpatients!==null?
	
    <div class={" bg-white h-[90%]   rounded-lg shadow-lg border-2 border-gray-200 flex-col  items-end flex "+(filteredpatients.length>0?(width<window.innerWidth?"w-[90%]":"w-[90%]"):"w-[90%]")} >
	  <div className="w-full h-[10%] z-40 relative px-6 pt-5 pb-[19px] justify-start items-center gap-4 inline-flex">
        <div className="w-[50%] h-full  justify-start items-center gap-2 flex">
          <div className="text-gray-900 text-lg font-medium font-['Inter'] leading-7">Your Patients</div>
		   { activeCount>0&& <div className="mix-blend-multiply justify-start items-start flex">
            <div className="px-2 py-0.5 bg-slate-50 rounded-2xl justify-center items-center flex">
              <div className="text-center text-blue-800 text-xs font-medium font-['Inter'] leading-[18px]">{activeCount} active</div>
            </div>
          </div> } 
		  { attentionCount>0&&<div className="mix-blend-multiply  justify-start items-start flex">
            <div className=" has-tooltip z-50 px-2 py-0.5 bg-amber-50 rounded-2xl justify-center items-center flex">
			<span className='tooltip rounded shadow-lg p-1 ml-40 mt-24 absolute '>
				{
					issues.expired_plans>0?<React.Fragment>Not Ended Plans: {issues.expired_plans}<br/></React.Fragment>:""
				}
				{
					issues.lapsed_plans>0?<React.Fragment>Lapsed Plans: {issues.lapsed_plans}<br/></React.Fragment>:""
				}
				{
					issues.practice_better_missing_accounts>0?<React.Fragment>Missing Practice Better Account: {issues.practice_better_missing_accounts}<br/></React.Fragment>:""
				}
				{
					issues.unsynchronized_plans>0?<React.Fragment>Unsynchronized Plans: {issues.unsynchronized_plans}<br/></React.Fragment>:""
				}
				{
					issues.week_zero_plans>0?<React.Fragment>Not Started Plans: {issues.week_zero_plans}<br/></React.Fragment>:""
				}
			</span>

              <div className="text-center text-amber-700 text-xs  font-medium font-['Inter'] leading-[18px]">{attentionCount} require attention</div>
            </div>
          </div> }
        </div>
        <div className="grow shrink w-[100%] basis-0 h-full  items-center gap-1 flex  ">
          <div className="justify-center items-center flex">
		  <input
		  className='w-4 h-4 p-0.5 bg-slate-50 rounded border border-blue-600 justify-center items-center flex'
          type="checkbox"
          checked={showInactive}
          onChange={()=>{setShowInactive(!showInactive)}}
        />
          </div>
          <div className="text-slate-700  grow shrink text-sm font-['Inter'] w-[100%] leading-tight">Include Inactive Patients</div>
        </div>
        <div className="w-[30%] flex-col justify-start items-start inline-flex">
          <div className="self-stretch h-full flex-col justify-start items-start gap-1.5 flex">
            <div className="self-stretch h-full flex-col justify-start items-start gap-1.5 flex">
              <div className="self-stretch px-3.5 py-1.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 h-full justify-start items-center gap-2 flex">
                  <img className="w-6 h-6 relative" src="/icons/search.svg" />
                  <Input className="  outline-none border-0 focus:outline-none	 active:border-0 focus:border-0 focus:ring-0 	overflow-hidden	w-full grow shrink basis-0 h-6 text-gray-500 text-base font-normal font-['Inter'] leading-normal	" type="text" name="Search Patients" onChange={setFilter} required formErrors={errors} overrideText={"Search Patients"} value={filter}/>
                </div>
              </div>
            </div>
          </div>
        </div>
		
      </div>
	  {
		filteredpatients&&filteredpatients.length>0?
	  <div class="grid grid-col w-full whitespace-nowrap 	overflow-y-auto">
                <div class="	whitespace-nowrap	w-full">
                    <div class="whitespace-nowrap w-full	inline-block align-middle ">
                    <div class=" whitespace-nowrap w-full	">
                        
                        <table class="border-0 w-full table-auto" ref={tableRef} id="table">
                        <thead className='whitespace-nowrap w-full border-0 '>
                            <tr className='shadow-[inset_0_1px_0_#d3d3d3,inset_0_-1px_0_#d3d3d3] bg-gray-50 whitespace-nowrap sticky top-0 z-10 border-x-0	'>
                            <th scope="col" class="whitespace-nowrap border-x-0 sticky top-0 	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px] ">Name</th>
                            <th scope="col" class="whitespace-nowrap border-x-0 sticky top-0   px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Plan Status</th>
                            <th scope="col" class="whitespace-nowrap border-x-0 sticky top-0   px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Total Days Used</th>
                            <th scope="col" class="whitespace-nowrap border-x-0  sticky top-0  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Total Activity Time</th>
                            <th scope="col" class="whitespace-nowrap border-x-0 sticky top-0 	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Current Week<br/> Days Used</th>
                            <th scope="col" class="whitespace-nowrap border-x-0 sticky top-0   px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Current Week<br/> Activity Time</th>
                            <th scope="col" class="whitespace-nowrap border-x-0  sticky top-0 	  px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Current Week<br/> Routine Completions</th>
                            <th scope="col" class="whitespace-nowrap border-x-0	sticky top-0   px-6 py-3 text-start text-xs font-medium text-gray-500 font-['Inter'] leading-[18px]">Current Week<br/> Bonus Activities</th>
                            </tr>
                        </thead>
                        <tbody className='whitespace-nowrap	'>
                            <React.Fragment>
                        {
                            filteredpatients&&  RenderTable()
                        }
                        </React.Fragment>
                        </tbody>
                    
                        </table>
							
                        
                    </div>
                    </div>
                </div>
      </div>
	  :	<div className=" w-full h-full justify-center items-center inline-flex flex-col	">
		                        <p className="px-6 text-gray-900 text-lg font-medium font-['Inter']">No patients found</p>
		</div>

	}
    </div>
	:
	<div className=" w-full h-full justify-center items-center inline-flex flex-col	">
	<TailSpin	
				height="100"
				width="100"
				color='grey'
				ariaLabel='loading'
			  />
			  <br /> <br />
			  <div/>
		  <h4 className="font-['Inter']">Loading...</h4>
	</div>
	}
  </div> 

</Page>
		
	);
}

export default withRouter( Gallery);


