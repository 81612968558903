import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';





const Spacer = ({headerObj,internal}) => {
  const [trail, setTrail] = useState(null);
  const [body, setBody] = useState(null);


  useEffect(() => {
    if(headerObj!==null&headerObj!==undefined)
  {
    if(headerObj.trail!==undefined)
    {
      setTrail(headerObj.trail);
    }
    if(headerObj.body!==undefined)
    {
      setBody(headerObj.body);
    }
  
  }
     
   }, [headerObj])
  
  return (

    <React.Fragment>
    {
        headerObj!==null&&headerObj!==undefined&&internal?
        (
          
         
    <div id="spacer" className={internal?'z-10 pointer-events-auto  py-0 my-0 right-0 left-[calc(100%-calc(100%-196px))] top-0 ':'z-10 pointer-events-auto  right-0 left-0 top-0  '}>
      {trail? <span id ="innerspace" className={`w-full h-[3vh] max-[1200px]:h-[3vh]  float-start  bg-red-500  items-center py-0 my-0 inline-flex ${headerObj.body===undefined?"":""} `}>
        </span>:<span className='absolute' id="deadspacer"></span>
    }
    {
      body?<span id="innerspace" className={`w-full h-[14vh] max-[1200px]:h-[11vh] relative  bg-white my-0 py-0 float-up   inline-flex  inset-x-0 ${headerObj.body!==undefined?"shadow-md":""}`} >
     
     


     </span>:<span className='absolute' id="deadspacer"></span>
    }

    </div>
    )

          
          
        
    :
    <span className='absolute'></span>
    
    }

    </React.Fragment>
  )
}

export default Spacer