import React, { useState, useEffect } from 'react';
import Input from './Input';
import HeightInput from './HeightInput';
import RadioGroup from './RadioGroup';
import { useForm, useFormContext } from 'react-hook-form';
import DropdownInput from './DropdownInput';
import CustomDropDown from '../compositions/CustomDropDown';

const ProfileInput = ({setProfile, loadedProfile, formErrors }) => {
	const [height, setHeight] = useState(null);
	


	const [metric, setMetric] = useState(false);
	const [bio, setBio] = useState("");
	const [gender, setGender] = useState(null);
	const [language, SetLanguage] = useState(null);
	const [payer_type, setPayerType] = useState(null);


	useEffect(() => {
		console.log("loaded profile", loadedProfile)
		if(loadedProfile){
			setHeight(loadedProfile.height);
			setBio(loadedProfile.bio);
			setGender(loadedProfile.gender);
			SetLanguage(loadedProfile.language)
			setPayerType(loadedProfile.payer_type)

		}
	}, [loadedProfile])

	useEffect(() => {
		setProfile({
			height: height,
			gender: gender,
			language: language,
			payer_type: payer_type


		})
	}, [height, bio, gender,language,payer_type])

	const genderOptions = [
		{
			name: "Female",
			id: 0,
			value:0
		},
		{
			name: "Male",
			id: 1,
			value:1
		},
		{
			name: "Nonbinary",
			id: 2,
			value:2
		}
	];

	const FeetOptions = [
		{
			label: "1",
			id: 0,
		},
		{
			label: "2",
			id: 1
		},
		{
			label: "3",
			id: 2
		},
		{
			label: "4",
			id: 3,
		},
		{
			label: "5",
			id: 4
		},
		{
			label: "6",
			id: 5
		},
		{
			label: "7",
			id: 6
		},
	];

	const inchOptions = [
		{
			name: "1",
			id: 0,
		},
		{
			label: "2",
			id: 1
		},
		{
			label: "3",
			id: 2
		},
		{
			label: "4",
			id: 3,
		},
		{
			label: "5",
			id: 4
		},
		{
			label: "6",
			id: 5
		},
		{
			label: "7",
			id: 6,
		},
		{
			label: "8",
			id: 7
		},
		{
			label: "9",
			id: 8
		},
		{
			label: "10",
			id: 9,
		},
		{
			label: "11",
			id: 10
		}
	];

	const LanguageOptions = [
		{
			name: "English",
			id: 0,
			value:0
		},
		{
			name: "Spanish",
			id: 1,
			value:1
		}
	];

	const PayerOptions = [
		{
			name: "Test",
			value: -3,
			id:0
		},
		{
			name: "Demo",
			value: -2,
			id:1
		},
		{
			name: "Research",
			value: -1,
			id:2
		},
		
		{
			name: "Worker’s Compensation",
			value: 1,
			id:3
		},
		{
			name: "Private Pay",
			value: 0,
			id:4
		},
		{
			name: "Veteran’s Association",
			value: 2,
			id:5
		},
		{
			name: "Personal Injury",
			value: 3,
			id:6
		}
	];
	return (
		<React.Fragment>
			<HeightInput metric={metric} patientHeight={height} setPatientHeight={setHeight} initialHeight={loadedProfile && loadedProfile.height} formErrors={formErrors} />
				
            <div className="h-[70px]  w-full  flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                    <div className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight">Gender</div>
					{
					gender!==null&&<CustomDropDown defaultValue={gender} textcolor='text-gray-500' options={genderOptions} onchange={setGender} maxH={"max-h-32"}  fullwidth={true}/>
					}
                   
                </div>
            </div>
            <div className="h-[70px]  w-full  flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                    <div className="text-gray-700 text-sm font-medium font-['Inter'] leading-tight">Language</div>
				{
				language!==null&&<CustomDropDown defaultValue={language} textcolor='text-gray-500' options={LanguageOptions} onchange={SetLanguage} maxH={"max-h-32"}  fullwidth={true}/>

				}
				
               
		</div>
            </div>
            <div className="h-[70px] w-full flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch w-full h-[70px] flex-col justify-start items-start gap-1.5 flex">
                    <div className="text-gray-700 w-full text-sm font-medium font-['Inter'] leading-tight">Payer Type</div>
					
					{
					payer_type!==null&&<CustomDropDown defaultValue={payer_type} textcolor='text-gray-500' options={PayerOptions} onchange={setPayerType} maxH={"max-h-32"} posBottom={false} fullwidth={true}/>

					}
					
                   
                </div>
            </div>
			
		</React.Fragment>
	
	)

	
}

export default ProfileInput;