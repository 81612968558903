
import React from 'react'
import Divider from './Divider.tsx';
import EditCoachingPlanHead, { ViewState } from './EditCoachingPlanHead.tsx';
import BonusSection from './BonusSection.tsx';
import RoutineSection from './RoutineSection.tsx';

const CompletedCurrentWeekView = ({activityStats,selectedWeek, weekOptions, coachingPlan,changeWeek,StartNewPlan,  personalRoutine, name, routineScoreData}) => {
  return (
    <div className='w-[100%]  p-10 flex-col justify-center items-center gap-y-6 inline-flex '>
    <div id="head" className='w-full  justify-center items-center gap-x-3 inline-flex '>
    <EditCoachingPlanHead startNewPlan={StartNewPlan}  coachingPlan={coachingPlan} setSelectedWeek={changeWeek}  selectedWeek={selectedWeek} state={ViewState.Interrupted} weekOptions={weekOptions}  />

    </div>
    <div id="divider" className='w-full  justify-center items-center gap-x-3 inline-flex bg-green-100  '>
    <Divider label={"Assignments"}/>
</div>
<div id="divider" className='w-full  justify-center items-center gap-3 inline-flex  rounded-[10px] shadow border border-gray-200 '>
<RoutineSection activityStats={activityStats} key={routineScoreData}  setActivity={null} setDuration={null} state={ViewState.Interrupted} firstName={name} routineBadgeData={routineScoreData} selectedWeek={selectedWeek} personalRoutine={personalRoutine}/>
</div>
<div id="divider" className='w-full  justify-center items-center gap-3 inline-flex  rounded-[10px] shadow border border-gray-200 '>
    <BonusSection activityStats={activityStats} key={coachingPlan} setActivity={null} setDuration={null} state={ViewState.Interrupted} selectedWeek={selectedWeek} coachingplan={coachingPlan} />
</div>

</div>  )
}

export default CompletedCurrentWeekView