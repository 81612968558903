import React from 'react';


const FormErrors = ({ errors }) => {
    return(
        <React.Fragment>
            {console.log(errors!==null)}
            {errors!==null?(
        <div className="text-[25px] font-bold text-error-700 mt-[15px]">
            {
            (typeof errors === 'string' || errors instanceof String)? <React.Fragment>{errors.split('\n').map(i => {
                return <p>{i}</p>
            })}
            </React.Fragment>
            :errors && errors.errors.map((error, i) => <p>{error.message}</p> )
            }
        </div>
            ):<span className='absolute'></span>
        }
            </React.Fragment>
        
    )
}

export default FormErrors;