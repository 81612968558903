import React from 'react';
import { withRouter } from 'react-router';
import { labelToURL } from '../../utility/Utility';

const ButtonCard = ({ destination, history }) => {

	const goToDestination = () => {
		history.push(destination);
	}


	

	return (
		<div className="button card" onClick={goToDestination}>
			<h5>Create new</h5>
		</div>
	)

	
}

export default withRouter(ButtonCard);