import React from 'react';
import { Link, withRouter } from 'react-router-dom';


const Breadcrumbs = ({ links, history }) => {
  
  const isCurrent = (i) => {
    return i == links.length - 1;
  }
  
  const goBack = () => {
    history.goBack();
  }

  

  return(
    <div className="justify-start items-start gap-3 inline-flex   shadow-none	">
  
      {links.map((link, i) => (
       <Crumb key={i} link={link} current={isCurrent(i)} links={links}/>
       
      ))}
    </div>
  )
}

const Crumb = ({ link, current, links=null }) => {
  return(

    <div className="justify-start items-start gap-3 inline-flex">
      <Link className={"text-brand-500 hover:underline"}  to={link.url}>{link.label}</Link>
      {!current  && <span className='text-gray-500'>/</span>}
      {links!==null&links.length===1  ? <span className='text-gray-500'>/</span>:null}

    </div>
  )
}

export default withRouter(Breadcrumbs);
