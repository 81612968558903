import React from 'react';
import { withRouter } from 'react-router';
import { arrivedAtPatient, arrivedAtProvider } from '../../services/VetkitServices';
import { destination, isPending } from '../../utility/VetkitUtility';
import ButtonConfirmation from '../cards/ButtonConfirmation';

const PatientReceivedButton = ({ vetkit, onChange, history }) => {

  const patientReceived = () => {
    arrivedAtPatient(vetkit.vetkit_assignment).then(response => {
      onChange && onChange();
    });
  }


  return(
    <React.Fragment>
      {vetkit && isPending(vetkit) && destination(vetkit) == "Patient" && 
        <ButtonConfirmation label="Arrived at patient" action={patientReceived} message="This VET Kit has been shipped to a patient. Has it arrived?" />
      }      
    </React.Fragment>
    
  )
}

export default withRouter(PatientReceivedButton);