import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import API from '../services/API';
import DropdownInput from '../components/input/DropdownInput';
import moment from 'moment';
import AssignVetkitButton from '../components/buttons/AssignVetkitButton';

const AssignVetkit = ({ history }) => { 
	const [vetkitId, setVetkitId] = useState(useParams().vetkitId);
	const [patientId, setPatientId] = useState(useParams().patientId);
	const [vetkitData, setVetkitData] = useState(null);
	const [patients, setPatients] = useState(null);
	const [vetkits, setVetkits] = useState(null);
	const [patientsAvailable, setPatientsAvailable] = useState(false);//hack until filter is put in place 
	

	useEffect(() => {
		console.log(vetkitId)
		if (vetkitId) {
			getVetkitData();	

		}

		getPatients();
		getVetKits();

	}, []);




	const getVetkitData = () => {
		API.get(`/vetkits/${vetkitId}/`)
			.then(response => {
				console.log(response);
				setVetkitData(response.data);
			})
			.catch(error => console.error(error));
	}

	const getPatients = () => {
		API.get(`/patients/`).then(response => {//how do we filter patients that have no vetkit?
			console.log(response.data);
			let availablePatients = [];
			response.data.results.map(patient => {
				if(!patient.vetkit){
					availablePatients.push(patient);
				}

			})

			if(availablePatients.length > 0){
				setPatients(availablePatients);
			}

		})
	}

	const getVetKits = () => {
		API.get(`/vetkits/`).then(response => {
			console.log("VETKITS", response.data.results)
			if(response.data.results.length > 0){
				setVetkits(response.data.results.filter(vetkit => vetkit.is_assignable));
			}

		}).catch(error => console.log(error));
	}

	const assignVetKit = () => {
		const data = JSON.stringify({
			patient: parseInt(patientId),
			vetkit: vetkitId,
			date_due: moment().add(12, 'weeks').format("YYYY-MM-DD"),
			delivery_method: 1
		});

		console.log("DATA",data)

		API.post('/vetkitassignments/', data).then(response => {
			console.log(response.data);
			goToPatient();
		}).catch(error => console.log("ERROR", error));
	}

	const goToPatient = () => {
		history.push(`/patient/${patientId}`);
	}

	const goToCreatePatient = () => {
		history.push('/create-patient/');
	}


	const getPatientOptions = () => {
		if(!patients){
			return [];
		}

		let options = [];
		patients && patients.map(patient => options.push({id: patient.id, label: `${patient.first_name} ${patient.last_name}`}))
		console.log(options)
		return options;
	}

	const getVetKitOptions = () => {
		let options = [];
		vetkits && vetkits.map(vetkit => {
			vetkit.is_assignable && options.push({id: vetkit.id, label: vetkit.name});
		})

		console.log(options);
		return options;
	}

	const renderVetkitDropdown = () => {
		if (vetkits) {
			return <DropdownInput name="VET Kit" options={getVetKitOptions()} value={vetkitId} onChange={setVetkitId} />
		}
		else {
			return <div><br /><p><b>NOTE: </b>No VET Kits available. <a href="mailto:info@karunalabs.com">Contact Karuna for ordering info.</a></p></div>
		}
	}

	const renderPatientDropdown = () => {
		if(patients){
			return <DropdownInput name="Patient" options={getPatientOptions()} value={patientId} onChange={setPatientId} />
		}
		else {
			return <div><br/><h5>No patients available.</h5><button onClick={goToCreatePatient}>Create a new patient</button></div>
		}
	}

    return (
			<Page title={`Assign ${vetkitData ? vetkitData.name : " a VET Kit"}`} showMenu>
			{<div>
				{/* {vetkits && <DropdownInput name="VET Kit" options={getVetKitOptions()} value={vetkitId} onChange={setVetkitId} />} */}
				{renderPatientDropdown()}
				{renderVetkitDropdown()}
				<AssignVetkitButton vetkitId={vetkitId} patientId={patientId} />
			</div>}
		</Page>
     
    );

}

export default withRouter(AssignVetkit);
