import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import DropdownInput from '../components/input/DropdownInput';
import Page from '../components/Page';
import API from '../services/API';
import { getTimezone } from '../utility/UserUtility';
import cookie from 'react-cookies';

const UserProfile = ({ history }) => { 

	const [name, setName] = useState(null);
	const [bio, setBio] = useState(null);
	const [email, setEmail] = useState(null);
	const [username, setUsername] = useState(null);
	const [timezone, setTimezone] = useState(getTimezone())
	const [emailSent, setEmailSent] = useState(false);


	useEffect(() => {

		API.get('user/').then(response => {
			console.log(response)
			setName(`${response.data.first_name} ${response.data.last_name}`);
			// setBio(response.data.profile.bio);
			setEmail(response.data.email);
			// setUsername(response.data.username);
		});
	}, [])

	useEffect(() => {
		timezone && cookie.save("timezone", timezone);

	}, [timezone])

		const goToEdit = () => {
			history.push('/user/edit/')
		}

		const goToChangePassword = () => {
			history.push('/password/change/')

		}

	const timezoneOptions = [
		{ id: "America/Los_Angeles", label: `Pacific` },
		{ id: "America/New_York", label: "Eastern" },
		{ id: "America/Denver", label: "Mountain" },
		{ id: "America/Chicago", label: "Central"}
	];

    return (
		<Page title={name} displayMessage={emailSent?"password reset email sent":undefined} crumbsOverride={true} showMenu>
			<div className="section">

					<p>Email: {email}</p>

					<button onClick={goToEdit}>Edit data</button><br />
					<button onClick={goToChangePassword}>Change password</button>
					
			</div>
		
		</Page>
     
    );

}

export default withRouter(UserProfile);
