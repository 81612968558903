import React from 'react';
import { withRouter } from 'react-router';
import { labelToURL } from '../../utility/Utility';
import VideoThumbnail from 'react-video-thumbnail';

const VideoCard = ({ video, index, series, history }) => {

	const videoUrl = '/video/' + labelToURL(series.title) + "/" + (index + 1) + "-" + labelToURL(video.title)

	const loadVideo = () => {

		history.push(videoUrl);
	}

	return (
		<div className="media card" onClick={loadVideo}>
			<h2>{(index + 1)}</h2>
			<div>{video.title}</div>
		
		</div>
	)
}

export default withRouter(VideoCard);