import React, { useState } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import { labelToURL } from '../utility/Utility';
import VideoCard from '../components/cards/VideoCard';
import Breadcrumbs from '../components/Breadcrumbs';


const Video = ({}) => { 
	const seriesPath = useParams().series;
	const videoPath = useParams().id;
	const content = window.Content;

	const seriesIndex = content.videoSeries.findIndex((series) => labelToURL(series.title) == seriesPath);
	const videoIndex = content.videoSeries[seriesIndex].videos.findIndex((video) => videoPath.includes(labelToURL(video.title)));
	

	const videoTitle = content.videoSeries[seriesIndex].videos[videoIndex].title;
	const seriesTitle = content.videoSeries[seriesIndex].title;
	console.log(videoPath)
	console.log(seriesPath)

	const getNextVideoIndex = () => {
		if(videoIndex < content.videoSeries[seriesIndex].videos.length - 1){
			return videoIndex + 1;
		}
	
	}

	const getNextSeriesIndex = () => {
		
		if (seriesIndex < content.videoSeries.length) {
			return seriesIndex + 1;
		}
	}

	const getPreviousVideoIndex = () => {
		if (videoIndex > 0) {
			return videoIndex - 1;
		}
		else if (seriesIndex > 0) {
			return 0;
		}
	}


    return (
		<Page title={videoTitle} showMenu>
				<Breadcrumbs links={[
					{
						label: "Media",
						url: "/media"
					},
					{
						label: videoTitle,
						url: window.location.url
					}
				]} />
			<h5>{"Series: " + seriesTitle}</h5>
			<div className="video">
				<video controls autoPlay>
					<source src={'/video/' + seriesPath + '/' + videoPath + '.mp4'} type="video/mp4" />
				</video>
			</div>
			<div className="video-navigation">
				{getNextVideoIndex() != null &&
					<div>
						<h5>Next video</h5>
						<VideoCard video={content.videoSeries[seriesIndex].videos[getNextVideoIndex()]} index={getNextVideoIndex()} series={content.videoSeries[seriesIndex]} />

					</div>}
				{getPreviousVideoIndex() != null && 
				<div>
					<h5>Previous video</h5>
					<VideoCard video={content.videoSeries[seriesIndex].videos[getPreviousVideoIndex()]} index={getPreviousVideoIndex()} series={content.videoSeries[seriesIndex]} />
					
				</div>}
				
			</div>
			{/* <Link to={'/video/' + getNe} */}
		</Page>
     
    );
  
  
}

export default withRouter(Video);
