import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import history from './history'

import cookie from 'react-cookies';
import Login from './pages/Login';
import Gallery from './pages/Gallery';
import PropTypes from 'prop-types';
import Video from './pages/Video';
import EditPatient from './pages/EditPatient';
import  EditCoachingPlan  from './pages/EditCoachingPlan';
import PatientActivityPage from './pages/PatientActivityPage';
import PatientReviewReportPage from './pages/PatientReviewReportPage';
import PatientProfileRedirect from './pages/PatientProfileRedirect';
import BadgePage from './pages/BadgePage';
import PatientOutcome from './pages/PatientOutcome';
import Provisioning from './pages/Provisioning';
import ResetPassword from './pages/ResetPassword';
import UserProfile from './pages/UserProfile';
import UserSettings from './pages/UserSettings';
import EditUser from './pages/EditUser';
import Patients from './pages/Patients';
import AnalyticsDashboard from './pages/AnalyticsDashboard';
import Media from './pages/Media';
import Clinicians from './pages/Clinicians';
import EditClinician from './pages/EditClinician';
import ClinicianProfile from './pages/ClinicianProfile';
import ResetPasswordConfirm from './pages/ResetPasswordConfirm';
import VetkitProfile from './pages/VetkitProfile';
import AssignVetkit from './pages/AssignVetkit';
import VisitProfile from './pages/VisitProfile';
import UnityVisitSummaryRedirect from './pages/UnityVisitSummaryRedirect';
import RIBSVisitSummaryRedirect from './pages/RIBSVisitSummaryRedirect';
import RIBSVisitProfile from './pages/RIBSVisitProfile';
import DayVisits from './pages/DayVisits';
import SessionPage from './pages/SessionPage';
import PatientOutcomeGenerationPage from './pages/PatientOutcomeGenerationPage';
import PatientOutcomeVerificationPage from './pages/PatientOutcomeVerificationPage';
import ChangePassword from './pages/ChangePassword';
import SetPassword from './pages/SetPassword';
import ActivityPage from './pages/ActivityPage';
import Maintenance from './pages/Maintenance';
import PatientOutcomeVerifcation from './pages/PatientOutcomeVerifcation';
import Downloader from './pages/Downloader';
import DownloadZip from './pages/DownloadZip';
import API from './services/API';
import ValidateEmail from './pages/ValidateEmail';
import ReportReviewPage from './pages/ReportReviewPage';
import ExternalReportsPage from './pages/ExternalReportsPage';

const App = ({ }) => {
	const [loggedIn, setLoggedIn] = useState(cookie.load('token'));
	useEffect(() => {
		console.log('Token: ' + cookie.load('token'))
		if (!loggedIn && cookie.load('token')) {
			console.log("setting logged in cause token is " + cookie.load('token'))
			setLoggedIn(true);
		}
		else if (loggedIn && !cookie.load('token')) {
			console.log("setting logged out");
			setLoggedIn(false);
		}
		else if (!loggedIn && !cookie.load('token')) {
			console.log("logged out");
			setLoggedIn(false);
		}

	}, [loggedIn]);


	const ProtectedRoute = ({ component: Component, ...rest }) => {
		return (
			<Route
				{...rest}
				render={(props) =>
					(loggedIn ?
						<Component {...props} /> :
						<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
					)}
			/>
		)
	}

	const AuthenticatedRoute = ({ component: Component, ...rest }) => {
		return (
			<Route
				{...rest}
				render={(props) =>
					(!loggedIn ?
						<Component {...props} /> :
						<Redirect to={{ pathname: '/' }} />
					)}
			/>
		)
	}

	API.interceptors.response.use(response => {
		return response;
	 }, error => {
		console.log(error)
		if(error.response===undefined){
			console.log("401!!")
		cookie.remove('token', { path: '/' });
		cookie.remove('csrftoken', { path: '/' });
		cookie.remove('role', { path: '/' });
		cookie.remove('user', { path: '/' });
		localStorage.clear();
		if ( !document.URL.includes("login") ) {
			history.push('/login');
		}
		return error;
		}
		else{
		if(error.response.status!==undefined){
	   if (error.response.status === 401) {
		console.log("401!!")
		cookie.remove('token', { path: '/' });
		cookie.remove('csrftoken', { path: '/' });
		cookie.remove('role', { path: '/' });
		cookie.remove('user', { path: '/' });
		localStorage.clear();
		if ( !document.URL.includes("login") ) {
			history.push('/login');
		}
	   }
	   if (error.response.status === 503) {
		history.push('/maintenance');

	   }
	}
}
	   return error;
	 });


	ProtectedRoute.propTypes = {
		component: PropTypes.any,
		location: PropTypes.any,
	};

	return (
		<BrowserRouter forceRefresh={true} history={history} >
			<Switch>
				<Route exact path='/login' component={Login} />
				<Route exact path='/maintenance' component={Maintenance} />

				<ProtectedRoute path='/' exact component={Gallery} />
				<ProtectedRoute exact path='/resources' exact component={Gallery} />
				<ProtectedRoute exact path='/video/:series/:id' exact component={Video} />
				<ProtectedRoute exact path='/patient/:id' exact component={PatientProfileRedirect} />
				<ProtectedRoute exact path='/patient/:id/edit' exact component={EditPatient} />
				<ProtectedRoute exact path='/patient/:id/coaching-plan' exact component={EditCoachingPlan} />
				<ProtectedRoute exact path='/patient/:id/badges' exact component={BadgePage} />
				<ProtectedRoute exact path='/patient/:id/activity' exact component={PatientActivityPage} />
				<ProtectedRoute exact path='/patient/:id/reports' exact component={PatientReviewReportPage} />


				<ProtectedRoute exact path='/physician/:id/' exact component={ClinicianProfile} />
				<ProtectedRoute exact path='/clinician/:id/edit' exact component={EditClinician} />
				<ProtectedRoute exact path='/create-patient' exact component={EditPatient} />

				<ProtectedRoute exact path='/create-clinician' exact component={EditClinician} />
				<ProtectedRoute exact path='/provisioning' exact component={Provisioning} />
				<ProtectedRoute exact path='/user' exact component={UserSettings} />
				<ProtectedRoute exact path='/user/edit' exact component={EditUser} />
				<ProtectedRoute exact path='/patients' exact component={Patients} />
				<ProtectedRoute exact path='/clinicians' exact component={Clinicians} />
				<ProtectedRoute exact path='/analytics' exact component={AnalyticsDashboard} />
				<ProtectedRoute exact path='/media' exact component={Media} />
				<ProtectedRoute exact path='/vetkit/:id/' exact component={VetkitProfile} />
				<ProtectedRoute exact path='/vetkit/:vetkitId/assign' exact component={AssignVetkit} />
				<ProtectedRoute exact path='/patient/:patientId/assign-vetkit' exact component={AssignVetkit} />
				<ProtectedRoute exact path='/visit/:visitId' exact component={VisitProfile} />
				<ProtectedRoute exact path='/visit/:visitId/ribs' exact component={RIBSVisitProfile} />
				<ProtectedRoute exact path='/patient/:patientId/visit/:visitId' exact component={VisitProfile} />
				<ProtectedRoute exact path='/day/:dayName' exact component={DayVisits} />
				<ProtectedRoute exact path='/patient/:patientId/day/:dayName' exact component={SessionPage} />
				<ProtectedRoute exact path='/patient/:id/outcomes' exact component={PatientOutcomeGenerationPage} />
				<ProtectedRoute exact path='/outcomes/:outcomeId' exact component={PatientOutcomeVerificationPage} />
				<ProtectedRoute exact path ='/reports' exact component={ReportReviewPage}/>
				<ProtectedRoute exact path ='/reportsExternal' exact component={ExternalReportsPage}/>

				<ProtectedRoute exact path='/activity/:activityName' exact component={ActivityPage} />
				<ProtectedRoute exact path='/patient/:patientId/activity/:activityName' exact component={ActivityPage} />


				<Route exact path='/visit/:visitId/redirect/:token' exact component={UnityVisitSummaryRedirect} />
				<Route exact path='/visit/:visitId/redirect/ribs/:ribsServer/:ribsToken' exact component={RIBSVisitSummaryRedirect} />
				<Route exact path='/ribs/:ribsServer/:ribsToken' exact component={RIBSVisitSummaryRedirect} />
				
				<Route exact path='/password/reset/confirm/:uid/:token' exact component={SetPassword} />

				<Route exact path='/account-confirm-email/:key/' exact component={ValidateEmail} />
				<Route exact path='/report/:key/' exact component={Downloader} />
				<Route exact path='/reports/:key/' exact component={DownloadZip} />

				<Route exact path='/password/reset' exact component={ResetPassword} />


				<ProtectedRoute exact path='/password/change/' exact component={ChangePassword} />

			</Switch>
		</BrowserRouter>
	);
}



export default App;
