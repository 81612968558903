import React, { useEffect, useState } from 'react';
import API from '../services/API';
const StickyFooter = ({internal}) => {
    const [version, setVersion] = useState(null);
    useEffect(() => {
        getVersion();
      }, [])

      const getVersion = () => {
        API.get('version/').then(response => {
          setVersion(response.data.version);
        }).catch(error => console.log(error));
      }

  return (
    <footer className={internal?"bg-brand-100 relative inset-x-0  p-2   justify-center items-center inline-flex mt-[12px] w-full" :"bg-brand-100 absolute inset-x-0 bottom-0 p-2 justify-center items-center inline-flex "}> 
		<div className="text-Gray-500 text-xs font-normal  leading-none">© Karuna Labs 2024</div>
        <div className="grow shrink basis-0 text-right text-Gray-500 text-xs font-normal leading-none">API version {version}</div>
  </footer> 
  )
}

export default StickyFooter