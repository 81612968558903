export const isEmail = ( value ) => {
    if(value===null){return false}
    let reg = (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    return reg.test(value.toLowerCase());
}

export const minLength = ( value, min ) => {
    return value.length >= min;
}

export const maxLength = ( value, max ) => {
    return value.length <= max;
}