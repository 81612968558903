import React from 'react';
import { withRouter } from 'react-router';
import { sendToKaruna } from '../../services/VetkitServices';
import { canReturnToKaruna } from '../../utility/VetkitUtility';
import ButtonConfirmation from '../cards/ButtonConfirmation';

const ReturnToKarunaButton = ({ vetkit, onChange, history }) => {

  const returnToKaruna = () => {
    sendToKaruna(vetkit.id).then(response => {
      onChange && onChange();
    });
  }


  return(
    <React.Fragment>
      {vetkit && canReturnToKaruna(vetkit) && <ButtonConfirmation label="Return to Karuna" action={returnToKaruna} message="Have you returned this kit to Karuna?" />}    
    </React.Fragment>
    
  )
}

export default withRouter(ReturnToKarunaButton);