import React, { useRef } from 'react';
import { useParams } from 'react-router';
import { useState } from 'react';
import Page from '../components/Page';
import { useEffect } from 'react';
import API from '../services/API';
import { getDuration } from '../utility/Visit';
import { getDurationComplete } from '../utility/Visit';

import RomsGrid from '../components/compositions/RomsGrid';
import moment from 'moment-timezone';
import Exercise from '../components/Exercise';
import PersonCard from '../components/cards/PersonCard';
import Breadcrumbs from '../components/Breadcrumbs';
import html2PDF from 'html2pdf.js';
import { getTimezone } from '../utility/UserUtility';
import ListOfCoachingPlanList from '../components/compositions/ListOfCoachingPlanList';
import PriorityProtocolList from '../components/compositions/PriorityProtocolList';
import RatingsList from '../components/compositions/RatingsList';

import NewBests from '../components/compositions/NewBests';

import { TailSpin	 } from  'react-loader-spinner'
import { parse } from 'date-fns';
import { element, object } from 'prop-types';

const DayVisits = ({  }) => {

  const content = useRef(null);

  const dayName = useParams().dayName;
  const patientId = useParams().patientId;
  
  const [roms, setRoms] = useState([]);

  const [duration, setDuration] = useState(-1);
  const [exerciseData, setExerciseData] = useState([]);
  const [dayCoachingPlanData, setCoachingPlan] = useState(null);
  const [badges, setBadges]= useState(null)
  const [patientData, setPatientData] = useState(null);
  const [clinicianData, setClinicianData] = useState(null);
  const timezone = getTimezone();
  

  const utcStartTime = moment(dayName).format('YYYY-MM-DD')+"T00:00:00Z"
  const utcEndTime = moment(dayName).add(1, 'd').format('YYYY-MM-DD')+"T00:00:00Z";
  const [hideForExport, setHideForExport] = useState(false);
	const [priorityProtocol ,SetPrioritProtocol ] = useState(null);
	const [priorityProgress ,SetPriorityProgress ] = useState(null);
	const [protocols, setProtocols] = useState(null);

  const [showActivity ,SetShowActivity ] = useState(false);
	const [showCoaching ,SetShowCoaching ] = useState(false);
	const [scorecard ,SetScorecard ] = useState(null);


  useEffect(() => {
    if(patientId){
      getProtocols();
    getExerciseData();
    getRoms();
    GetPriorityProtocols();
    GetPriorityProgress();
      getPatientData();
      getScorecard();
    }
 
    
  }, [])

  useEffect(() => {
   
    if(hideForExport)
    {setHideForExport(false)}
    
  }, [hideForExport])

 const GetPriorityProgress=()=>{
  API.get(`/personal_routine_progress/?patient=${patientId}&limit=5000`)
		.then(response => {
			SetPriorityProgress(response.data.results)
			
		})
		.catch(
			error => {
			console.error(error)
      SetPriorityProgress([])


			}

			);
 }

const RoutineHasProgress=(progress)=>{
  var sum=0
  progress.forEach(element => {
      sum+=(parseFloat(Object.values(element)[0])!==0?1:0)
  })
  return sum>0
}

const getProtocols= () => {
  API.get(`/protocols`).then(response => {
    var protocol ={}
    response.data.results.forEach(element => protocol[element.id]=element.display_name);
    setProtocols(protocol)

  }).catch(error => console.log(error));
  }

const getScorecard=()=>{
  if(scorecard===null){
  API.get(`/coaching_plan_track_instances/?patient=${patientId}`).then(response=>{
    var result = response.data.results;

    var current = -1
    var date=null
     result.forEach(e=>{
      if(e.end_date===undefined)
      {
        current=e.id;
        date = e.start_date
      }
    })
    if(current>-1){
      API.get(`/scorecards/?coaching_plan_track_instance=${current}`).then(response=>{

          var scoreboardData=response.data.results;
          SetScorecard(scoreboardData[0]);
      }).catch(
        error =>{
          console.log(error)
          SetScorecard(null);

        }
      )
    }
    else{
      SetScorecard(null);
    }
  


  })
  .catch(
    error =>{
      console.log(error)
      SetScorecard(null);
    }
  )
  }
}


  const GetPriorityProtocols =()=>{
		API.get(`/personal_routine_daily_progress/?patient=${patientId}&limit=5000`)
		.then(response => {
			if(response.data.results.length>0)
			{
          var id=-1;
          
          response.data.results.forEach(element=>{
            if( element!==null &&"date" in element)
            {
              if(moment(element.date).format("YYYY-MM-DD")===moment(dayName).format("YYYY-MM-DD")){
                if(id!==-1){
                  if(element.id>id &&(RoutineHasProgress(element.progress)))
                  {
                    console.log(element)
                    id=element.id
                    SetPrioritProtocol(element);
                  }
                }
                else{
                  if(element.id>id )
                  {
                    id=element.id
                    SetPrioritProtocol(element);
                  }
                }
              }

            }
        })
			}
			
		})
		.catch(
			error => {
			console.error(error)
			

			}

			);
	}

  const getPatientData = (id) => {
    var badges ={"activity":[],"coaching_plan_weekly": [], "personal_routine_weekly":[]}

    API.get(`/patients/${patientId}`).then(response => {
      setPatientData(response.data);
      getCoachingPlanData();
      var clinician ={first_name: response.data.physician_name.split(' ')[0], last_name: response.data.physician_name.split(' ')[1]}
      setClinicianData(clinician)      
        API.get(`/badges/?patient=${patientId}`).then(response => {
           
            response.data.forEach(e=>{
             
              if(parseInt( e.patient)===parseInt( patientId) )
              {
               badges.activity= badges.activity.concat(e.badges.activity)
               badges.coaching_plan_weekly= badges.coaching_plan_weekly.concat(e.badges.coaching_plan_weekly)
                badges.personal_routine_weekly=badges.personal_routine_weekly.concat(e.badges.personal_routine_weekly)
              }
            })
            setBadges(badges);
          }).catch(error => console.log(error))
        
       
				
      }).catch(error => console.log(error))
   
  }

  const emptyProgress=(prog)=>{
    var count=0;
    Object.keys(prog).forEach(function(key,index) {
      count+=prog[key]
  });

return count===0;
  }

  const getCoachingPlanData = () => {
    API.get(`/coaching_plan_daily_progress/?patient=${patientId}&date=${dayName}`).then(response => {
      if(response.data.results[0]!==undefined)
      {
        if(response.data.results.length===1)
        {
        setCoachingPlan([response.data.results[0]])
        }
        else{
          var weeks=[]
          var results=[]
          var allResults=response.data.results;
          let sorted = allResults.sort((a, b) => (a.id < b.id) ? 1 : -1);
          sorted.forEach(element => {
            if(!weeks.includes( element.plan_week))
            {
              weeks.push(element.plan_week)
              results.push(element);
            }
            else
            {
              results.forEach(e=>{
                if(element.plan_week===e.plan_week)
                {
                  if(emptyProgress( e.progress)){
                    e.progress=element.progress
                  }
                }
              })
            }
          });
          setCoachingPlan(results)
        }

      }
    }).catch(error => console.log(error))
  }

 

  const showBadges = () => {
    var badgeList={}
    if(badges!==null)
    {

      for (const [key, value] of Object.entries(badges)) 
      {
        if(value!==[] && value.length>0)
        {
          
            badgeList[key]=[]
           
          value.forEach(e =>{
           
           if(new moment(e.date_earned).isSame(new moment(dayName), 'day')){
             badgeList[key].push(e);
            }
          })
        }
      }
      if(Object.keys(badgeList).length>0)
      {
        const cpBadges = badgeList["coaching_plan"] && badgeList["coaching_plan"].map((element) => (
          <React.Fragment>
            {

            <h4>{camelCaseParser(element.id.split("_")[0]) +("week_earned" in element? " Week "+element.week_earned:""   )}  </h4>
         }
          </React.Fragment>
          
          
         ))
         const cpwBadges = badgeList["coaching_plan_weekly"] && badgeList["coaching_plan_weekly"].map((element) => (
 
          <React.Fragment>

            {
            <h4>{camelCaseParser(element.id.split("_")[0]) +" "+element.id.split("_")[1]+" "+badgeParse(element.id.split("_")[element.id.split("_").length-1])}  </h4>
          }
          </React.Fragment>
          
          
         ))
         const ppwBadges = badgeList["personal_routine_weekly"] && badgeList["personal_routine_weekly"].map((element) => (

          <React.Fragment>
    
            {
              
              <h4>{ "Personal Routine Week "+element.id.split("_")[1]+" "+badgeParse(element.id.split("_")[element.id.split("_").length-1]) }  </h4>
            }
          </React.Fragment>
          
          
           ))

         const ActBadges = badgeList["activity"] && badgeList["activity"].map((element) => (

          <React.Fragment>
            {

            <h4>{camelCaseParser(element.id.split("_")[1])+" "+badgeParse(element.id.split("_")[element.id.split("_").length-1])+ ("week_earned" in element? " Week "+element.week_earned:""   )       }  </h4>}
          </React.Fragment>

          
         ))
      return(
        <React.Fragment>
          {badgeList["activity"]&&badgeList["activity"].length>0 ?<h3>Activity Badges</h3>:""}
          {badgeList["activity"] &&badgeList["activity"].length>0  ?ActBadges:""}
          {badgeList["activity"] && badgeList["activity"].length>0 ?<React.Fragment><br/><br/></React.Fragment>:""}

          {badgeList["coaching_plan_weekly"] && badgeList["coaching_plan_weekly"].length>0 ?<React.Fragment><br/><br/></React.Fragment>:""}
          {badgeList["coaching_plan_weekly"] && badgeList["coaching_plan_weekly"].length>0 ?<h3>Coaching Plan Weekly Badges</h3>:""}
          {badgeList["coaching_plan_weekly"] && badgeList["coaching_plan_weekly"].length>0 ?cpwBadges:""}
          {badgeList["coaching_plan"] ?<React.Fragment><br/><br/></React.Fragment>:""}

          {badgeList["coaching_plan"] ?<h3>Coaching Plan Badges</h3>:""}
          {badgeList["coaching_plan"] ?cpBadges:""}
          {badgeList["personal_routine_weekly"]&&badgeList["personal_routine_weekly"].length>0 ?<h3>My Routine Weekly Plan Badges</h3>:""}
			  {badgeList["personal_routine_weekly"] ?ppwBadges:""}
	          {badgeList["personal_routine_weekly"] ?<React.Fragment><br/><br/></React.Fragment>:""}

        </React.Fragment>

      )

      }
    }
  
  }

 
  const getRoms = () => {
    API.get(`/roms/?date_created_client__gte=${utcStartTime}&date_created_client__lte=${utcEndTime}&patient=${patientId}&limit=5000`).then(response => {
      setRoms(response.data.results);
    }).catch(error => console.log(error));
  }

  const getExerciseData = () => {
    console.log("getting")
    console.log(`/exercises/?start_time__gte=${utcStartTime}&start_time__lte=${utcEndTime}&visit__patient=${patientId}&limit=5000`)
    API.get(`/exercises/?start_time__gte=${utcStartTime}&start_time__lte=${utcEndTime}&visit__patient=${patientId}&limit=5000`).then(response => {
      let tempDuration = duration;
      console.log(response);
      console.log(response.data.results);

      response.data.results.map(exercise => {
        let dur=0
        console.log(exercise.motion_time)
        if(exercise.motion_time!==0)
        {
          dur = getDuration(exercise);
        }
        else
        {

          dur = getDurationComplete(exercise);
        }
        tempDuration += dur;
      })
console.log(tempDuration)
      setDuration(tempDuration);      
      setExerciseData(exerciseData => [...exerciseData, ...response.data.results]);
    })
  }
  const breadcrumbLinks = () => {

      return [
        {
          label: "Patients",
          url: "/patients"
        },
        {
          label: patientData && `${patientData.first_name} ${patientData.last_name}`,
          url: `/patient/${patientData && patientData.id}`
        },
        {
          label: `Activity for ${moment(dayName).format("MMM Do")}`,
          url: `/patient/${patientId}/day/${dayName}`
        }
      ]
  }


  const generatePDF = () => {
    setHideForExport(true);
    try {
      window.scrollTo(0,0);
      const svgElements = Array.from(content.current.querySelectorAll('svg'));
      svgElements.forEach(s => {
        const bBox = s.getBBox();
        s.setAttribute("x", bBox.x);
        s.setAttribute("y", bBox.y);
        s.setAttribute("width", bBox.width);
        s.setAttribute("height", bBox.height);
      })

      var opt = {
       pagebreak: {mode: ['avoid-all']}
      };
      html2PDF().set(opt).from(content.current).save(`DAY_VIEW_${patientData.first_name}_${patientData.last_name}_${dayName}`);
    } catch (error) {
      console.log(error);
    }

  }

  const camelCaseParser=(text)=>{
		return text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
	}

  const badgeParse=(text)=>{
    if(text==="1x")
    {return "Bronze"}
    if(text==="2x")
    {return "Silver"}
    if(text==="3x")
    {return "Gold"}
    if(text==="5x")
    {return "Gold Legacy"}
    return ""

  }
  
  const GetWeeks=()=>{
    var weeks=[]
    priorityProgress.forEach(e=>{
      if(!weeks.includes(e.plan_week) && moment(e.end_time).format("YYYY-MM-DD") ===moment(dayName).format("YYYY-MM-DD")){
      weeks.push(e.plan_week)
      }
    })
      if(!weeks.includes(priorityProtocol.plan_week))
      {
      weeks.push(priorityProtocol.plan_week)
      }
  
    if(weeks.length>0)
    {
      return<h3> {"weeks active on this date: " +weeks.sort() }</h3>
    }
    else
    {
      return <h4>No Weeks Found</h4>
    }

  }

  return(
    <Page title={moment(dayName).format("MMM Do, YYYY")} showMenu>
      <Breadcrumbs links={breadcrumbLinks()} />
    
     




      {
       


        patientData && exerciseData.length>-1 && duration>0?
        <React.Fragment>
      <div ref={content}>
        {hideForExport?<h1>{moment(dayName).format("MMM Do, YYYY")}</h1>:""}

        <div className="series">
          {patientData && <PersonCard person={patientData} type="patient" />}
          {clinicianData && <PersonCard person={clinicianData} type="physician" noLoad={true} />}
        </div>

        <br />
        <h3>Total Duration</h3> 
        <p>{duration > 0 ? `The patient was active for ${moment.duration(duration).hours() > 0 ? (moment.duration(duration).hours() + " hours and ") : ""}${moment.duration(duration).minutes()} minutes.` : `There is no duration data for this day.`}</p>     
        <br />
       { !hideForExport? <React.Fragment>
        <h3>Ranges of Motion</h3>      
        {roms.length > 0 ? <RomsGrid roms={roms} /> : <p>The patient didn't complete any ROMs for this date</p>}
        <br /><br />

        <h2>Coaching Plan Info</h2>
        {showCoaching&& protocols&&  <PriorityProtocolList priorityProtocol={priorityProtocol} dayName={dayName} protocol={protocols}/>}
        {showCoaching&& priorityProgress&&protocols&&<RatingsList dayName={dayName} progress={priorityProgress} protocol={protocols}/>}
         {dayCoachingPlanData !==null ?showCoaching&&<ListOfCoachingPlanList coachingPlan={dayCoachingPlanData}  protocol={protocols}/>:showCoaching&&<h3>No Bonus activity data recorded</h3> && <br />&&<br />}
         { showCoaching&&showBadges()}
         </React.Fragment>:""
       }
         {!showCoaching&&exerciseData.length>0&&<br/>&&<br/>&& <button onClick={() => SetShowCoaching(true)}>Show Coaching Section</button>}
			{showCoaching &&exerciseData.length>0&& <br/>&&<br/>&&<button onClick={() => SetShowCoaching(false)}>Hide Coaching Section</button>}
      <br /><br />

         {<h2>Activities</h2>}
       {showActivity&& exerciseData && exerciseData.sort((a,b) => a.id - b.id).map((exercise, i) => (
          <Exercise key={exercise.id} exercise={exercise} hide={hideForExport} scorecard={scorecard} />
        ))}
            {!showActivity&&exerciseData.length>0&&<br/>&&<br/>&& <button onClick={() => SetShowActivity(true)}>Show Activity Section</button>}
			{showActivity &&exerciseData.length>0&& <br/>&&<br/>&&<button onClick={() => SetShowActivity(false)}>Hide Activity Section</button>}
      <br /><br />

      </div>
      {!hideForExport?<button onClick={generatePDF}>Export</button>:""}

      </React.Fragment>
      :
      <React.Fragment>
      <TailSpin	
			height="100"
			width="100"
			color='grey'
			ariaLabel='loading'
		  />
      	<br /> <br />
      <h4>Loading...</h4>
      </React.Fragment>
      }
      
    </Page>
  )
}

export default DayVisits;
