import React from 'react';
import { withRouter } from 'react-router';
import { arrivedAtProvider } from '../../services/VetkitServices';
import { destination, isPending } from '../../utility/VetkitUtility';
import ButtonConfirmation from '../cards/ButtonConfirmation';

const ProviderReceivedButton = ({ vetkit, onChange, history }) => {

  const providerReceived = () => {
    arrivedAtProvider(vetkit.id).then(response => {
      onChange && onChange();
    });
  }



  return(
    <React.Fragment>
      {vetkit && ((isPending(vetkit) && destination(vetkit) == "Provider") || (vetkit.status == "Maintenance" && !isPending(vetkit))) && 
        <ButtonConfirmation label="Arrived in office" action={providerReceived} message="This VET Kit has been shipped to your office. Has it arrived?" />
      }      
    </React.Fragment>
    
  )
}

export default withRouter(ProviderReceivedButton);