import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import API from '../../services/API';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend, BarChart, Bar } from 'recharts';
import moment from 'moment';
import RangePicker from './RangePicker';
import { getDurationComplete } from '../../utility/Visit';

const TimeSeriesGraph = ({ type, timeKey, limit, label, filter, dateRange,exerciseData=null }) => {

	const [rawData, setRawData] = useState([]);
	const [dateRangeLength, setDateRangeLength] = useState(30);
	const [dataset, setDataset] = useState(null);


	useEffect(() => {
		getData(type);
	}, [dateRangeLength, dateRange]);

	useEffect(() => {
		createDataset();
	}, [rawData, dateRangeLength, dateRange]);


	const getData = (category) => {
		if(exerciseData===null)
		{
		const filterQuery = filter && `&${filter.id}=${filter.value}`;
		API.get(`/${category}/?limit=${limit}${filterQuery}`)
			.then(response => {
				//(category, response);
				setRawData(response.data.results);
			})
			.catch(error => console.error(error));
		}
		else
		{
			setRawData(exerciseData);
		}
	}

	const createDataset = () => {
		let datasetHolder = [];
		let filteredData = false ? rawData.filter(data => data[filter.id] == filter.value) : [...rawData];
		let today = new Date();

		let length = dateRange ? moment().diff(dateRange.start, 'days') : dateRangeLength;
		let seriesEnd = dateRange ? moment().diff(dateRange.end, 'days') : 0;

		for (var i = length; i >= seriesEnd; i--) {
			let date = new Date();
			date.setDate(today.getDate() - i);
			let dataPerDate = filteredData.filter(data => new Date(data[timeKey]).getDate() == date.getDate()
				&& new Date(data[timeKey]).getMonth() == date.getMonth());


			let timeSpent=0
			/*if(dataPerDate[0] && dataPerDate[0].motion_time!=0)
			{
				console.log("motion_time")
				 timeSpent = dataPerDate.reduce((a, b) => a + (b.motion_time || 0), 0);
				 let dataPoint = {
					date: date.getMonth() + 1 + "/" + date.getDate(),
					value: Math.round(timeSpent / 30)
				}
				datasetHolder.push(dataPoint);

			}
			else
			{*/
				dataPerDate.forEach(element => {
					timeSpent+=getDurationComplete(element);
				});
				let dataPoint = {
					date: date.getMonth() + 1 + "/" + date.getDate(),
					value: ~~(timeSpent/60000)
				}
				datasetHolder.push(dataPoint);

			//}
			

		}

		if(datasetHolder.length > 0){
			setDataset(datasetHolder);
		}
		
	}

	const updateDateRange = (range) => {
		setDateRangeLength(range);
	}
	return (
		<div className="graph">



			{dataset && dataset.length>0 ?

				<React.Fragment>
					<h3>{label}</h3>

					{!dateRange && <RangePicker 
					onRangeUpdated={updateDateRange}
					defaultValue={30}
					ranges={[
						{
							"label": "One week", 
							"value":7
						  },
						  {
							"label": "Four weeks",
							"value": 30
						  },    
						  {
							"label": "Eight weeks",
							"value": 60
						  },
						  {
							"label": "Twelve weeks",
							"value": 90
						  },
						  {
							"label": "All time",
							"value": 365
						  }
					]}/>}
				

					<BarChart width={400} height={250} className="chart" data={dataset} margin={{ top: 5, right: 5, left: 5, bottom: 5 }} >
						<XAxis dataKey="date" 
						type="category" 
						tick={{ fontSize: 12 }}
						tickMargin={4}
						tickLine={false}
						/>
						<YAxis domain={['dataMin', 'dataMax']} 
						label={{ value: 'Time spent (min)', position: 'insideLeft', style: { textAnchor: 'middle', fontWeight: 'normal' }, angle: -90 }}
						tick={{ fontSize: 18 }}
						tickMargin={8}
						tickLine={false}
						/>
					<Tooltip formatter={(value, name) => { return [`${value} minutes`, "Motion time"] }}/>
					<Bar dataKey="value" fill="#F36C33" />
					</BarChart>


				</React.Fragment>:
				<h4>Loading Time Graph...</h4>


			}



		</div>

	);
}


export default TimeSeriesGraph;
