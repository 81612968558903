import API from "./API";


export const arrivedAtProvider = ( vetkitId ) => {
  return API.put(`/vetkits/${vetkitId}/`, JSON.stringify({ action: 1 }))
    .then(response => {
    })
    .catch(error => console.error(error));

}

export const sendToKaruna = ( vetkitId ) => {
  return API.put(`/vetkits/${vetkitId}/`, JSON.stringify({ action: 2 }))
    .then(response => {
    })
    .catch(error => console.error(error));

}

export const arrivedAtPatient = (vetkitAssignmentId) => {
  const data = JSON.stringify({ action: 1});
  console.log(data);
  return API.put(`/vetkitassignments/${vetkitAssignmentId}/`, data)
    .then(response => {


    })
    .catch(error => console.error(error));
}

export const returnToProvider = (vetkitAssignmentId, deliveryMethod, returnReason, returnNotes) => {
  const data = JSON.stringify({ action: 2, delivery_method: deliveryMethod, return_reason: returnReason, return_notes: returnNotes });
  console.log(data);
  return API.put(`/vetkitassignments/${vetkitAssignmentId}/`, data)
    .then(response => {

    })
    .catch(error => console.error(error));

}

export const assignVetKit = (vetkitId, patientId, dueDate, deliveryMethod) => {
  const data = JSON.stringify({
    patient: patientId,
    vetkit: vetkitId,
    date_due: dueDate,
    delivery_method: deliveryMethod
  });

  console.log("Assignment DATA", data)

  return API.post('/vetkitassignments/', data).then(response => {
    
  }).catch(error => console.log("ERROR", error));
}




