import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { sendToKaruna,  returnToProvider, sendToPatient, assignVetKit } from '../../services/VetkitServices';
import { canReturnToKaruna, canReturnToProvider } from '../../utility/VetkitUtility';
import ButtonConfirmation from '../cards/ButtonConfirmation';
import DropdownInput from '../input/DropdownInput'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

const AssignVetkitButton = ({ vetkitId, patientId, onChange, history }) => {

  const [deliveryMethod, setDeliveryMethod] = useState(1);
  const [dueDate, setDueDate] = useState(moment().add(12, 'weeks').toDate());

  const assignVetkitAction = () => {
    assignVetKit(vetkitId, patientId, moment(dueDate).format("YYYY-MM-DD"), deliveryMethod).then(response => {
      history.push(`/patient/${patientId}`)
    });
  }


  return(
    <React.Fragment>
      {vetkitId && <ButtonConfirmation label="Assign vetkit" action={assignVetkitAction} message="Are you ready to assign this kit to the patient? Please confirm the delivery method and due date." >
        <DropdownInput 
        name="Delivery method"
        options={[{label: "By mail", id: 1}, {label: "Handed directly to patient", id: 2}]}
        value={deliveryMethod}
        onChange={setDeliveryMethod}
        />
        <h4>Due date</h4>
        <DatePicker selected={dueDate} onChange={setDueDate} />
        </ButtonConfirmation>}    
    </React.Fragment>
    
  )
}

export default withRouter(AssignVetkitButton);