import cookie from 'react-cookies';
import moment from 'moment-timezone';

export const getTimezone = () => {
  let timezone = cookie.load("timezone");

  if(timezone){
    return timezone;
  }
  else{
    return moment.tz.guess();
  }

  //otherwise set local time



}