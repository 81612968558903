import React, { useState } from 'react';
import Confirmation from '../Confirmation';


const ButtonConfirmation = ({ label, action, children, message }) => {
  const [confirmationVisible, setConfirmationVisible] = useState(false);


  const handleClick = (e) => {
    
    e.stopPropagation();
    setConfirmationVisible(true);
  }



  return(
    <React.Fragment>
      <button onClick={handleClick}>{label}</button>
      {confirmationVisible && 
      <Confirmation 
      onClose={() => setConfirmationVisible(false)} 
      onSubmit={action}    
      >
        {message}
        {children}
        </Confirmation>}
    </React.Fragment>
    
  )
}

export default ButtonConfirmation;