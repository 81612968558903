import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import API from '../services/API';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import cookie, { select } from 'react-cookies';
import Breadcrumbs from '../components/Breadcrumbs';
import FormErrors from '../components/FormErrors';
import { TailSpin	 } from  'react-loader-spinner'
import Input from '../components/input/Input';
import SingleInput from '../components/input/SingleInput';
import { element, object } from 'prop-types';
import moment from 'moment';

const PatientOutcome = ({ history }) => {

    const NotificationTypes = {
        NewSymptoms:"NewSymptoms",
        PsychotherapyReferral: "PsychotherapyReferral",
        MedicationReEvaluation: "MedicationReEvaluation",
        NewInjury: "NewInjury",
       
    }

    const [manual, SetManual] = useState(false);
    const [previouslyActivePlan, setPreviouslyActivePlan] = useState(false);
    const [data, setData] = useState(null);

	const [patientId, setPatientId] = useState(useParams().id);
    const [generate, setGenerate]= useState(false)
	const [patientName, setPatientName] = useState(null);
    const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [loadedProfile, setLoadedProfile] = useState(false);
    const [coach, setCoach] = useState(null);
    const [currentWeek, setCurrentWeek] = useState(0);
    const [selectedWeek, setSelectedWeek] = useState(0);
    const [changedWeek, setChangedWeek] = useState(0);
    const [DOB,setDOB] = useState("");
    const [program,setProgram] = useState("");

    const [hasPlan, setHasPlan] = useState(true);

    const [templateWeeks,setTemplateWeeks] = useState("1");
    const [templateNotification,setTemplateNotification] = useState(NotificationTypes.PsychotherapyReferral);





    const [weeks, setWeeks] = useState([]);
    const [oversight, setOversight] = useState(false);
    const [plan, setPlan] = useState("");
    const [assessment, setAssessment] = useState("");
    const [summary, setSummary] = useState("");

    //#region checkboxes
    const [goalProgress, setGoalProgress] = useState(false); 
    const [engagmentLevel, setEngagementLevel] = useState(false); 

    const [sessionsAttended, setSessionsAttended] = useState(false); 
    const [uniqueDays, setUniqueDays] =useState(false);  
    const [averageTime, setAverageTime] = useState(false); 

    const [leftFlexion, setLeftFlexion] = useState(false); 
    const [leftScaption, setLeftScaption] = useState(false); 
    const [leftAbduction, setLeftAbduction] = useState(false); 
    const [rightFlexion, setRightFlexion] = useState(false); 
    const [rightScaption, setRightScaption] =useState(false); 
    const [rightAbduction, setRightAbduction] = useState(false); 
    const [lowerFlexion, setLowerFlexion] = useState(false); 

    const [pain, setPain] = useState(false); 
    const [mood, setMood] = useState(false); 
    const [activity, setActivity] = useState(false); 
    const [sleep, setSleep] = useState(false); 
    const [confidence, setConfidence] =useState(false); 

    const [returnToWork, setReturnToWork] = useState(false); 
    const [medReduction, setMedReduction] = useState(false); 
    const [oswestry, setOwestry] = useState(false); 
    const [Dash, setDash] = useState(false); 
    const [PSEQ, setPSEQ] = useState(false); 
    const [PCS, setPCS] = useState(false); 
    const [UEFI, setUEFI] = useState(false);
    const [FABQTotal, setFABQTotal] = useState(false);
    const [FABQActivity, setFABQActivity] = useState(false);
    const [FABQWork, setFABQWork] = useState(false);
    const [PHQ9, setPHQ9] = useState(false);
    const [PCSS, setPCSS] = useState(false);
    const [WHODAS, setWHODAS] = useState(false);
    const [GAD7, setGAD7] = useState(false);
    const [NDI, setNDI] = useState(false);


    const [physical, setPhysical,] = useState(true); 
    const [behavioral, setBehavioral] = useState(true); 
    const [social, setSocial] = useState(true); 

    const [continueProgram, setContinueProgram] = useState(false); 
    const [notification, setNotification] = useState(false); 
    const [extension, setExtension] = useState(false); 
    const [planEnd, setPlanEnd] = useState(false); 



    //#endregion checkboxes

    //#region raw data
    const [rawGoalProgressData, setRawGoalProgressData] = useState(null); //put in
    const [rawEngagmentLevelData, setRawEngagementLevelData] = useState(null); //put in


    const [RawSessionsAttendedData, setRawSessionsAttendedData] = useState(null); //in
    const [RawUniqueDaysData, setRawUniqueDaysData] = useState(null); //in
    const [RawAverageTimeData, setRawAverageTimeData] = useState(null); //in
    //#endregion raw data

     //#region formatted data
     
    const [physicalText, setPhysicalText] = useState(""); 
    const [behavioralText, setBehavioralText] = useState(""); 
    const [socialText, setSocialText] = useState(""); 


     const [returnToWorkData, setReturnToWorkData] = useState(null); //in
     const [medReductionData, setMedReductionData] = useState(null); //in

     const [leftFlexionData, setLeftFlexionData] = useState([]);
    const [leftScaptionData, setLeftScaptionData] = useState([]);
    const [leftAbductionData, setLeftAbductionData] = useState([]);
    const [rightFlexionData, setRightFlexionData] = useState([]);
    const [rightScaptionData, setRightScaptionData] = useState([]);
    const [rightAbductionData, setRightAbductionData] = useState([]);
    const [lowerFlexionData, setLowerFlexionData] = useState([]);

    const [leftFlexionCheck, setLeftFlexionCheck] = useState(false);
    const [leftScaptionCheck, setLeftScaptionCheck] = useState(false);
    const [leftAbductionCheck, setLeftAbductionCheck] = useState(false);
    const [rightFlexionCheck, setRightFlexionCheck] = useState(false);
    const [rightScaptionCheck, setRightScaptionCheck] = useState(false);
    const [rightAbductionCheck, setRightAbductionCheck] = useState(false);
    const [lowerFlexionCheck, setLowerFlexionCheck] = useState(false);


    const [painData, setPainData] = useState([]);
    const [moodData, setMoodData] = useState([]);
    const [activityData, setActivityData] = useState([]);
    const [sleepData, setSleepData] = useState([]);
    const [confidenceData, setConfidenceData] = useState([]);

    const [PSEQData, setPSEQData] = useState([]);
    const [PCSData, setPCSData] = useState([]);
    const [oswestryData, setOwestryData] = useState([]);
    const [DashData, setDashData] = useState([]);
    const [UEFIData, setUEFIData] = useState([]);
    const [FABQTotalData, setFABQTotalData] = useState([]);
    const [FABQActivityData, setFABQActivityData] = useState([]);
    const [PHQ9Data, setPHQ9Data] = useState([]);
    const [PCSSData, setPCSSData] = useState([]);
    const [WHODASData, setWHODASData] = useState([]);
    const [GAD7Data, setGAD7Data] = useState([]);
    const [NDIData, setNDIData] = useState([]);
    const [FABQWorkData, setFABQWorkData] = useState([]);

    //#endregion formatted data

    //#region improvment
    const [leftFlexionImp, setLeftFlexionImp] = useState([]);
    const [leftScaptionImp, setLeftScaptionImp] = useState([]);
    const [leftAbductionImp, setLeftAbductionImp] = useState([]);
    const [rightFlexionImp, setRightFlexionImp] = useState([]);
    const [rightScaptionImp, setRightScaptionImp] = useState([]);
    const [rightAbductionImp, setRightAbductionImp] = useState([]);
    const [lowerFlexionImp, setLowerFlexionImp] = useState([]);

    const [painImp, setPainImp] = useState(null);
    const [moodImp, setMoodImp] = useState(null);
    const [activityImp, setActivityImp] = useState(null);
    const [sleepImp, setSleepImp] = useState(null);
    const [confidenceImp, setConfidenceImp] = useState(null);

    const [medImp, setMedImp] = useState(null);


    const [PSEQImp, setPSEQImp] = useState(null);
    const [PCSImp, setPCSImp] = useState(null);
    const [oswestryImp, setOwestryImp] = useState(null);
    const [DashImp, setDashImp] = useState(null);
    const [UEFIImp, setUEFIImp] = useState(null);
    const [FABQTotalImp, setFABQTotalImp] = useState(null);
    const [FABQActivityImp, setFABQActivityImp] = useState(null);
    const [FABQWorkImp, setFABQWorkImp] = useState(null);
    const [PHQ9Imp, setPHQ9Imp] = useState(null);
    const [PCSSImp, setPCSSImp] = useState(null);
    const [WHODASImp, setWHODASImp] = useState(null);
    const [GAD7Imp, setGAD7Imp] = useState(null);
    const [NDIImp, setNDIImp] = useState(null);

    //#endregion improvement

    const [discovery,setDiscovery] = useState([])
    const [htmlDisc,setHtmlDisc] = useState([])

    const [goal,setGoal] = useState([])
    const [htmlGoal,setHtmlGoal] = useState([])

	const [errors, setErrors] = useState(null);
	useEffect(() => {
		if (patientId) {
			getPatientData();
            
		}

	}, []);


    useEffect(() => {
		if(rawGoalProgressData!==null)
        {
            setGoalProgress(true);
        }
        else
        {
            setGoalProgress(false);

        }
        if(rawEngagmentLevelData!==null)
        {
            setEngagementLevel(true);
        }
        else
        {
            setEngagementLevel(false);

        }
        if(RawSessionsAttendedData!==null&&RawSessionsAttendedData!==NaN)
        {
            setSessionsAttended(true);
        }
        else
        {
            setSessionsAttended(false);

        }
        if(RawUniqueDaysData!==null&&RawUniqueDaysData!==NaN)
        {
            setUniqueDays(true);
        }
        else
        {
            setUniqueDays(false);

        }
        if(RawAverageTimeData!==null&& RawAverageTimeData!==NaN)
        {
            setAverageTime(true);
        }
        else
        {
            setAverageTime(false);

        }
        if(returnToWorkData!==null)
        {
            if(returnToWorkData.slice(-1)[0].value!==0){
                setReturnToWork(true);
            }
            else
            {
                setReturnToWork(false);
            }
        }
        else
        {
            setReturnToWork(false);

        }
        if(medReductionData!==null)
        {
            setMedReduction(true);
        }
        else
        {
            setMedReduction(false);
 
        }
        if(leftFlexionData.length>0)
        {
            setLeftFlexion(true)
        }
        else
        {
            setLeftFlexion(false)
 
        }
        if(leftScaptionData.length>0)
        {
            setLeftScaption(true)
        }
        else
        {
            setLeftScaption(false)

        }
        if(leftAbductionData.length>0)
        {
            setLeftAbduction(true)
        }
        else
        {
            setLeftAbduction(false)
  
        }
        if(rightFlexionData.length>0)
        {
            setRightFlexion(true)
        }
        else
        {
            setRightFlexion(false)
 
        }
        if(rightScaptionData.length>0)
        {
            setRightScaption(true)
        }
        else
        {
            setRightScaption(false)

        }
        if(rightAbductionData.length>0)
        {
            setRightAbduction(true)
        }
        else
        {
            setRightAbduction(false)

        }

        if(painData.length>0)
        {
            setPain(true)
        }
        else{
            setPain(false)

        }
        if(moodData.length>0)
        {
            setMood(true)
        }
        else
        {
            setMood(false)

        }
        
        if(activityData.length>0)
        {
            setActivity(true)
        }
        else
        {
            setActivity(false)
  
        }
        if(sleepData.length>0)
        {

            setSleep(true)
        }
        else
        {
            setSleep(false)

        }
        if(confidenceData.length>0)
        {
            setConfidence(true)
        }
        else
        {
            setConfidence(false)
 
        }

        if(PSEQData.length>0)
        {
            setPSEQ(true)
        }
        else
        {
            setPSEQ(false)

        }
        if(PCSData.length>0)
        {
            setPCS(true)
        }
        else
        {
            setPCS(false)
 
        }
        if(oswestryData.length>0)
        {
            setOwestry(true)
        }
        else
        {
            setOwestry(false)

        }
        if(DashData.length>0)
        {
            setDash(true)
        }
        else
        {
            setDash(false);

        }

        if(lowerFlexionData.length>0)
        {
            setLowerFlexion(true)
        }
        else
        {
            setLowerFlexion(false)

        }
        if(UEFIData.length>0)
        {
            setUEFI(true);
        }
        else
        {
            setUEFI(false);
        }

        if(FABQTotalData.length>0)
        {
            setFABQTotal(true);
        }
        else
        {
            setFABQTotal(false);
        }
        if(FABQActivityData.length>0)
        {
            setFABQActivity(true);
        }
        else
        {
            setFABQActivity(false);
        }
        if(FABQWorkData.length>0)
        {
            setFABQWork(true);
        }
        else
        {
            setFABQWork(false);
        }

        if(NDIData.length>0)
        {
            setNDI(true);
        }
        else
        {
            setNDI(false);
        }

        if(PCSSData.length>0)
        {
            setPCSS(true);
        }
        else
        {
            setPCSS(false);
        }

        if(WHODASData.length>0)
        {
            setWHODAS(true);
        }
        else
        {
            setWHODAS(false);
        }

        if(GAD7Data.length>0)
        {
            setGAD7(true);
        }
        else
        {
            setGAD7(false);
        }

        if(PHQ9Data.length>0)
        {
            setPHQ9(true);
        }
        else
        {
            setPHQ9(false);
        }

	}, [rawGoalProgressData,rawEngagmentLevelData,RawSessionsAttendedData,RawUniqueDaysData,RawAverageTimeData,returnToWorkData,medReductionData,leftFlexionData,leftScaptionData,leftAbductionData,rightFlexionData,rightScaptionData,rightAbductionData, painData, moodData, activityData, sleepData, confidenceData, PSEQData,PCSData,oswestryData,DashData, lowerFlexionData, UEFIData, FABQTotalData,FABQActivityData,FABQWorkData, PCSSData, NDIData,GAD7Data,PHQ9Data,WHODASData]);


    useEffect(() => {
       
        var text=""
        if(continueProgram)
        {

            if(selectedWeek<12)
            {

            text+=`Given ${patientName.split(" ")[0]}’s progress and current needs, it is my recommendation to continue with the ongoing KarunaHOME program. The upcoming weeks will cover the following topics, tailored to support ${patientName.split(" ")[0]}'s specific goals and to build upon their existing knowledge:\n\n`+

            ("Pain Defined, Neural Circuit Pain and Calming the Nervous System, Danger Signals, Graded Exposure, Emotions, Thoughts and Behaviors, Stress Interpretation and Relaxation Skills, Mindfulness and Gratitude, Enjoyable Activities, Social Connection, Restoring Life Balance, Reclaiming Your Life, Planning For Success".split(", ").slice(selectedWeek-1, 11).join(", ")+"\n\n")+

            `${patientName.split(" ")[0]} will be encouraged to continue engaging with 5-25 minutes of daily VET™ and maintain regular physical activity. Regular check-ins and adjustments will be made as necessary to ensure the patient's optimal engagement and benefit.`
            }
            else
            {
                text+=`Given ${patientName.split(" ")[0]}'s progress and current needs, it is my recommendation to continue with the ongoing KarunaHOME program. Having completed the core 12-week curriculum, ${patientName.split(" ")[0]}'s coaching sessions will be tailored to support their specific goals and to build upon their existing knowledge.\n\n`+`${patientName.split(" ")[0]} will be encouraged to continue engaging with 5-25 minutes of daily VET™ and maintain regular physical activity. Regular check-ins and adjustments will be made as necessary to ensure the patient's optimal engagement and benefit.`
            }
        }
        if(notification)
        {
			text  +=((text.length>0?"\n\n":""))
            switch(templateNotification)
            {
                case NotificationTypes.PsychotherapyReferral:
                    text+=`${patientName.split(" ")[0]} has expressed challenges with their mental health. They are interested in exploring options such as psychotherapy or counseling.`
                    break
                case NotificationTypes.MedicationReEvaluation:
                    text+=`${patientName.split(" ")[0]} has voiced concerns regarding their current medication regimen. They would like to request a re-evaluation of their current needs to address any potential side-effects or concerns.`
                    break;
                case NotificationTypes.NewInjury:
                    text+=`${patientName.split(" ")[0]} has reported a recent injury and is requesting further medical attention to assess the extent of the injury and advise any necessary modifications to their current plan of care.`
                    break;
                case NotificationTypes.NewSymptoms:
                    text+=`${patientName.split(" ")[0]} has reported experiencing new concerning symptoms. They are requesting a physician re-evaluation to ascertain the cause and implications of these symptoms.`
                    break;
            }
        }
		if (extension) {
            var template=`In consideration of the subjective and objective progress exhibited by ${patientName} over the initial ${selectedWeek} week${selectedWeek>1?"s":""} of the KarunaHOME program, it is my recommendation that a request for an additional ${templateWeeks} week${templateWeeks>1?"s":""} be submitted for authorization.`
			text  +=((text.length>0?"\n\n":"")+template)
		}
        if(planEnd)
        {
            var template=`Given ${patientName.split(" ")[0]}'s progress and current needs, I recommend that they continue implementing the skills and concepts of the KarunaHOME program independently. Having completed the core 12-week curriculum, ${patientName.split(" ")[0]} will be encouraged to continue working towards their goals. ${patientName.split(" ")[0]} will have a 6-month check-in, and adjustments will be made as necessary to ensure their optimal engagement and benefit. If ${patientName.split(" ")[0]}'s current state starts to decline, it is my recommendation that they are re-evaluated for additional sessions with the KarunaHOME program.`
			text  +=((text.length>0?"\n\n":"")+template)
        }
        setPlan(text)

        

	}, [extension,continueProgram, notification, templateWeeks, templateNotification,planEnd]);

    useEffect(() => {
        if(selectedWeek!==changedWeek)
        {
            setChangedWeek(selectedWeek)
            
        }

	}, [selectedWeek]);


    useEffect(() => {
        if(manual)
        {
        setErrors("");
        setLoadedProfile(false);
        getPatientData(changedWeek);
        SetManual(false);
        }

	}, [changedWeek]);

    useEffect(() => {
		var text=""

        if(physical)
        {
            if(physicalText.trim()!=="")
            {
                text+=physicalText.trim(); 
                if(text.charAt(text. length-1)!==".")
                {
                    text+="."
                }

            }
        }
        else
        {
            
        }

        if(behavioral)
        {
            if(behavioralText.trim()!=="")
            {
                if(text.length>0)
                {
                    text+="\n"
                }
                text+=behavioralText.trim(); 
                if(text.charAt(text. length-1)!==".")
                {
                    text+="."
                }
            }
        }
        else
        {
            
        }

        if(social)
        {
            if(socialText.trim()!=="")
            {
                if(text.length>0)
                {
                    text+="\n"
                }
                text+=socialText.trim(); 
                if(text.charAt(text. length-1)!==".")
                {
                    text+="."
                }
            }
        }
        else
        {
            
        }

        setAssessment(text);

	}, [social,behavioral,physical,socialText,behavioralText,physicalText]);




    useEffect(() => {
		if (generate) {

            setLoadedProfile(false);
            GenerateJSON();
            setGenerate(false)

		}

	}, [generate]);

    useEffect(() => {
        if(errors!==""){
        window.scrollTo(0, 0);  
        }     


	}, [errors]);

	
	useEffect(() => {
	}, [loadedProfile])

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      }

      const IndicatePSEQ=(val)=>{
            var text="";
            if(val>=40)
            {
                text+="Higher confidence to return to work";
            }
            if(val<=30)
            {
                text+="Decreased likelihood to return to work";
            }
            return text+",";
      }

      const IndicateOswestry=(val)=>{
        var text="";
        if(val<=20)
        {
            text="minimal disability";
        }
        else if(val<=40)
        {
            text="moderate disability";

        }
        else if (val <=60)
        {
            text="severe disability";

        }
        else if (val <=80)
        {
            text="crippled";

        }
        else if (val <=100)
        {
            text="bed-bound";

        }
        return text+",";

      }

      const getPercent=(x, y)=> {
        if(x===0&&y===0)
        { 
            return 0;
        }
        if(x===0&&y!==0)
        { 
            x=1;
        }
        var percentage =(((y - x) * 100)/x).toFixed(1);
        if(parseFloat( percentage)===0)
        {

            percentage=0;
        }
        percentage= percentage.toString();
        percentage=parseFloat(percentage);
        return percentage
       }

       const getDifference=(x, y)=> {
        var dif = y-x;
        if(parseFloat( dif)===0)
        {

            dif=0;
        }
        return dif
       }

    const formatDate = (date) =>{
        return [
        padTo2Digits(date.getMonth() + 1),
          padTo2Digits(date.getDate()),
          date.getFullYear(),
        ].join('/');
      }


      const AddGoal=(e)=>{
        e.preventDefault();

        var newHTML = htmlGoal.concat([<SingleInput  start={"Description (Progress and Action Steps)"} nameClass="goalInput" index={htmlGoal.length} eraseFunction={RemoveGoal} title='Goal' multiInput={true} secondClass='goal2Input' start2="Title (1-6 words)" />])
        setHtmlGoal(newHTML)
    }

    const AddGoalManual=(vals, revertOveride=false)=>{
        var newHTML=[]
        vals.forEach(v=>{

        newHTML = newHTML.concat([<SingleInput  startVal={v[1]} nameClass="goalInput" index={newHTML.length} eraseFunction={RemoveGoal} title='Goal' multiInput={true} secondClass='goal2Input' startVal2={v[0]} />])

        })

        if(revertOveride)
        {
                var g1 =document.querySelectorAll('.goalInput');
                var g2 = document.querySelectorAll('.goal2Input');

                for(var i =0; i<vals.length;i++)
                {
                if(g1[i]!==undefined&&g2[i]!==undefined){
                g1[i].value=vals[i][1];
                g2[i].value=vals[i][0];
                }


                }
        }

        setHtmlGoal(newHTML)
    }

    const RemoveGoal=(index)=>
    {
        var valueArr = [];
        document.querySelectorAll('.goalInput').forEach(function(el){
            valueArr.push(el.value);
        });
        valueArr.splice(index,1);

        var valueArr2 = [];
        document.querySelectorAll('.goal2Input').forEach(function(el){
            valueArr2.push(el.value);
        });
        valueArr2.splice(index,1);
       

        var newHolder=[];
        valueArr.forEach((element,i)=>{
            newHolder.push(<SingleInput start={element===""?"Description (Progress and Action Steps)":element} nameClass="goalInput" index={i} eraseFunction={RemoveGoal} title='Goal' multiInput={true} secondClass='goal2Input' start2={valueArr2[i]===""?"Title (1-6 words)":valueArr2[i]}/>)
        })
        setHtmlGoal(newHolder)
        document.querySelectorAll('.goalInput').forEach((el,i)=>{
            el.value=valueArr[i];
          });   

          document.querySelectorAll('.goal2Input').forEach((el,i)=>{
            el.value=valueArr2[i];
          });
    }

    const MedReductionCheck=(ar)=>{
        var length = ar.length;
        if(length<=1)
        {
            return true;
        }
        var result =true;
        var ar2 = ar.slice(0,length-1);
        ar2.forEach(e=>{
            if(parseInt(e.value)!==0)
            {
                result=false;
            }
        })
        return result;
    }

    const MedReductionCheckFull=(ar)=>{
        var length = ar.length;
        if(length<=1)
        {
            return true;
        }
        var result =true;
        var ar2 = ar.slice(0,length);
        ar2.forEach(e=>{
            if(parseInt(e.value)!==0)
            {
                
                result=false;
            }
        })
        return result;
    }

    const GetMedReductionResult=(ar)=>{

        var medRed="No";
      
        if(ar.slice(-1)[0].value>=2||MedReductionCheckFull(ar))
        {
            medRed="No";
        }
        else
        {
          
            if(ar.slice(-1)[0].value<=1&&!MedReductionCheck(ar)){

                medRed="Yes";

            }
            else
            {

                medRed="No";
            }
        }
        return medRed;
    }

    const GetWHODASIndication=(val)=>{
        switch(true)
        {
            case val <1.5:  return "No Disability, ";
            case val <2.5:  return "Mild Disability, ";
            case val<3.5:  return "Moderate Disability, ";
            case val <4.5:  return "Severe Disability, ";
            default: return "Extreme Disability, ";

        }

    }

    const GetGAD7Indication=(val)=>{
        switch(true)
        {
            case val <=4:  return "No anxiety, ";
            case val <=9:  return "Mild anxiety, ";
            case val<=14:  return "Moderate anxiety, ";
            case val <=21:  return "Severe anxiety, ";
            default: return "";
        }
    }

    const GetNDIIndication=(val)=>{
        switch(true)
        {
            case val <=4:  return "No Neck disability, ";
            case val <=14:  return "Mild disability, ";
            case val<=24:  return "Moderate disability, ";
            case val <=34:  return "Severe disability, ";
            default: return "Complete disability, ";
        }
    }

    const GetPHQ9Indication=(val)=>{
        switch(true)
        {
            case val <=4:  return "Minimal depression, ";
            case val <=9:  return "Mild depression, ";
            case val<=14:  return "Moderate depression, ";
            case val <=19:  return "Moderatel severe depression, ";
            case val <=27:  return "Severe depression, ";

            default: return "Complete disability, ";
        }
    }


    const AddDiscovery=(e)=>{
        e.preventDefault();

        var newHTML = htmlDisc.concat([<SingleInput start="Description" nameClass="discoveryInput" index={htmlDisc.length} eraseFunction={RemoveDiscovery} title='Discovery'/>])
        setHtmlDisc(newHTML)
    }

    const AddDiscoveryManual=(vals, override=false)=>{
        var newHTML=[]
        vals.forEach(v=>{
        newHTML = newHTML.concat([<SingleInput startVal={v} nameClass="discoveryInput" index={newHTML.length} eraseFunction={RemoveDiscovery} title='Discovery'/>])
        })

        if(override)
        {
            var d1 =document.querySelectorAll('.discoveryInput');

              for(var i =0; i<vals.length;i++)
              {
                if(d1[i]!==undefined)
                {
                    d1[i].value=vals[i];
                }

              }
        }
        
        setHtmlDisc(newHTML)
    }

    const RemoveDiscovery=(index)=>
    {
        var valueArr = [];
        document.querySelectorAll('.discoveryInput').forEach(function(el){
            valueArr.push(el.value);
        });
        valueArr.splice(index,1);

        var newHolder=[];
        valueArr.forEach((element,i)=>{
            newHolder.push(<SingleInput start={element===""?"Description":element} nameClass="discoveryInput" index={i} eraseFunction={RemoveDiscovery} title='Discovery'/>)
        })
        setHtmlDisc(newHolder)
        document.querySelectorAll('.discoveryInput').forEach((el,i)=>{
            el.value=valueArr[i];
          });   
    }

	

   
	


    const NumberPages =(doc)=>{
        var pageWidth = doc.internal.pageSize.width;  
        var pageHeight = doc.internal.pageSize.height;  

        var pages = doc.internal.getNumberOfPages();
       
        doc.setFontSize(11);  //Optional
        doc.setFont('Proxima Nova Regular','normal')

        for (let j = 1; j < pages + 1 ; j++) {
              let horizontalPos = pageWidth / 2;  //Can be fixed number
              let verticalPos = pageHeight - 20;  //Can be fixed number
              doc.setPage(j);
              doc.text(`Rewire Your Brain. Unlearn Your Pain`, 40, verticalPos, {align: 'left'  });
              doc.text(`${j}`, pageWidth-40, verticalPos, {align: 'right'  });   

              doc.setDrawColor(30,170,241)
              doc.setFillColor(30,170,241 )

              verticalPos = pageHeight - 10;  //Can be fixed number
                doc.rect (0,verticalPos , pageWidth, 10,'FD');
                doc.rect (0,0, pageWidth, 10,'FD');

               
        }
       
    }


    const ProviderSection =(doc)=>{

        var {
            ComboBox,
            ListBox,
            CheckBox,
            PushButton,
            TextField,
            PasswordField,
            RadioButton,
            Appearance
        } = jsPDF.AcroForm;

        var pageWidth = doc.internal.pageSize.width;  
        var pageHeight = doc.internal.pageSize.height;  
        var y= doc.lastAutoTable.finalY
        doc.addPage();
        y=0;

        doc.setFont('Proxima Nova Regular','normal')
        doc.setTextColor(30,170,241 )

        doc.setFontSize(20); 
        doc.text(40, y = y + 40, "Physician Review", { align: 'left' });

        doc.setFont('Proxima Nova Regular','normal')
        doc.setFontSize(11); 
        doc.setTextColor("black")

        doc.text(40, y=y+20, "For provider use only");
        y+=10;
        doc.setFillColor("Black" )
        doc.setDrawColor("Black")
        doc.setLineWidth(1); 

        var checkBox = new CheckBox();
        checkBox.fieldName = "CheckBox1";
        checkBox.Rect = [40, y+=15, 10, 10];
        checkBox.width = 20;
		checkBox.height = 20;
        checkBox.fontSize=10;
       checkBox.appearanceState = 'Off'
		checkBox.maxFontSize = 10;
        doc.addField(checkBox);
        doc.setFillColor("Black" )
        doc.rect(40,y,20,20)
        y+=5;


        doc.text(80, y, `Having reviewed the subjective and objective outcomes of ${patientName}, I concur with`)
        doc.text(80, y+=15, `and hold as my own ${coach}’s assessment and onward plan of care.`)




        doc.text(40, y=y+30, "Other Comments:");
        doc.setFillColor("Black" )
        doc.rect(40,y+=10,530,400)
        doc.setFillColor("Black" )

    var notesField = new TextField();
    notesField.multiline = true;
    notesField.maxFontSize = 12;
    notesField.fontSize=12;

    notesField.height = 150;
    notesField.Rect = [40,y,530,400];
    notesField.fontSize = 12;

    
    doc.addField(notesField);

    y+=400;
    doc.setLineWidth(3); 

    doc.setFillColor("Black");
    doc.setDrawColor("Black");
    doc.line(pageWidth-270,y+=80,pageWidth-40,y)
    doc.text(pageWidth-40, y+=20, "Provider Signature", { align: 'right' })

    doc.line(pageWidth-270,y+=80,pageWidth-40,y)
    doc.text(pageWidth-40, y+=20, "Date", { align: 'right' })

    }
   
    
	
    const handleSubmit = (e)=>{
        e.preventDefault();

        if(selectedWeek===0)
        {
            setErrors("cannot create export during week 0.\n")
            return;
        }

        if(hasPlan===false)
        {
            setErrors("cannot create export without active coaching plan.\n")
            return;
        }

        


        var error ="";

        if(social&& socialText.length>600)
        {
            error+=("Social section of assessment is above character limit\n")
        }

        if(physical&& physicalText.length>600)
        {
            error+=("Physical section of assessment is above character limit\n")
        }

        if(behavioral&& behavioralText.length>600)
        {
            error+=("Behavioral section of assessment is above character limit\n")
        }

        var discoveryText="";
        document.querySelectorAll('.discoveryInput').forEach(function(el){
            if(el.value.trim()!==""){
            discoveryText+= (el.value)+(el.value.endsWith(".")?"":".")+"\n";
            }
            else
            {
                error+="incomplete discovery field, please remove or fill out.\n"
                
            }
        });
        setDiscovery(discoveryText);

        var g1=[]
        var g2=[]
        document.querySelectorAll('.goalInput').forEach((el)=>{
            g2.push( el.value +(el.value.endsWith(".")?"":"."));
          });   

          document.querySelectorAll('.goal2Input').forEach((el)=>{
            g1.push(el.value);
          });

          var gtemp=[];
        
          g1.forEach((e,i)=>{
            if(e.trim()!=="" && g2[i].trim()!==""){
            gtemp.push({goal: e , detail: g2[i]})
            }
            else
            {
                error+="incomplete goal field, please remove or fill out.\n"

            }
          })
          setGoal(gtemp);


          if(summary.trim()===""){
            error+="please fill out Progression Summary.\n"
          }

          if(assessment.trim()===""){
            error+="please fill out Assessment. There must be some form of Assessment\n"
          }

          if(plan.trim()===""){
            error+="please fill out Plan. There must be some form of Plan\n"
          }

          if(error===""){
            setErrors("");

        setGenerate(true);
          }
          else
          {
            setErrors(error)
          }
    }

    const  GenerateJSON= async ()=>{
        setErrors("");

        data.enabled={};
        data.enabled.goalProgress=goalProgress;
        data.enabled.engagmentLevel=engagmentLevel;
        data.enabled.sessionsAttended=sessionsAttended;
        data.enabled.uniqueDays=uniqueDays;
        data.enabled.averageTime=averageTime;
        data.enabled.leftFlexion=leftFlexion;
        data.enabled.leftScaption=leftScaption;
        data.enabled.leftAbduction=leftAbduction;
        data.enabled.rightFlexion=rightFlexion;
        data.enabled.rightScaption=rightScaption;
        data.enabled.rightAbduction=rightAbduction
        data.enabled.lowerFlexoion = lowerFlexion;
        data.enabled.pain=pain;
        data.enabled.mood=mood;
        data.enabled.activity=activity;
        data.enabled.sleep=sleep;
        data.enabled.confidence=confidence;
        data.enabled.returnToWork=returnToWork;
        data.enabled.medicationReduction=medReduction;
        data.enabled.oswestry=oswestry;
        data.enabled.Dash=Dash;
        data.enabled.PSEQ=PSEQ;
        data.enabled.PCS=PCS;
        data.enabled.UEFI=UEFI;
        data.enabled.PCSS= PCSS;
        data.enabled.WHODAS= WHODAS;
        data.enabled.NDI= NDI;
        data.enabled.GAD7= GAD7;
        data.enabled.PHQ9= PHQ9;




        data.enabled.social=social;
        data.enabled.physical=physical;
        data.enabled.behavioral=behavioral;
        data.enabled.FABQTotal= FABQTotal;
        data.enabled.FABQActivity= FABQActivity;
        data.enabled.FABQWork= FABQWork;
        data.customizations={};
        data.included_weeks=selectedWeek;


        data.customizations.customSummary=summary;
        var tempGoals=[];
        goal.forEach(e=>{
            tempGoals.push([e.goal,e.detail]);
        })
        data.customizations.custumGoals=tempGoals;
        
        data.customizations.customDiscovery=discovery.split("\n").filter(function (el) {
            return el !=="";
          });

        data.customizations.customSocial=socialText;
        data.customizations.customPhysical=physicalText;
        data.customizations.customBehavioral=behavioralText;

        data.plan= plan;

        var json={}
        json.report_json_data=data;


        await API.post(`/outcome_reports/`,json ).then((response) => {

                console.log(response);
                history.push(`/patient/${patientId}/`,
                {    
                    state: {
                        message: `Week ${selectedWeek} report successfully submitted for review.`
                    }
                });
            
        }).catch(error => {
            console.error(error)
            setErrors(error.message);
            setLoadedProfile(true);


        })  



    }


	

	

    const ChangeWeek = (event)=>{
        if(event.target.value!==selectedWeek){
        SetManual(true);
        setSelectedWeek(event.target.value);
        }
    }

    const changeNotification=(event)=>{
        if(event.target.value!==templateNotification){
            setTemplateNotification(event.target.value)
        }
    }

    const changeTemplateWeek=(event)=>{
        if(event.target.value!==templateWeeks){
            setTemplateWeeks(event.target.value)
        }
    }

	const getPatientData = (week) => {

        
		API.get(`/outcome_report_generate/?patient_id=${patientId}`+(week!==undefined?`&included_weeks=${week}`:""))
			.then(response => {
                console.log(response)
                if(response.code!==undefined)
                {
                    if(response.code==="ERR_BAD_RESPONSE")
                    {
                        setErrors("Recieved bad 500 error response from API please try refreshing the page")
                        setLoadedProfile(true);

                        return
                    }
                }
                setLoadedProfile(true);


                if(response.data["error"]!==undefined)
                {
                    
                    if(response.data.error.includes("No matching active Practice Better account found")||response.data.error.includes("must not start with 'changeme'")||response.data.error.includes("Active Practice Better account found with e-mail"))
                    {
                        setErrors("Could not retrieve Practice Better data for this patient. Please make sure the patient's email address matches the one entered in Practice Better exactly.")
                        return;
                    }
                    else
                    {
                        API.get(`/patients/${patientId}`)
                        .then(response=>{
                            setFirstName(response.data.first_name);
                                setLastName(response.data.last_name);
                        })
                        .catch(error => 
                            {
                                setErrors(error.message)
                                

                            }
                            );
                        setErrors(response.error)
                        return;
                    }
                }
                else
                {

                    var data =response.data;
                    if(data.current_coaching_plan_week< data.included_weeks)
                    {
                        data.included_weeks=data.current_coaching_plan_week;
                    }
                    console.log(data)
                    if(data.coaching_plan==="None")
                    {
                        API.get(`/patients/${patientId}`)
                        .then(response=>{
                            setFirstName(response.data.first_name);
                                setLastName(response.data.last_name);
                        })
                        .catch(error => 
                            {
                                setErrors(error.message)
                                

                            }
                            );
                        setErrors("Cannot generate outcome report. Patient requires an active or previously active coaching plan to access the required data")
                        return;
                    }
                    else
                    {
                        setProgram(data.program_type);
                        setHasPlan(true);
                        if(data.coaching_plan==="Most Recently Completed")
                        {
                            setPreviouslyActivePlan(true);
                        }
                    }
                    if(data.current_coaching_plan_week<=0)
                    {
                        setErrors("Cannot generate outcome report. patient's current or last active coaching plan must be at week 1 or later")
                        API.get(`/patients/${patientId}`)
                        .then(response=>{
                            setFirstName(response.data.first_name);
                                setLastName(response.data.last_name);
                        })
                        .catch(error => 
                            {
                                setErrors(error.message)
                                

                            }
                            );
                        return;
                    }
                    else
                    {
                        setCurrentWeek(data.current_coaching_plan_week);
                        setChangedWeek(data.included_weeks!==-1?data.included_weeks :data.current_coaching_plan_week)
                        setSelectedWeek(data.included_weeks!==-1?data.included_weeks :data.current_coaching_plan_week)
                        var options =[];
                        var selected= data.included_weeks!==-1?data.included_weeks :data.current_coaching_plan_week;
                       
                        for(let i=1; i<data.current_coaching_plan_week+1;i++)
                        {
                            if(selected===i){
                                options.push(<option value={i.toString()} selected="selected">{i}</option>)

                            }
                            else
                            {
                            options.push(<option value={i.toString()}>{i}</option>)
                            }
                        }
                        setWeeks(options)
            
                        
                    }

                    if(data.dob!==undefined)
                    {
                        if(data.dob!=="DOB not set in Practice Better"){
                    setDOB(moment( data.dob).calendar())
                        }
                        else
                        {
                            setDOB(null);
                            setErrors("Date of birth not set in Practice Better");
                            return;
                        }
                    }
                    if(data.patient!==undefined){
				    setPatientName(data.patient);
                    }

                    if(data.coach!==undefined)
                    {
                        setCoach(data.coach)
                    }

                    if(data.has_physician_oversight_agreement!==undefined)
                    {
                        setOversight(data.has_physician_oversight_agreement);
                    }

                    if(data.subjective_outcomes!==undefined)
                    {
                        var selected= data.included_weeks!==-1?data.included_weeks :data.current_coaching_plan_week;

                        var discoveries=[];
                        var goals=[];
                        var engage=[];
                        var prog=[];
                        var summary=[];
                        var tempDisc= Object.entries(data.subjective_outcomes);
                        tempDisc.forEach(temp=>
                        { 

                            if(temp[0]>0&&temp[0]<=selected)
                            {
                                if(temp[1].discoveries!==undefined)
                                {
                                    discoveries.push(temp[1].discoveries);
                                }
                                if(temp[1].program_goals!==undefined)
                                {
                                    if(temp[1].program_goals.goals!==undefined)
                                    {
                                       
                                        goals.push(Object.values(temp[1].program_goals.goals))
                                    }
                                }
                                if(temp[1].overall_subjective_progress!==undefined)
                                {
                                    summary.push({week:parseInt(temp[0]),value:temp[1].overall_subjective_progress})
                                }
                                if(temp[1].level_of_engagement_in_program!==undefined)
                                {
                                    engage.push({week:parseInt(temp[0]),value:temp[1].level_of_engagement_in_program})
                                }
                                if(temp[1].perceived_progress_towards_goals!==undefined)
                                {
                                    prog.push({week:parseInt(temp[0]),value:temp[1].perceived_progress_towards_goals})
                                }
                                
                            }
                        }
                        )

                       
                        if(discoveries.length>0){

                        AddDiscoveryManual(discoveries[discoveries.length-1],true);
                        }
                        else
                        {
                            setDiscovery([]);
                            setHtmlDisc([]);
                        }
                        if(goals.length>0){
                        AddGoalManual(goals[goals.length-1],true);
                        }
                        else
                        {
                            setGoal([]);
                            setHtmlGoal([]);
                        }
                        if(prog.length>0)
                        {
                            setRawGoalProgressData(prog[prog.length-1]);
                        }
                        else
                        {
                            setRawGoalProgressData(null)
                        }
                        if(summary.length>0)
                        {
                           if(summary[summary.length-1].week===selected)
                           {
                            var fullSum="";
                            if(Array.isArray(summary[summary.length-1].value)){
                            summary[summary.length-1].value.forEach(e=>{fullSum+=(e+"\n\n")})
                            }
                            else
                            {
                                fullSum= summary[summary.length-1].value;
                            }
                            setSummary(fullSum);
                           }
                           else
                           {
                            setSummary("");
                           }
                        }
                        else
                        {
                            setSummary("");
                        }
                        if(engage.length>0)
                        {
                            setRawEngagementLevelData(engage[engage.length-1]);
                        }
                        else
                        {
                            setRawEngagementLevelData(null);
                        }


                    }
                    
                    if(data.objective_outcomes!==undefined){
                        var selected= data.included_weeks!==-1?data.included_weeks :data.current_coaching_plan_week;


                        if(data.objective_outcomes.adherence!==undefined)
                        {
                            if(data.objective_outcomes.adherence.sessions_attended!==undefined   )
                            {
                            setRawSessionsAttendedData(data.objective_outcomes.adherence.sessions_attended);
                            }
                            else
                            {
                                setRawSessionsAttendedData(null);

                            }

                            if(data.objective_outcomes.adherence.unique_day_vet_usage_by_week!==undefined && Object.keys(data.objective_outcomes.adherence.unique_day_vet_usage_by_week).length>0  )
                            {
                                var values = Object.keys(data.objective_outcomes.adherence.unique_day_vet_usage_by_week);
                                var sum =0;
                        
                                values.forEach(e=>{ if(parseInt(e)>0 && parseInt(e)<=selected){sum+=data.objective_outcomes.adherence.unique_day_vet_usage_by_week[e];}});
                                setRawUniqueDaysData((sum / selected).toFixed(1));
                            }



                            if(data.objective_outcomes.adherence.vet_seconds_by_week!==undefined && Object.keys(data.objective_outcomes.adherence.vet_seconds_by_week).length>0 )
                            {
                                var values = Object.keys(data.objective_outcomes.adherence.vet_seconds_by_week);
                                var sum =0;
                                values.forEach(e=>{ if(parseInt(e)>0 && parseInt(e)<=selected){sum+=data.objective_outcomes.adherence.vet_seconds_by_week[e];}});
                                
                                var avg =(sum / selected);    
                                var duration = `${(moment.duration(avg, 'seconds').hours()+(moment.duration(avg, 'seconds').days()*24))  > 0 ? ((moment.duration(avg, 'seconds').hours()+(moment.duration(avg, 'seconds').days()*24)) + " hours and ") : ""}${moment.duration(avg, 'seconds').minutes()?moment.duration(avg, 'seconds').minutes() +" minutes ":""}  ${moment.duration(avg, 'seconds').seconds()>0? moment.duration(avg, 'seconds').seconds()+" seconds":""}`
                                setRawAverageTimeData(duration)
                                
                            }

                        }
                        
                        if(data.objective_outcomes.rom_improvements!==undefined)
                        {
                            var leftFlex=[];
                            var leftAbd=[];
                            var leftSca=[];
                            var rightFlex=[];
                            var rightAbd=[];
                            var rightSca=[];
                            var lowerFlex=[];
                            var selected= data.included_weeks!==-1?data.included_weeks :data.current_coaching_plan_week;

                            Object.entries(data.objective_outcomes.rom_improvements).forEach(element=>{
                                var week= element[0];
                                if(week>0&&week<=selected){
                                Object.entries(data.objective_outcomes.rom_improvements[week]).forEach(
                                    e=>{
                                        switch(e[0])
                                        {
                                            case "upper_extremity":
                                                Object.entries(e[1]).forEach(arm=>{
                                                    if(arm[0]==="left")
                                                    {
                                                        if(arm[1].abduction!==undefined)
                                                        {
                                                            if(arm[1].abduction.max_measurement>-1){
                                                            leftAbd.push({week:parseInt(week),value:arm[1].abduction.max_measurement});
                                                            }
                                                        }
                                                        if(arm[1].scaption!==undefined)
                                                        {
                                                            if(arm[1].scaption.max_measurement>-1){
                                                            leftSca.push({week:parseInt(week),value:arm[1].scaption.max_measurement});
                                                            }
                                                        }
                                                        if(arm[1].flexion!==undefined)
                                                        {
                                                            if(arm[1].flexion.max_measurement>-1){
                                                            leftFlex.push({week:parseInt(week),value:arm[1].flexion.max_measurement});
                                                            }
                                                        }
                                                    }
                                                    if(arm[0]==="right")
                                                    {
                                                        if(arm[1].abduction!==undefined)
                                                        {
                                                            if(arm[1].abduction.max_measurement>-1){
                                                            rightAbd.push({week:parseInt(week),value:arm[1].abduction.max_measurement});
                                                            }
                                                        }
                                                        if(arm[1].scaption!==undefined)
                                                        {
                                                            if(arm[1].scaption.max_measurement>-1){
                                                            rightSca.push({week:parseInt(week),value:arm[1].scaption.max_measurement});
                                                            }

                                                        }
                                                        if(arm[1].flexion!==undefined)
                                                        {
                                                            if(arm[1].flexion.max_measurement>-1){
                                                            rightFlex.push({week:parseInt(week),value:arm[1].flexion.max_measurement});
                                                            }

                                                        }
                                                    }
                                                })
                                                break;
                                            case "lower_back":
                                                Object.entries(e[1]).forEach(metric=>{
                                                    
                                                    if(metric[0]==="flexion")
                                                    {
                                                        if(metric[1].max_measurement>-1){
                                                        lowerFlex.push({week:parseInt(week),value:metric[1].max_measurement});
                                                        }
                                                    }
                                                })
                                                break;
                                        }
                                    }
                                )
                                }
                            
                                
                            })
                            var hascheck=false
                            if(data.objective_outcomes.rom_first_measurements!==undefined)
                            {
                                if(data.objective_outcomes.rom_first_measurements.lower_back!==undefined &&data.objective_outcomes.rom_first_measurements.lower_back.flexion!==undefined )
                                {
                                    if(lowerFlex.length>0){
                                    lowerFlex.unshift({week:parseInt(data.objective_outcomes.rom_first_measurements.lower_back.flexion.plan_week),value:data.objective_outcomes.rom_first_measurements.lower_back.flexion.first_measurement});
                                    setLowerFlexionCheck(true);
                                    hascheck=true;
                                    }


                                }
                                if(data.objective_outcomes.rom_first_measurements.upper_extremity!==undefined  )
                                {
                                   if(data.objective_outcomes.rom_first_measurements.upper_extremity.left!==undefined )
                                   {
                                        var left =data.objective_outcomes.rom_first_measurements.upper_extremity.left;
                                        if(left.abduction!==undefined)
                                        {
                                            if(leftAbd.length>0){
                                                leftAbd.unshift({week:parseInt(left.abduction.plan_week),value:left.abduction.first_measurement});
                                            setLeftAbductionCheck(true);

                                            hascheck=true;
                                            }
                                        }
                                        if(left.scaption!==undefined)
                                        {
                                            if(leftSca.length>0){

                                            leftSca.unshift({week:parseInt(left.scaption.plan_week),value:left.scaption.first_measurement});
                                            setLeftScaptionCheck(true);

                                            hascheck=true;
                                            }
                                        }
                                        if(left.flexion!==undefined)
                                        {
                                            if(leftFlex.length>0){

                                            leftFlex.unshift({week:parseInt(left.flexion.plan_week),value:left.flexion.first_measurement});
                                            setLeftFlexionCheck(true);

                                            hascheck=true;
                                            }
                                        }
                                        
                                   }
                                   if(data.objective_outcomes.rom_first_measurements.upper_extremity.right!==undefined )
                                   {
                                        var right = data.objective_outcomes.rom_first_measurements.upper_extremity.right;
                                        if(right.abduction!==undefined)
                                        {
                                            if(rightAbd.length>0){

                                            rightAbd.unshift({week:parseInt(right.abduction.plan_week),value:right.abduction.first_measurement});
                                            setRightAbductionCheck(true);

                                            hascheck=true;
                                            }
                                        }
                                        if(right.scaption!==undefined)
                                        {
                                            if(rightSca.length>0){

                                            rightSca.unshift({week:parseInt(right.scaption.plan_week),value:right.scaption.first_measurement});
                                            setRightScaptionCheck(true);

                                            hascheck=true;
                                            }
                                        }
                                        if(right.flexion!==undefined)
                                        {
                                            if(rightFlex>0){
                                            rightFlex.unshift({week:parseInt(right.flexion.plan_week),value:right.flexion.first_measurement});
                                            setRightFlexionCheck(true);
                                            hascheck=true;
                                            }
                                        }

                                   }

                                }
                            }

                            

                            setRightAbductionData(rightAbd);
                            setRightScaptionData(rightSca);
                            setRightFlexionData(rightFlex);
                            setLeftAbductionData(leftAbd);
                            setLeftScaptionData(leftSca);
                            setLeftFlexionData(leftFlex);
                            setLowerFlexionData(lowerFlex)

                            if(rightAbd.length>0)
                            {
                                setRightAbductionImp(getPercent(rightAbd[0].value,rightAbd[GetBestWeek(rightAbd,3,!hascheck)].value))
                            }
                            if(rightSca.length>0)
                            {
                                setRightScaptionImp(getPercent(rightSca[0].value,rightSca[GetBestWeek(rightSca,3,!hascheck)].value))
                            }
                            if(rightFlex.length>0)
                            {
                                setRightFlexionImp(getPercent(rightFlex[0].value,rightFlex[GetBestWeek(rightFlex,3,!hascheck)].value))
                            }
                            if(leftAbd.length>0)
                            {
                                setLeftAbductionImp(getPercent(leftAbd[0].value,leftAbd[GetBestWeek(leftAbd,3,!hascheck)].value))
                            }
                            if(leftSca.length>0)
                            {
                                setLeftScaptionImp(getPercent(leftSca[0].value,leftSca[GetBestWeek(leftSca,3,!hascheck)].value))
                            }
                            if(leftFlex.length>0)
                            {
                                
                                

                                setLeftFlexionImp(getPercent(leftFlex[0].value,leftFlex[GetBestWeek(leftFlex,3,!hascheck)].value))
                            }
                            if(lowerFlex.length>0)
                            {
                                setLowerFlexionImp(getPercent(lowerFlex[0].value,lowerFlex[GetBestWeek(lowerFlex,3,!hascheck)].value))
                            }
                        }

                        if(data.objective_outcomes.measurements!==undefined)
                        {
                        
                            var pain=[];
                            var mood=[];
                            var confidence=[];
                            var activity=[];
                            var sleep=[];
                            var osw=[];
                            var dash=[];
                            var pcs=[];
                            var pseq=[];
                            var uefi =[];
                            var FABQT =[];
                            var FABQA =[];
                            var FABQW =[];
                            var pcss=[];
                            var whodas=[];
                            var gad7=[];
                            var phq9=[];
                            var ndi=[];
                        var meds=[];
                        var work=[];
                        
                        var selected= data.included_weeks!==-1?data.included_weeks :data.current_coaching_plan_week;


                            Object.entries(data.objective_outcomes.measurements).forEach(element=>{
                                element[1].forEach(e=>{
                                    if(element[0]>0&&element[0]<=selected){
                                        switch(true)
                                        {
                                      
                                         case e.label.includes('WHODAS'):
                                            whodas.push({week:parseInt(element[0]),value:parseFloat(e.value)})
                                            break;
                                         case e.label.includes('PHQ9'):
                                            phq9.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                            break;
                                         case e.label.includes('PCSS'):
                                            pcss.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                            break;
                                         case e.label.includes('GAD-7'):
                                            gad7.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                            break;
                                         case e.label.includes('NDI'):
                                            ndi.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                            break;
                                        case e.label.includes('Sleep'):
                                            sleep.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                            break;
                                            
                                        case e.label.includes('Confidence'):
                                            confidence.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                            break;
                                        case e.label.includes('Activity')&&!e.label.includes('FABQ'):
                                            activity.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                            break;
                                        case e.label.includes('Pain'):
                                            pain.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                            break;
                                        case e.label.includes('Mood'):
                                            mood.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                            break;
                                        case e.label.includes('PSEQ Score'):
                                            pseq.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                            break;
                                        case e.label.includes('DASH Score'):
                                            var trueScore = ((e.value / 30) - 1) * 25
                                            dash.push({week:parseInt(element[0]),value:parseInt(trueScore)})
                                            break;
                                        case e.label.includes('PCS Score'):
                                                pcs.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                                break;
                                        case e.label.includes('UEFI Score'):
                                                uefi.push({week:parseInt(element[0]),value:parseInt(e.value)});
                                                break;
                                        case e.label.includes('Oswestry Score'):
                                                osw.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                                break;
                                        case e.label.includes('Work Status'):
                                                work.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                                break;
                                        case e.label.includes('Medication'):
                                                meds.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                                break;
                                        case e.label.includes('FABQ-Total'):
                                                FABQT.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                                break;
                                        case e.label.includes('FABQ-Work'):
                                                FABQW.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                                break;
                                        case e.label.includes('FABQ-Physical'):
                                                FABQA.push({week:parseInt(element[0]),value:parseInt(e.value)})
                                                break;    
                                    }
                                    }
                                })
                                
                            })
                            
                            
                            setSleepData(sleep);
                            setOwestryData(osw);
                            setDashData(dash);
                            setActivityData(activity);
                            setPCSData(pcs);
                            setPSEQData(pseq);
                            setConfidenceData(confidence);
                            setMoodData(mood);
                            setPainData(pain);
                            setUEFIData(uefi); 
                            setFABQActivityData(FABQA);
                            setFABQTotalData(FABQT);
                            setFABQWorkData(FABQW);
                            setNDIData(ndi);
                            setGAD7Data(gad7);
                            setPCSSData(pcss);
                            setWHODASData(whodas);
                            setPHQ9Data(phq9);
                            if(work.length>0){       
                            setReturnToWorkData(work);
                            }
                            if(meds.length>0){
                            setMedReductionData(meds);
                            setMedImp(GetMedReductionResult(meds))
                            }
                            
                        
                            if(sleep.length>0)
                            {
                                setSleepImp(getDifference(sleep[0].value,sleep[sleep.length-1].value))
                            }
                            if(osw.length>0)
                            {
                                setOwestryImp(getPercent(osw[0].value,osw[osw.length-1].value))
                            }
                            if(dash.length>0)
                            {
                                setDashImp(getPercent(dash[0].value,dash[dash.length-1].value))
                            }
                            if(activity.length>0)
                            {
                                setActivityImp(getDifference(activity[0].value,activity[activity.length-1].value))
                            }
                            if(pcs.length>0)
                            {
                                setPCSImp(getPercent(pcs[0].value,pcs[pcs.length-1].value))
                            }
                            if(pseq.length>0)
                            {
                                setPSEQImp(getPercent(pseq[0].value,pseq[pseq.length-1].value))
                            }
                            if(confidence.length>0)
                            {
                                setConfidenceImp(getDifference(confidence[0].value,confidence[confidence.length-1].value))
                            }
                            if(mood.length>0)
                            {
                                setMoodImp(getDifference(mood[0].value,mood[mood.length-1].value))
                            }
                            if(pain.length>0)
                            {
                                setPainImp(getDifference(pain[0].value,pain[pain.length-1].value))
                            }

                            if(uefi.length>0)
                            {
                                setUEFIImp(getPercent(uefi[0].value,uefi[uefi.length-1].value))
                            }

                            if(FABQT.length>0)
                            {
                                setFABQTotalImp(getPercent(FABQT[0].value,FABQT[FABQT.length-1].value))
                            }

                            if(FABQA.length>0)
                            {
                                setFABQActivityImp(getPercent(FABQA[0].value,FABQA[FABQA.length-1].value))
                            }

                            if(FABQW.length>0)
                            {
                                setFABQWorkImp(getPercent(FABQW[0].value,FABQW[FABQW.length-1].value))
                            }  
                            if(pcss.length>0)
                            {
                                setPCSSImp(getPercent(pcss[0].value,pcss[pcss.length-1].value))
                            }  
                             if(ndi.length>0)
                            {
                                setNDIImp(getPercent(ndi[0].value,ndi[ndi.length-1].value))
                            } 
                             if(whodas.length>0)
                            {
                               
                                setWHODASImp(getPercent(whodas[0].value,whodas[whodas.length-1].value))
                            } 
                             if(phq9.length>0)
                            {
                                setPHQ9Imp(getPercent(phq9[0].value,phq9[phq9.length-1].value))
                            } 
                            if(gad7.length>0)
                            {
                                setGAD7Imp(getPercent(gad7[0].value,gad7[gad7.length-1].value))
                            }
                             
                            
                        }

                    }

                    if(data.assessments!==undefined)
                    {

                        var physical=[];
                        var behave=[];
                        var social=[];
                        var assessments = Object.entries(data.assessments);
                        assessments.forEach(element => {
                            if(element[0]>0&&element[0]<=selected){
                            if(element[1].physical!==undefined&&element[1].physical.length>0)
                            {
                                var text="";
                                element[1].physical.forEach(b=>{
                                    if(b.charAt(b.length-1) != "."){b+="."}
                                    text+="•"+b+"\n";
                                
                                }
                                )
                                physical.push({week:parseInt(element[0]),value:text});                            }
                           
                            if(element[1].behavioral!==undefined&&element[1].behavioral.length>0)
                            {
                                var text="";
                                element[1].behavioral.forEach(b=>{
                                    if(b.charAt(b.length-1) != "."){b+="."}
                                    text+="•"+b+"\n";
                                
                                }
                                )
                                behave.push({week:parseInt(element[0]),value:text});

                            }
                            
                            if(element[1].social!==undefined&&element[1].social.length>0)
                            {
                                var text="";
                                element[1].social.forEach(b=>{
                                    if(b.charAt(b.length-1) != "."){b+="."}
                                    text+="•"+b+"\n";
                                
                                }
                                )
                                social.push({week:parseInt(element[0]),value:text});
                            }
                            }
                        });

                        if(physical.length>0)
                        {
                            setPhysicalText(physical[physical.length-1].value);
                        }
                        else
                        {
                            setPhysicalText("");
                        }

                        if(behave.length>0)
                        {
                            setBehavioralText(behave[behave.length-1].value);
                        }
                        else
                        {
                            setBehavioralText("");
                        }


                        if(social.length>0)
                        {
                            setSocialText(social[social.length-1].value);
                        }
                        else
                        {
                            setSocialText("");
                        }

                        

                       
                        
                        

                    }


                   

                    setData(data);


                }
				
			})
			.catch(error => 
                {
                    setErrors(error.message)
                    API.get(`/patients/${patientId}`)
                    .then(response=>{
                        setFirstName(response.data.first_name);
                            setLastName(response.data.last_name);
                    })
                    .catch(error => 
                        {
                            setErrors(error.message)
                            

                        }
                        );

                }
                );

        
	}

   

    const GetBestWeek=(weeks, weekRange,nofilter=false)=>{
        
        if(nofilter)
        {
            weekRange=1;
        }

        var maxIndex=0;
        var maxVal=Number.MIN_SAFE_INTEGER;
        var count=0;

        for(var i=weeks.length-1; i>=0;i--)
        {
            if(count>=weekRange)
            {
                break;
            }
            if(weeks[i].value>maxVal)
            {

                maxVal=weeks[i].value;
                maxIndex=i;
            }
            count++;

        }
        return maxIndex;
    }

	const goToPatient = (id) => {
		history.push(`/patient/${id}/`);
	}


	const breadcrumbLinks = () => {

		return [
            {
                label: "Patients",
                url: "/patients"
            },
            {
                label: patientName ? `${patientName}`: `${firstName} ${lastName}`,
                url: `/patient/${patientId}`
            },
            {
                label: "Patient Outcomes",
                url: `/patient/${patientId}/outcomes/`
            }
        ]


	}


	return (
		<Page title={"Generate Outcome Report"} titleClass={"export"} showMenu standard={false}>
			<Breadcrumbs links={breadcrumbLinks()} />
			<div className="sectionOutcome">
				<form onSubmit={handleSubmit}>

				
				<FormErrors errors={errors} />

					<h2>{patientName}</h2>

					{!loadedProfile && <div>
				<React.Fragment>
      <TailSpin	
			height="100"
			width="100"
			color='grey'
			ariaLabel='loading'
		  />
      	<br /> <br />
      <h4 className='load'>Loading, retrieving data from Practice Better...</h4>
      </React.Fragment>
				</div>}

					{patientName&&loadedProfile&&
                    <div>
                    <React.Fragment>
                    {previouslyActivePlan?( < label className='noMarg missing'>&#9888; Patient has no active coaching plan. Report will use data from most recently active coaching plan</label>):<span></span>}
                    {previouslyActivePlan? <br/>:<span></span>}
                    < label className='noMarg'>Includes data up to week&emsp;&emsp;</label>
                    <select name="weeks" id="weeks" className='noMarg' onChange={ChangeWeek}>
                        {weeks}
                  </select> <br/><br/>

                    {oversight?<p className='noMarg instruction'>Physician Oversight Agreement in place with patient's provider. Physician Review section will be included in report.</p>:<p className='noMarg instruction'>Patient's provider not marked as having a Physician Oversight Agreement. Report will not include a Physician Review section.</p>}
                    <br/>
                    <br/>

                    <h3>Subjective Outcomes</h3>
                    
                    <h5>Program Goals</h5>

                    <p className='instruction'>Outline the real-life goals the patient set for themselves at the start of the program.</p>
<br/>
                    <div id="goals_fields_wrap" >
                  
                        {htmlGoal}
                        {htmlGoal.length==0?<span className='missing shifted'>&emsp;&emsp;&#9888; No Goals found in Practice Better data. You can add them manually below.
                        </span>:<span></span>}
                        

                     </div>
                     <button className='buttonLower' id="add_goals_button" type="button" onClick={AddGoal}>+ Add New Goal</button>
                    <br/>
                    <br/>
                    <br/>



                    <h5>Patient Perspective</h5>
                    <br/>

                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={goalProgress } onChange={()=>{setGoalProgress(!goalProgress)}} disabled={rawGoalProgressData===null?true:false}/> Perceived Progress Towards Goals{rawGoalProgressData? <span className='stat'>&emsp;&emsp;{rawGoalProgressData.value.toString()+"/10"+(selectedWeek===rawGoalProgressData.week?"":` [week ${rawGoalProgressData.week}]`)}</span>:<span className='missing'>&emsp;&emsp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={engagmentLevel } onChange={()=>{setEngagementLevel(!engagmentLevel)}} disabled={rawEngagmentLevelData===null?true:false} /> Level of Engagement in Program {rawEngagmentLevelData? <span className='stat'>&emsp;&emsp;{rawEngagmentLevelData.value.toString()+"/10"+(selectedWeek===rawEngagmentLevelData.week?"":` [week ${rawEngagmentLevelData.week}]`)}</span>:<span className='missing'>&emsp;&emsp;&ensp;&#9888; No Data</span>}
                    </label>
                    <br/>
                    <br/>

                    <h5>Discoveries</h5>
                    <p className='instruction'> Outline any breakthroughs the patient has made in understanding and managing their pain</p>
                    <br/>

                    <div id="discoveries_fields_wrap" >
                  
                        {htmlDisc}
                        {htmlDisc.length==0?<span className='missing shifted'>&emsp;&emsp;&#9888; No Discoveries found in Practice Better data. You can add them manually below.
                        </span>:<span></span>}

                     </div>
                    <button className='buttonLower' id="add_discoveries_button" type="button" onClick={AddDiscovery}>+ Add New Discovery</button>

                    <br/>
                    <br/>
                    <br/>


                    <h5>Subjective Progress Summary</h5>
                    

                    <br/>
                    &emsp;&emsp;<textarea className='customText' name="SummaryText" placeholder="Enter text" onChange={e => setSummary(e.target.value)} value={summary}></textarea>
                    <p className='shift_instruction'>Write a short summary of the patient's engagement with the program and improvements in any area of their lives. Focus on the patient's own perspective and the progress they have communicated to you.</p>
                    <br/><br/>
                    <h3 className='titleWithInstruction'>Objective Outcomes</h3>
                    <p className='instruction cutoff'>Week: {selectedWeek} </p>

                    <h5>Adherence</h5>
                    <br/>

                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={sessionsAttended} onChange={()=>{setSessionsAttended(!sessionsAttended)}} disabled={RawSessionsAttendedData===null?true:false}/> # Coaching Sessions Attended {RawSessionsAttendedData!==null? <span className='stat'>&emsp;&emsp;&emsp;{RawSessionsAttendedData}</span>:<span className='missing'>&emsp;&emsp;&emsp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={uniqueDays } onChange={()=>{setUniqueDays(!uniqueDays)}} disabled={RawUniqueDaysData===null?true:false}/> Unique Days in Headset p/w {RawUniqueDaysData? <span className='stat'>&emsp;&emsp;&emsp;&ensp;&nbsp;{RawUniqueDaysData}</span>:<span className='missing'>&emsp;&emsp;&emsp;&ensp;&nbsp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={averageTime } onChange={()=>{setAverageTime(!averageTime)}} disabled={RawAverageTimeData===null?true:false}/> Average Time in Activities p/w {RawAverageTimeData? <span className='stat'>&emsp;&emsp;&emsp;{RawAverageTimeData}</span>:<span className='missing'>&emsp;&emsp;&emsp;&nbsp;&#9888; No Data</span>}
                    </label>
                    
                    <br/>

                    <h5>Range Of Motion</h5>
                    <br/>


                    <label className='label'>
                    &emsp;&emsp; <input  className ="box" type="checkbox" checked={leftFlexion } onChange={()=>{setLeftFlexion(!leftFlexion)}} disabled={leftFlexionData.length>0?false:true}/> Left Arm Flexion
                    {leftFlexionData.length>0? <span className={leftFlexionImp===0?'stat':(leftFlexionImp>0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;{  leftFlexionData[GetBestWeek(leftFlexionData,3)].value.toFixed(1).toString()}{leftFlexionData.length>1?(leftFlexionImp===0?(selectedWeek===leftFlexionData[GetBestWeek(leftFlexionData,3)].week?" degrees ":` degrees [week ${leftFlexionData[GetBestWeek(leftFlexionData,3)].week}] `)+" (No Change from earliest measurement"+")":" degrees"+(selectedWeek===leftFlexionData[GetBestWeek(leftFlexionData,3)].week?" ":` [week ${leftFlexionData[GetBestWeek(leftFlexionData,3)].week}] `)+"("+Math.abs(leftFlexionImp).toString()+"% "+(leftFlexionImp>0?"improvement":"decline")+" from earliest measurement"+")" ):" degrees"+(selectedWeek===leftFlexionData[GetBestWeek(leftFlexionData,3)].week?" "+"(No Change from earliest measurement)":` [week ${leftFlexionData[GetBestWeek(leftFlexionData,3)].week}] `+"(No Change from earliest measurement)")}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp; <input  className ="box" type="checkbox" checked={leftScaption } onChange={()=>{setLeftScaption(!leftScaption)}} disabled={leftScaptionData.length>0?false:true}/> Left Arm Scaption
                    {leftScaptionData.length>0? <span className={leftScaptionImp===0?'stat':(leftScaptionImp>0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;{leftScaptionData[GetBestWeek(leftScaptionData,3)].value.toFixed(1).toString()}{leftScaptionData.length>1?(leftScaptionImp===0?(selectedWeek===leftScaptionData[GetBestWeek(leftScaptionData,3)].week?" degrees":` degrees [week ${leftScaptionData[GetBestWeek(leftScaptionData,3)].week}] `)+" (No Change from earliest measurement"+")":" degrees"+(selectedWeek===leftScaptionData[GetBestWeek(leftScaptionData,3)].week?" ":` [week ${leftScaptionData[GetBestWeek(leftScaptionData,3)].week}] `)+"("+Math.abs(leftScaptionImp).toString()+"% "+(leftScaptionImp>0?"improvement":"decline")+" from earliest measurement"+")"):" degrees"+(selectedWeek===leftScaptionData[GetBestWeek(leftScaptionData,3)].week?" "+"(No Change from earliest measurement)":` [week ${leftScaptionData[GetBestWeek(leftScaptionData,3)].week}] `+"(No Change from earliest measurement)")}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp; <input  className ="box" type="checkbox" checked={leftAbduction } onChange={()=>{setLeftAbduction(!leftAbduction)}} disabled={leftAbductionData.length>0?false:true}/> Left Arm Abduction
                    {leftAbductionData.length>0? <span className={leftAbductionImp===0?'stat':(leftAbductionImp>0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{leftAbductionData[GetBestWeek(leftAbductionData,3)].value.toFixed(1).toString()}{leftAbductionData.length>1?(leftAbductionImp===0?(selectedWeek===leftAbductionData[GetBestWeek(leftAbductionData,3)].week?" degrees":` degrees [week ${leftAbductionData[GetBestWeek(leftAbductionData,3)].week}] `)+" (No Change from earliest measurement"+")":" degrees"+(selectedWeek===leftAbductionData[GetBestWeek(leftAbductionData,3)].week?" ":` [week ${leftAbductionData[GetBestWeek(leftAbductionData,3)].week}] `)+"("+Math.abs(leftAbductionImp).toString()+"% "+(leftAbductionImp>0?"improvement":"decline")+" from earliest measurement"+")"):" degrees"+(selectedWeek===leftAbductionData[GetBestWeek(leftAbductionData,3)].week?" "+"(No Change from earliest measurement)":` [week ${leftAbductionData[GetBestWeek(leftAbductionData,3)].week}] `+"(No Change from earliest measurement)")}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#9888; No Data</span>}
                    </label>
                    
                    <label className='label'>
                    &emsp;&emsp; <input  className ="box" type="checkbox" checked={rightFlexion } onChange={()=>{setRightFlexion(!rightFlexion)}} disabled={rightFlexionData.length>0?false:true}/> Right Arm Flexion
                    {rightFlexionData.length>0? <span className={rightFlexionImp===0?'stat':(rightFlexionImp>0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;{rightFlexionData[GetBestWeek(rightFlexionData,3)].value.toFixed(1).toString()}{rightFlexionData.length>1?(rightFlexionImp===0?(selectedWeek===rightFlexionData[GetBestWeek(rightFlexionData,3)].week?" degrees":` degrees [week ${rightFlexionData[GetBestWeek(rightFlexionData,3)].week}] `)+" (No Change from earliest measurement"+")":" degrees"+(selectedWeek===rightFlexionData[GetBestWeek(rightFlexionData,3)].week?" ":` [week ${rightFlexionData[GetBestWeek(rightFlexionData,3)].week}] `)+"("+Math.abs(rightFlexionImp).toString()+"% "+(rightFlexionImp>0?"improvement":"decline")+" from earliest measurement"+")" ):" degrees"+(selectedWeek===rightFlexionData[GetBestWeek(rightFlexionData,3)].week?" "+"(No Change from earliest measurement)":` [week ${rightFlexionData[GetBestWeek(rightFlexionData,3)].week}] `+"(No Change from earliest measurement)")}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;&#9888; No Data</span>}
                    </label>
                   
                    <label className='label'>
                    &emsp;&emsp; <input  className ="box" type="checkbox" checked={rightScaption } onChange={()=>{setRightScaption(!rightScaption)}} disabled={rightScaptionData.length>0?false:true}/> Right Arm Scaption
                    {rightScaptionData.length>0? <span className={rightScaptionImp===0?'stat':(rightScaptionImp>0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;&nbsp;{rightScaptionData[GetBestWeek(rightScaptionData,3)].value.toFixed(1).toString()}{rightScaptionData.length>1?(rightScaptionImp===0?(selectedWeek===rightScaptionData[GetBestWeek(rightScaptionData,3)].week?" degrees":` degrees [week ${rightScaptionData[GetBestWeek(rightScaptionData,3)].week}] `)+" (No Change from earliest measurement"+")":" degrees"+(selectedWeek===rightScaptionData[GetBestWeek(rightScaptionData,3)].week?" ":` [week ${rightScaptionData[GetBestWeek(rightScaptionData,3)].week}] `)+"("+Math.abs(rightScaptionImp).toString()+"% "+(rightScaptionImp>0?"improvement":"decline")+" from earliest measurement"+")" ):" degrees"+(selectedWeek===rightScaptionData[GetBestWeek(rightScaptionData,3)].week?" "+"(No Change from earliest measurement)":` [week ${rightScaptionData[GetBestWeek(rightScaptionData,3)].week}] `+"(No Change from earliest measurement)")}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;&nbsp;&#9888; No Data</span>}
                    </label>

                    
                 
                    <label className='label'>
                    &emsp;&emsp; <input  className ="box" type="checkbox" checked={rightAbduction } onChange={()=>{setRightAbduction (!rightAbduction )}} disabled={rightAbductionData.length>0?false:true}/> Right Arm Abduction
                    {rightAbductionData.length>0? <span className={rightAbductionImp===0?'stat':(rightAbductionImp>0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;{rightAbductionData[GetBestWeek(rightAbductionData,3)].value.toFixed(1).toString()}{rightAbductionData.length>1?(rightAbductionImp===0?(selectedWeek===rightAbductionData[GetBestWeek(rightAbductionData,3)].week?" degrees":` degrees [week ${rightAbductionData[GetBestWeek(rightAbductionData,3)].week}] `)+" (No Change from earliest measurement"+")":" degrees"+(selectedWeek===rightAbductionData[GetBestWeek(rightAbductionData,3)].week?" ":` [week ${rightAbductionData[GetBestWeek(rightAbductionData,3)].week}] `)+"("+Math.abs(rightAbductionImp).toString()+"% "+(rightAbductionImp>0?"improvement":"decline")+" from earliest measurement"+")" ):" degrees"+(selectedWeek===rightAbductionData[GetBestWeek(rightAbductionData,3)].week?" ":` [week ${rightAbductionData[GetBestWeek(rightAbductionData,3)].week}] `)}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp; <input  className ="box" type="checkbox" checked={lowerFlexion} onChange={()=>{setLowerFlexion(!lowerFlexion)}} disabled={lowerFlexionData.length>0?false:true}/> Lower Back Flexion
                    {lowerFlexionData.length>0? <span className={lowerFlexionImp===0?'stat':(lowerFlexionImp>0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{lowerFlexionData[GetBestWeek(lowerFlexionData,3)].value.toFixed(1).toString()}{lowerFlexionData.length>1?(lowerFlexionImp===0?(selectedWeek===lowerFlexionData[GetBestWeek(lowerFlexionData,3)].week?" degrees":` degrees [week ${lowerFlexionData[GetBestWeek(lowerFlexionData,3)].week}] `)+" (No Change from earliest measurement"+")":" degrees"+(selectedWeek===lowerFlexionData[GetBestWeek(lowerFlexionData,3)].week?" ":` [week ${lowerFlexionData[GetBestWeek(lowerFlexionData,3)].week}] `)+"("+Math.abs(lowerFlexionImp).toString()+"% "+(lowerFlexionImp>0?"improvement":"decline")+" from earliest measurement"+")"):" degrees"+(selectedWeek===lowerFlexionData[GetBestWeek(lowerFlexionData,3)].week?" ":` [week ${lowerFlexionData[GetBestWeek(lowerFlexionData,3)].week}] `)}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#9888; No Data</span>}
                    </label>
                    <br/>
                    

                    <h5>Qualitative Measures</h5>
                    <br/>

                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={pain } onChange={()=>{setPain(!pain)}} disabled={painData.length>0?false:true}/> Pain
                    {painData.length>0? <span className={painImp===0?'stat':(painImp<0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;{painData.slice(-1)[0].value.toString()+"/10"+(selectedWeek===painData.slice(-1)[0].week?"":` [week ${painData.slice(-1)[0].week}] `)}{painData.length>1?(painImp===0?" (No Change from week "+painData[0].week+")":(painImp<0?" (Improvement of":" (Decline of") +" "+Math.abs(painImp).toString()+" from week "+painData[0].week+")"):""}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={mood } onChange={()=>{setMood(!mood)}} disabled={moodData.length>0?false:true} /> Mood
                    {moodData.length>0? <span className={moodImp===0?'stat':(moodImp>0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;{moodData.slice(-1)[0].value.toString()+"/10"+(selectedWeek===moodData.slice(-1)[0].week?"":` [week ${moodData.slice(-1)[0].week}] `)}{moodData.length>1?(moodImp===0?" (No Change from week "+moodData[0].week+")":(moodImp>0?" (Improvement of":" (Decline of") +" "+Math.abs(moodImp).toString()+" from week "+moodData[0].week+")"):""}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={activity } onChange={()=>{setActivity(!activity)}} disabled={activityData.length>0?false:true}/> Activity
                    {activityData.length>0? <span className={activityImp===0?'stat':(activityImp>0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;{activityData.slice(-1)[0].value.toString()+"/10"+(selectedWeek===activityData.slice(-1)[0].week?"":` [week ${activityData.slice(-1)[0].week}] `)}{activityData.length>1?(activityImp===0?" (No Change from week "+activityData[0].week+")":(activityImp>0?" (Improvement of":" (Decline of") +" "+Math.abs(activityImp).toString()+" from week "+activityData[0].week+")"):""}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                        
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={sleep } onChange={()=>{setSleep(!sleep)}} disabled={sleepData.length>0?false:true}/> Sleep
                    {sleepData.length>0? <span className={sleepImp===0?'stat':(sleepImp>0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&ensp;{sleepData.slice(-1)[0].value+"/10"+(selectedWeek===sleepData.slice(-1)[0].week?"":` [week ${sleepData.slice(-1)[0].week}] `)}{sleepData.length>1?(sleepImp===0?" (No Change from week "+sleepData[0].week+")":(sleepImp>0?" (Improvement of":" (Decline of") +" "+Math.abs(sleepImp).toString()+" from week "+sleepData[0].week+")") :""}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&ensp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={confidence } onChange={()=>{setConfidence(!confidence)}} disabled={confidenceData.length>0?false:true} /> Confidence
                    {confidenceData.length>0? <span className={confidenceImp===0?'stat':(confidenceImp>0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;{confidenceData.slice(-1)[0].value+"/10"+(selectedWeek===confidenceData.slice(-1)[0].week?"":` [week ${confidenceData.slice(-1)[0].week}] `)}{confidenceData.length>1?(confidenceImp===0?" (No Change from week "+confidenceData[0].week+")":(confidenceImp>0?" (Improvement of":" (Decline of") +" "+Math.abs(confidenceImp).toString()+" from week "+confidenceData[0].week+")" ):""}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&#9888; No Data</span>}
                    </label>
                    <br/>

                    <h5>Outcome Measures</h5>
                    <br/>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={returnToWork } onChange={()=>{setReturnToWork(!returnToWork)}} disabled={returnToWorkData===null?true:(parseInt( returnToWorkData.slice(-1)[0].value)===0?true:false)}/> Return To Work {returnToWorkData!==null? <span className='stat'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;{returnToWorkData.slice(-1)[0].value>=3?"Yes":(returnToWorkData.slice(-1)[0].value!==0?"No":"N/A")}{(selectedWeek===returnToWorkData.slice(-1)[0].week?"":` [week ${returnToWorkData.slice(-1)[0].week}] `)}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&#9888; No Data</span>}
                    </label>
                    
                    

                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={medReduction } onChange={()=>{setMedReduction(!medReduction)}} disabled={medReductionData!==null?false:true}/> Medication Reduction {medReductionData!==null? <span className='stat'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;{medImp}{(selectedWeek===medReductionData.slice(-1)[0].week?"":` [week ${medReductionData.slice(-1)[0].week}] `)}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={PSEQ } onChange={()=>{setPSEQ(!PSEQ)}} disabled={PSEQData.length>0?false:true}/> PSEQ
                    {PSEQData.length>0? <span className={PSEQImp===0?'stat':(PSEQImp>0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;{PSEQData.slice(-1)[0].value.toString()+(selectedWeek===PSEQData.slice(-1)[0].week?"":` [week ${PSEQData.slice(-1)[0].week}] `)}{PSEQData.length>1?(PSEQImp===0?" ("+(IndicatePSEQ(PSEQData.slice(-1)[0].value))+" No Change from week "+PSEQData[0].week+")":" ("+(IndicatePSEQ(PSEQData.slice(-1)[0].value))+" "+Math.abs(PSEQImp).toString()+"% "+(PSEQImp>0?"improvement":"decline") +" from week "+PSEQData[0].week+")"):""}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&#9888; No Data</span>}
                    
                    </label>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={PCS } onChange={()=>{setPCS(!PCS)}} disabled={PCSData.length>0?false:true}/> PCS {PCSData.length>0? <span className={PCSImp===0?'stat':(PCSImp<0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{PCSData.slice(-1)[0].value.toString()+(selectedWeek===PCSData.slice(-1)[0].week?"":` [week ${PCSData.slice(-1)[0].week}] `)}{PCSData.length>1?(PCSImp===0?" ("+(PCSData.slice(-1)[0].value>=30?"Indicative of catastrophizing,":"")+" No Change from week "+PCSData[0].week+")":" ("+(PCSData.slice(-1)[0].value>=30?"Indicative of catastrophizing, ":"")+Math.abs(PCSImp).toString()+"% "+(PCSImp<0?"improvement":"decline")+" from week "+PCSData[0].week+")" ):""}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={oswestry} onChange={()=>{setOwestry(!oswestry)}} disabled={oswestryData.length>0?false:true}/> Oswestry
                    {oswestryData.length>0? <span className={oswestryImp===0?'stat':(oswestryImp<0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{oswestryData.slice(-1)[0].value.toString()+(selectedWeek===oswestryData.slice(-1)[0].week?"":` [week ${oswestryData.slice(-1)[0].week}] `)}{oswestryData.length>1?(oswestryImp===0?" ("+(IndicateOswestry(oswestryData.slice(-1)[0].value)) +" No Change from week "+oswestryData[0].week+")":" ("+(IndicateOswestry(oswestryData.slice(-1)[0].value))+" "+Math.abs(oswestryImp).toString()+"% "+(oswestryImp<0?"improvement":"decline") +" from week "+oswestryData[0].week+")"):""}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={Dash } onChange={()=>{setDash(!Dash)}} disabled={DashData.length>0?false:true}/> DASH
                    {DashData.length>0? <span className={DashImp===0?'stat':(DashImp<0?'increase':'decrease')}>&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{DashData.slice(-1)[0].value.toString()+(selectedWeek===DashData.slice(-1)[0].week?"":` [week ${DashData.slice(-1)[0].week}] `)}{DashData.length>1?(DashImp===0?" (No Change from week "+DashData[0].week+")":" ("+Math.abs(DashImp).toString()+"% "+(DashImp<0?"improvement":"decline") +" from week "+DashData[0].week+")"):""}</span>:<span className='missing'>&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#9888; No Data</span>}
                    </label>

                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={UEFI } onChange={()=>{setUEFI(!UEFI)}} disabled={UEFIData.length>0?false:true}/> UEFI
                    {UEFIData.length>0? <span className={UEFIImp===0?'stat':(UEFIImp>0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;{UEFIData.slice(-1)[0].value.toString()+(selectedWeek===UEFIData.slice(-1)[0].week?"":` [week ${UEFIData.slice(-1)[0].week}] `)}{UEFIData.length>1?(UEFIImp===0?" ("+" No Change from week "+UEFIData[0].week+")":" ( "+Math.abs(UEFIImp).toString()+"% "+(UEFIImp>0?"improvement":"decline") +" from week "+UEFIData[0].week+")"):""}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;No Data</span>}

                    </label>

                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={FABQTotal } onChange={()=>{setFABQTotal(!FABQTotal)}} disabled={FABQTotalData.length>0?false:true}/> FABQ Total
                    {FABQTotalData.length>0? <span className={FABQTotalImp===0?'stat':(FABQTotalImp<0?'increase':'decrease')}>&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&thinsp;{FABQTotalData.slice(-1)[0].value.toString()+(selectedWeek===FABQTotalData.slice(-1)[0].week?"":` [week ${FABQTotalData.slice(-1)[0].week}] `)}{FABQTotalData.length>1?(FABQTotalImp===0?" (No Change from week "+FABQTotalData[0].week+")":" ("+Math.abs(FABQTotalImp).toString()+"% "+(FABQTotalImp<0?"improvement":"decline") +" from week "+FABQTotalData[0].week+")"):""}</span>:<span className='missing'>&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&thinsp;&#9888; No Data</span>}
                    </label>

                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={FABQActivity } onChange={()=>{setFABQActivity(!FABQActivity)}} disabled={FABQActivityData.length>0?false:true}/> FABQ Activity
                    {FABQActivityData.length>0? <span className={FABQActivityImp===0?'stat':(FABQActivityImp<0?'increase':'decrease')}>&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&thinsp;&thinsp;&thinsp;{FABQActivityData.slice(-1)[0].value.toString()+(selectedWeek===FABQActivityData.slice(-1)[0].week?"":` [week ${FABQActivityData.slice(-1)[0].week}] `)}{FABQActivityData.length>1?(FABQActivityImp===0?" (No Change from week "+FABQActivityData[0].week+")":" ("+Math.abs(FABQActivityImp).toString()+"% "+(FABQActivityImp<0?"improvement":"decline") +" from week "+FABQActivityData[0].week+")"):""}</span>:<span className='missing'>&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&thinsp;&thinsp;&thinsp;&#9888; No Data</span>}
                    </label>

                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={FABQWork } onChange={()=>{setFABQWork(!FABQWork)}} disabled={FABQWorkData.length>0?false:true}/> FABQ Work
                    {FABQWorkData.length>0? <span className={FABQWorkImp===0?'stat':(FABQWorkImp<0?'increase':'decrease')}>&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;{FABQWorkData.slice(-1)[0].value.toString()+(selectedWeek===FABQWorkData.slice(-1)[0].week?"":` [week ${FABQWorkData.slice(-1)[0].week}] `)}{FABQWorkData.length>1?(FABQWorkImp===0?" (No Change from week "+FABQWorkData[0].week+")":" ("+Math.abs(FABQWorkImp).toString()+"% "+(FABQWorkImp<0?"improvement":"decline") +" from week "+FABQWorkData[0].week+")"):""}</span>:<span className='missing'>&emsp;&ensp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={PCSS } onChange={()=>{setPCSS(!PCSS)}} disabled={PCSSData.length>0?false:true}/> PCSS {PCSSData.length>0? <span className={PCSSImp===0?'stat':(PCSSImp<0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;{PCSSData.slice(-1)[0].value.toString()+(selectedWeek===PCSSData.slice(-1)[0].week?"":` [week ${PCSSData.slice(-1)[0].week}] `)}{PCSSData.length>1?(PCSSImp===0?" ("+(PCSSData.slice(-1)[0].value<=35?"Increased Likelyhood to return to work and life roles, ":"Prolonged recovery, ")+" No Change from week "+PCSSData[0].week+")":" ("+(PCSSData.slice(-1)[0].value<=35?"Increased Likelyhood to return to work, ":"Prolonged Recovery, ")+Math.abs(PCSSImp).toString()+"% "+(PCSSImp<0?"improvement":"decline")+" from week "+PCSSData[0].week+")" ):""}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={WHODAS } onChange={()=>{setWHODAS(!WHODAS)}} disabled={WHODASData.length>0?false:true}/> WHODAS {WHODASData.length>0? <span className={WHODASImp===0?'stat':(WHODASImp<0?'increase':'decrease')}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{WHODASData.slice(-1)[0].value.toString()+(selectedWeek===WHODASData.slice(-1)[0].week?"":` [week ${WHODASData.slice(-1)[0].week}] `)}{WHODASData.length>1?(WHODASImp===0?" ("+GetWHODASIndication(WHODASData.slice(-1)[0].value)+" No Change from week "+WHODASData[0].week+")":" ("+GetWHODASIndication(WHODASData.slice(-1)[0].value)+Math.abs(WHODASImp).toString()+"% "+(WHODASImp<0?"improvement":"decline")+" from week "+WHODASData[0].week+")" ):""}</span>:<span className='missing'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={NDI } onChange={()=>{setNDI(!NDI)}} disabled={NDIData.length>0?false:true}/> NDI {NDIData.length>0? <span className={NDIImp===0?'stat':(NDIImp<0?'increase':'decrease')}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{NDIData.slice(-1)[0].value.toString()+(selectedWeek===NDIData.slice(-1)[0].week?"":` [week ${NDIData.slice(-1)[0].week}] `)}{NDIData.length>1?(NDIImp===0?" ("+GetNDIIndication(NDIData.slice(-1)[0].value)+" No Change from week "+NDIData[0].week+")":" ("+GetNDIIndication(NDIData.slice(-1)[0].value)+Math.abs(NDIImp).toString()+"% "+(NDIImp<0?"improvement":"decline")+" from week "+NDIData[0].week+")" ):""}</span>:<span className='missing'>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={GAD7 } onChange={()=>{setGAD7(!GAD7)}} disabled={GAD7Data.length>0?false:true}/> GAD-7 {GAD7Data.length>0? <span className={GAD7Imp===0?'stat':(GAD7Imp<0?'increase':'decrease')}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{GAD7Data.slice(-1)[0].value.toString()+(selectedWeek===GAD7Data.slice(-1)[0].week?"":` [week ${GAD7Data.slice(-1)[0].week}] `)}{GAD7Data.length>1?(GAD7Imp===0?" ("+GetGAD7Indication(GAD7Data.slice(-1)[0].value)+" No Change from week "+GAD7Data[0].week+")":" ("+GetGAD7Indication(GAD7Data.slice(-1)[0].value)+Math.abs(GAD7Imp).toString()+"% "+(GAD7Imp<0?"improvement":"decline")+" from week "+GAD7Data[0].week+")" ):""}</span>:<span className='missing'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#9888; No Data</span>}
                    </label>
                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={PHQ9 } onChange={()=>{setPHQ9(!PHQ9)}} disabled={PHQ9Data.length>0?false:true}/> PHQ-9 {PHQ9Data.length>0? <span className={PHQ9Imp===0?'stat':(PHQ9Imp<0?'increase':'decrease')}>&nbsp;&nbsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{PHQ9Data.slice(-1)[0].value.toString()+(selectedWeek===PHQ9Data.slice(-1)[0].week?"":` [week ${PHQ9Data.slice(-1)[0].week}] `)}{PHQ9Data.length>1?(PHQ9Imp===0?" ("+GetPHQ9Indication(PHQ9Data.slice(-1)[0].value)+" No Change from week "+PHQ9Data[0].week+")":" ("+GetPHQ9Indication(PHQ9Data.slice(-1)[0].value)+Math.abs(PHQ9Imp).toString()+"% "+(PHQ9Imp<0?"improvement":"decline")+" from week "+PHQ9Data[0].week+")" ):""}</span>:<span className='missing'>&nbsp;&nbsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&#9888; No Data</span>}
                    </label>


                    <br/>
                    <br/>


                    <h3>Assessment</h3>

                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={physical } onChange={()=>{setPhysical(!physical)}} /> <b>Physical</b>
                    </label>
                    {physical?<React.Fragment>
                    <p className='shift_instruction_extra'>Write a concise statement summarizing any improvements in physical measures, such as exercise, range of motion, daily function, recreation, work status, sleep and medication.</p>

                <div className="form-block">
                    &emsp;&emsp;&emsp;<textarea value={physicalText} className='customText' name="PlanText" placeholder="Enter text" onChange={e => setPhysicalText(e.target.value)}></textarea><br/><br/>
                    <span className={physicalText.length<=600?"the-count":"the-count-red"}>{physicalText.length}/ 600</span>
                </div>
                    
                    </React.Fragment>:<span></span>}


                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={behavioral } onChange={()=>{setBehavioral(!behavioral)}} /> <b>Behavioral</b>
                    </label>
                    {behavioral?<React.Fragment>
                    <p className='shift_instruction_extra'>Write a concise statement summarizing any improvements in behavioral and cognitive areas, such as overall mood, adapting to positive behaviors, stress reduction skills, and implementing pain education concepts.</p>
                    
                    <div className="form-block">
                    &emsp;&emsp;&emsp;<textarea value={behavioralText} className='customText' name="PlanText" placeholder="Enter text" onChange={e => setBehavioralText(e.target.value)}></textarea><br/><br/>
                    <span className={behavioralText.length<=600?"the-count":"the-count-red"}>{behavioralText.length}/ 600</span>
                </div>
                    
                    </React.Fragment>:<span></span>}


                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={social } onChange={()=>{setSocial(!social)}} /> <b>Social</b>
                    </label>
                    {social?<React.Fragment>
                    <p className='shift_instruction_extra'>Write a concise statement summarizing any improvements in social aspects of life, such as assertive communication, support networks, personal connection and community engagement.</p>
                    
                    <div className="form-block">
                    &emsp;&emsp;&emsp;<textarea value={socialText} className='customText' name="PlanText" placeholder="Enter text" onChange={e => setSocialText(e.target.value)}></textarea><br/><br/>
                    <span className={socialText.length<=600?"the-count":"the-count-red"}>{socialText.length}/ 600</span>
                    </div>
                    
                    </React.Fragment>:<span></span>}
                   
                    <br/><br/>

                    <h3 className='titleWithInstruction'>Plan</h3>
                    
                    <p className='instruction cutoff'>Choose any pre-written templates first, then edit the final plan in the text box below. Modifying the templates will overwrite any custom changes made to the final plan.</p>

                    <h5>Pre-written Templates</h5>

                   

                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={continueProgram } onChange={()=>{setContinueProgram(!continueProgram)}} /> Continue KarunaHome Program
                    </label>

                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={notification } onChange={()=>{setNotification(!notification)}}/> Physician Notification
                    </label>
                    {notification?<React.Fragment>
                        <div className='SmallBreak'/>&emsp;&emsp;&emsp;&emsp;< label className='noMarg'>Notification Reason&emsp;&emsp;</label>
                <select name="templateNotification" id="templateNotification" className='noMarg' onChange={changeNotification} value ={templateNotification}>
                    {
                        [
                            <option value={NotificationTypes.PsychotherapyReferral} >{"Psychotherapy Referral"}</option>,
                            <option value={NotificationTypes.MedicationReEvaluation} >{"Medication Re-Evaluation"}</option>,
                            <option value={NotificationTypes.NewInjury} >{"New Injury"}</option>,
                            <option value={NotificationTypes.NewSymptoms} >{"New Symptoms"}</option>,
                       
                        ]
                    }
                </select><br/></React.Fragment>:<span></span>
                }


                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={extension } onChange={()=>{setExtension(!extension)}} /> Recommend Program Extension
                    </label>
                    {extension?<React.Fragment>
                        <div className='SmallBreak'/>&emsp;&emsp;&emsp;&emsp;< label className='noMarg'>Recommended Extension&emsp;&emsp;</label>
                <select name="templateWeeks" id="templateWeeks" className='noMarg' onChange={changeTemplateWeek} value ={templateWeeks}>
                    {
                        [
                        <option value={1} >{"1 week"}</option>,
                        <option value={2} >{"2 weeks"}</option>,
                        <option value={3} >{"3 weeks"}</option>,
                        <option value={4} >{"4 weeks"}</option>,
                        <option value={5} >{"5 weeks"}</option>,
                        <option value={6} >{"6 weeks"}</option>,
                        <option value={7} >{"7 weeks"}</option>,
                        <option value={8} >{"8 weeks"}</option>,
                        <option value={9} >{"9 weeks"}</option>,
                        <option value={10} >{"10 weeks"}</option>,
                        <option value={11} >{"11 weeks"}</option>,
                        <option value={12} >{"12 weeks"}</option>,
                        ]
                    }
                </select></React.Fragment>:<span></span>
                }


                    <label className='label'>
                    &emsp;&emsp;<input  className ="box" type="checkbox" checked={planEnd } onChange={()=>{setPlanEnd(!planEnd)}} /> Completed 12-Week Program
                    </label><br/><br/>

                    <h5>Final Plan</h5><div className='MediumBreak'/>

                    
                    &emsp;&emsp;<textarea className='PlanText' name="PlanText" value={plan} placeholder="Enter text" onChange={e => setPlan(e.target.value)}  ></textarea>
                     <p className='shift_instruction'>The Plan section should provide a concise overview of the recommended next steps in the patient's care. Keep in mind that physicians and payers often refer to this section first for a quick understanding of the care direction.</p>

                    <br/><br/>



  
                    <button className='buttonLower generate' >{"Send Report for Verifcation"}</button>
                    </React.Fragment>
				    </div>}
				</form>
			</div>

		</Page>

	);

}



export default withRouter(PatientOutcome);
