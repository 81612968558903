import React, { useState, useEffect } from 'react';



const RangePicker = ({ ranges, onRangeUpdated, defaultValue }) => {
  const [selected, setSelected] = useState(defaultValue);

  const updateRange = ( value ) => { 
    onRangeUpdated(value);
    setSelected(value);
  }

  const renderButtons = ranges.map((range, i) => (
    <button key={i} className={`alternate ${range.value == selected && 'selected'}`} onClick={() =>  updateRange(range.value)}>{range.label}</button>
  ))

  return(
    <div className="range-picker">
      {renderButtons}
    </div>
  );

} 

export default RangePicker;