import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { labelToURL } from '../../utility/Utility';

const PersonCard = ({ person, history, type, noLoad=false }) => {

	const  loadPerson = () => {
		if(noLoad){return;}
		history.push(`/${type}/` + person.id);
	}

	return (
		<div className="patient card" onClick={loadPerson}>
			<p>{person.first_name}</p> <h4>{person.last_name}</h4>
			<h5>{type}</h5>
		</div>
	)

	
}

export default withRouter(PersonCard);