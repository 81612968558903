import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import API from '../services/API';
const SideMenu = ( {location, history, onLogout, role , name }) => {
  const isAdmin = role.includes("admin");
	const isClinician = role === "Clinician" 
	const isPatient= role==="Patient"

    const [reviewer, setReviewer] = useState(false);
    const [issues, setIssues] = useState(0);
    const [pendingReports, setPendingReports] = useState(0);
    


    

  const GetDashBoardLink = () => {
		var version =window.location.origin
		if(version.includes("dev"))
		{
			return "https://api.dev.karunalabs.com/admin/login/"
		}
		else if(version.includes("qa"))
		{
			return "https://api.qa.karunalabs.com/admin/login/"

		}
		else if(version.includes("staging"))
		{		
			return "https://api.staging.karunalabs.com/admin/login/"

		}
		else if(version.includes("localhost"))
		{		
			return "https://api.dev.karunalabs.com/admin/login/"
		}
		else{
			if(version!==null)
			{
				return "https://api.karunalabs.com/admin/login/"

			}
			else
			{
				return "https://api.dev.karunalabs.com/admin/login/"

			}
		}
  }

  const FixName=(n)=>{
    var str1 = n;
var str2 = str1.slice(0, 20); 
var str2= str2.slice(0,-3)+ '...';
return str2;
  }

  useEffect(() => {
if(!isAdmin){
    API.get('/patient_alerts/').then(response => {
        var data= response.data
        if(data!==null&&data!==undefined){
        setReviewer(data.can_review_outcome_reports!==undefined?data.can_review_outcome_reports:false)
        setIssues(data.accounts_requiring_attention!==undefined?data.accounts_requiring_attention:0);
        setPendingReports(data.pending_outcome_reports!==undefined?data.pending_outcome_reports:0)
       
        }

   
    });
}
}, [])

	

	const logout = (e) => {
		// e.preventDefault();
		onLogout();
	
	}

  return (
    <div className='bg-white fixed h-full w-[196px]  flex-col justify-start items-start gap-6 inline-flex border border-r border-gray-200'>
        <div className="w-[196px] h-[216px] pt-8 flex-col justify-start items-start gap-6 inline-flex">
          <div className="self-stretch h-8 pl-6 pr-5 flex-col justify-start items-start flex">
              <div className="justify-start items-start inline-flex">
                  <div className="w-full h-8 justify-center items-center flex">
                      <img className="w-full h-[142px]" src="/icons/karuna_logo_full_blue.png" />
                  </div>
              </div>
          </div>
          <div className="self-stretch h-32 px-4 flex-col justify-start items-start  flex">
             {!isAdmin&& <div className="self-stretch justify-start items-start inline-flex">
                  <div className="grow shrink basis-0 self-stretch px-3 py-2 bg-white rounded-md justify-start items-center gap-2 flex">
                  <Link className="*:hover:underline" to="/">

                      <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                          <img className="w-6 h-6 relative" src="/icons/users.svg"/>
                          <div className="text-gray-700 text-base font-semibold font-['Inter'] leading-normal">Patients</div>
                      </div>
                      </Link>
                      {(issues>0)&&(
                      <div className="px-2 py-0.5 bg-amber-50 rounded-full border border-amber-200 justify-start items-center flex">
                          <div className="text-center text-amber-700 text-xs font-medium font-['Inter'] leading-[18px]">{issues}</div>
                      </div>)
                      }
                  </div>
              </div>}
              {!isPatient&&!isAdmin&&
              (<div className="self-stretch h-10 justify-start items-start inline-flex">
                  <div className="grow shrink basis-0 self-stretch px-3 py-2 bg-white rounded-md justify-start items-center gap-2 flex">
                  <Link className="*:hover:underline" target="_blank"  to={{pathname: GetDashBoardLink()}}>

                      <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                          <img className="w-6 h-6 relative" src="/icons/bar-chart-square.svg" />
                          <div className="text-gray-700 text-base font-semibold font-['Inter'] leading-normal">Analytics</div>
                      </div>
                      </Link>
                  </div>
              </div>)
              }
              {
                ///reportsExternal
                isAdmin&&(
                    <div className="self-stretch justify-start items-start inline-flex">
                        <div className="grow shrink basis-0 self-stretch px-3 py-2 bg-white rounded-md justify-start items-center gap-2 flex">
                        <Link className="*:hover:underline" to="/reportsExternal">
      
                            <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                                <img className="w-6 h-6 relative" src="/icons/file.svg" />
                                <div className="text-gray-700 text-base font-semibold font-['Inter'] leading-normal">Reports</div>
                            </div>
                        </Link>
                            {reviewer&&pendingReports>0&&(
                            <div className="px-2 py-0.5 bg-brand-50 rounded-full border border-brand-200 justify-start items-center flex">
                                <div className="text-center text-brand-800 text-xs font-medium font-['Inter'] leading-[18px]">{pendingReports}</div>
                            </div>)
                            }
                        </div>
                    </div>)
              }
              {
                reviewer&&(
              <div className="self-stretch justify-start items-start inline-flex">
                  <div className="grow shrink basis-0 self-stretch px-3 py-2 bg-white rounded-md justify-start items-center gap-2 flex">
                  <Link className="*:hover:underline" to="/reports">

                      <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                          <img className="w-6 h-6 relative" src="/icons/file.svg" />
                          <div className="text-gray-700 text-base font-semibold font-['Inter'] leading-normal">Reports</div>
                      </div>
                  </Link>
                      {reviewer&&pendingReports>0&&(
                      <div className="px-2 py-0.5 bg-brand-50 rounded-full border border-brand-200 justify-start items-center flex">
                          <div className="text-center text-brand-800 text-xs font-medium font-['Inter'] leading-[18px]">{pendingReports}</div>
                      </div>)
                      }
                  </div>
              </div>)
              }
          </div>
        </div>
        <div className="self-stretch h-40 pl-4  pb-8 flex-col justify-start items-start gap-6 flex absolute bottom-0">
        <div className="self-stretch h-10 flex-col justify-start items-start gap-1 flex">
          <button onClick={logout} className="*:hover:underline">
            <div className="self-stretch  px-3 py-2 bg-white rounded-md justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                    <img className="w-6 h-6 relative" src="/icons/log-out.svg" />
                    <div className="text-gray-700  text-base font-semibold font-['Inter'] leading-normal">Log Out</div>
                </div>
            </div>
            </button>
        </div>
        <Link className="*:hover:underline" to="/user">
        <div className="self-stretch pt-6 border-t border-gray-200 justify-start items-start gap-4 inline-flex">
            <div className="grow shrink basis-0 h-10 justify-start items-center gap-[5px] flex">
                <img className="w-10 h-10 relative" src="/icons/user.svg" />
                <div className="flex-col justify-start items-start inline-flex">
                    <div className="text-gray-700 text-sm font-semibold font-['Inter'] whitespace-nowrap  leading-tight"><p className='text-ellipsis '>{name.length<=20?name:FixName(name)}</p></div>
                    <div className="text-gray-600 text-sm font-normal font-['Inter'] leading-tight">{role}</div>
                </div>
            </div>
        </div>
        </Link>
    </div>
    </div>
  )
}

export default SideMenu