
export function labelToURL(text) {

	var textArray = text.toLowerCase().split(" ");
	return textArray.join("-").replace("<em>", "").replace("</em>", "").replace("<small>", "").replace("</small>", "").replace("/", "_").replace("δ", "Δ").replace("<br>", "");
}
export function labelToImage(text) {


	var textArray = text.toLowerCase().split(" ");
	return textArray.join("_").replace("<em>", "").replace("</em>", "").replace("<small>", "").replace("</small>", "").replace("/", "_").replace(":", "-").replace("δ", "Δ").replace("<br>", "").replace("<br>", "").replace("<br>", "");
}


export function labelToFolder(text) {
	return text.split(" ").join("").toLowerCase().replace("<em>", "").replace("</em>", "").replace("/", "_").replace("<br>", "");
}

export function shuffle(array) {
	var currentIndex = array.length, temporaryValue, randomIndex;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {

		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}




	return array;
}


export function fileExists(url) {
	if (url) {
		var req = new XMLHttpRequest();
		req.open('GET', url, false);
		req.send();
		return req.status == 200;
	} else {
		return false;
	}
}

export function onlyUnique(value, index, self) {
	return self.indexOf(value) === index;
}