import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { arrivedAtPatient, arrivedAtProvider, sendToKaruna } from '../../services/VetkitServices';
import { canReturnToKaruna, canReturnToProvider, destination, isPending } from '../../utility/VetkitUtility';
import ButtonConfirmation from './ButtonConfirmation';
import ProviderReceivedButton from '../buttons/ProviderReceivedButton';
import ReturnToKarunaButton from '../buttons/ReturnToKarunaButton';
import ReturnToProviderButton from '../buttons/ReturnToProviderButton';
import PatientReceivedButton from '../buttons/PatientReceivedButton';


const VetkitCard = ({ vetkit, onChange, history}) => {
	const loadVetkit = () => {
		history.push(`/vetkit/` + vetkit.id);
	}


	const assignVetkit = (e) => {
		e.stopPropagation();
		history.push(`/vetkit/${vetkit.id}/assign`);
	}

	const handleChange = () => {
		onChange && onChange();
		
	}

	return (
		<div className={`vetkit card `} onClick={loadVetkit}>
			<h5>VET Kit</h5>
			<h3>{`${vetkit.name}`}</h3>
			<h4 className="alternate">{vetkit.location}</h4>
			{vetkit.status == "Maintenance" && <h5>Needs service</h5>}
			{vetkit.is_assignable && <button onClick={assignVetkit} >Assign to patient</button>}
			<ProviderReceivedButton vetkit={vetkit} onChange={handleChange}/>
			<PatientReceivedButton vetkit={vetkit} onChange={handleChange}/>
			<ReturnToKarunaButton vetkit={vetkit} onChange={handleChange}/>
			<ReturnToProviderButton vetkit={vetkit} onChange={handleChange}/>
			
		</div>
	)

	
}

export default withRouter(VetkitCard);