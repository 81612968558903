import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import API from '../services/API';
import CliniciansList from '../components/compositions/CliniciansList';
import Breadcrumbs from '../components/Breadcrumbs';




const Clinicians = ({}) => { 

    return (
			<Page title={"Physicians"} showMenu>
				<Breadcrumbs links={[
					{
						label: "Physicians",
						url: "/clinicians"
					}
				]} />
				<CliniciansList />
		</Page>
     
    );

}

export default withRouter(Clinicians);
